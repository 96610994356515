import {useEffect,useState} from 'react';
import { useNavigate,useParams } from "react-router-dom";

import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import AddIcon from '@mui/icons-material/Add';
import UploadIcon from '@mui/icons-material/Upload';
import LinearProgress from '@mui/material/LinearProgress';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import { DataGrid } from '@mui/x-data-grid';
import Grid from '@mui/material/Grid';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Modal from '@mui/material/Modal';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
//import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
//import Typography from '@mui/material/Typography';
import FastfoodIcon from '@mui/icons-material/Fastfood';
//import DeleteIcon from '@mui/icons-material/Delete';
import Skeleton from '@mui/material/Skeleton';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
//import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import { convertToRaw,convertFromRaw, EditorState } from "draft-js";
//import { useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import { Fragment } from "react";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

import TopPrivateNavigation from '../components/TopPrivateNavigation.jsx'
import AppLogout from '../components/AppLogout.jsx'

//services
import AuthService from '../service/auth'
import ProjectService from '../service/Project'
import RecipeService from '../service/Recipe'
import RecipeFoodService from '../service/RecipeFood'
import FoodService from '../service/Food'

const formatJson=function(j){
	return JSON.stringify(j);
}

const formatDecimalPlaces=function(value,n){
	const num = Number(value)
	if (!isNaN(num)) {
		return num.toFixed(n);
	} else {
		return value;
	}
}

const formatPageMethod=function(word) {
  return word.charAt(0).toUpperCase()+ word.slice(1);
}

const selectPage=function(array,page_number){
	return array;//.splice(0,10);
}

const style_parent = {
  position: 'absolute',
  top: '50%',
  left: '50%',

  overflow:'scroll',
    height:'80%',
    display:'block',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const steps = ['Search food', 'Enter quantity', 'Add food'];

const Recipes = () => {
	
	const navigate = useNavigate();
	//protected page check if logged in
	const [currentUser,setCurrentUser]=useState({});
	const [projectData,setProjectData]=useState([]);
	const [projectFound,setProjectFound]=useState(false);
	const [pageMethod,setPageMethod]=useState("");
	
	
	//let params = useParams();
	let project_id =ProjectService.getCurrentProject();//params.id;
	console.log('project_id',project_id);	
	
	const [FoodData,setFoodData]=useState([]);
	const [FoodDataLoaded,setFoodDataLoaded]=useState(false);
	
	//const [recipeData,setRecipeData]=useState([]);//used for view/edit
	const [tempRecipeData,setTempRecipeData]=useState({});//used for new
	
	const [tempData,setTempData]=useState(null);
	const [tempHtmlData,setHtmlTempData]=useState('');
	
	/*Alerts */
	const [ShowErrorMessage,setErrorStatus]=useState(false); 
	const [ErrorText,setErrorMessage]=useState(""); 
	
	const [ShowSuccessMessage,setSuccessStatus]=useState(false); 
	const [SuccessText,setSuccessMessage]=useState("");
	
	function clearMessages(){
		setErrorStatus(false);
		setErrorMessage("");
		setSuccessStatus(false);
		setSuccessMessage("");
	}
	
	function createErrorMessage(msg){
		clearMessages();
		setErrorStatus(true);
		setErrorMessage(msg);
	}
	
	function createSuccessMessage(msg){
		clearMessages();
		setSuccessStatus(true);
		setSuccessMessage(msg);
	}
	
	function loadFoodDataByFilter(filter){
		FoodService.getByFilter(filter).then(function(res){
			setFoodData(res.data);
			setFoodDataLoaded(true);
		//	setFilteredFoodData(res.data);
			console.log(res.data);
			console.log('FoodDataLoaded',FoodDataLoaded);
		});
	}
	
	function loadFoodData(){
		FoodService.getAll().then(function(res){
			setFoodData(res.data);
			setFoodDataLoaded(true);
			console.log(res.data);
			console.log('FoodDataLoaded',FoodDataLoaded);
			console.log(res.data);
		});
	}
	
	const [FilteredFoodData,setFilteredFoodData]=useState([]);
	const handleFoodSearchChange = (event) => {
		setFilteredFoodData([]);
		var filteredList=[];
		//console.log(event.target.value);
		var keyword =event.target.value
		//if(keyword.length==0){	
		//	setFilteredFoodData(FoodData);
		//}
		//if(keyword.length>1){
			for(var i=0;i<FoodData.length;i++){
			//if(FoodData[i].name)
				if(FoodData[i].name.toLowerCase().includes(keyword.toLowerCase()) || FoodData[i].code.toLowerCase().includes(keyword.toLowerCase())){
					filteredList.push(FoodData[i]);
				}
			}
			setFilteredFoodData(filteredList);
		//}
		
	}
	
	function createRecipeTemplate(){
		return {'code':'','name':'','description':'','food_factor':1};
	}
	
	const [foodTranslations,setFoodTranslations]=useState(false);
	function loadFoodTranslation(project_settings){
			
			FoodService.getTranslations().then(function(res){
				var _tempfd ={}
				var _translations=res.data;
				console.log(res.data);
				console.log('project settings',project_settings);
				if(typeof project_settings !=="undefined"){
					for(var i=0;i<project_settings.length;i++){
						var _key=project_settings[i];
						_tempfd[_key]=_translations[_key];
					}
				}else{
					_tempfd=_translations;
				}
				setFoodTranslations(_tempfd);
				//createTemplate(res.data);
				console.log('_tempfd',_tempfd);
			}).catch(function(error){
					console.log(error);
			});
		}
		
	//generate food template
	function createTemplate(translations){
		//name
		setTempFoodData(prevState => ({
			...prevState,
			'name': '',
		}))
		//code
		setTempFoodData(prevState => ({
			...prevState,
			'code': '',
		}))
		//rest of variables
		for (let key in removeKeys(translations,['code','name'])) {
			console.log(key+'=>'+translations[key]);
			setTempFoodData(prevState => ({
				...prevState,
				[key]: 0,
			}))
		}
	}
	
	let params = useParams();
	
	let recipe_id =params.id;
	//console.log('recipe_id',recipe_id);
	
	let method =params.method;
	//console.log('method',method);

	
	const removeKeys=function(object,keys){
		var obj={};
		for (let key in object) {
		  if(!keys.includes(key)){
			  obj[key]=object[key];
		  }
		}
		return obj;
	}
	
	const renderDetailsButton = (params) => {
        return (
            <strong>
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{ marginLeft: 0 }}
                    onClick={() => {
                        //moreInfo(params.row)
						navigate("/food/view/"+params.row._id);
                    }}
                >
                    More Info
                </Button>
            </strong>
        )
    }
	
	
	const renderAddButton = (params) => {
        return (
            <strong>
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{ marginLeft: 0 }}
                    onClick={() => {
                        //cloneFood(params.row)
						//navigate("/food/clone/"+params.row._id);
						console.log(params.row);
						//handleNewFoodModalOpen(params.row)
						testAddFood(params.row)
                    }}
                >
                   Add
                </Button>
            </strong>
        )
    }
	
	
	const columns = [
	  { field: 'code', headerName: 'CODE', width: 130 },
	  { field: 'name', headerName: 'Name', width: 500 },

	 {
			field: '3',
			headerName: '',
			//width: 150,
			renderCell: renderDetailsButton,
			disableClickEventBubbling: true,
		},{
			field: '4',
			headerName: '',
			//width: 150,
			renderCell: renderAddButton,
			disableClickEventBubbling: true,
		}
	];

	const moreInfo = (item) => {
		console.log('food',item);
		navigate("/food/view/"+item._id);
	};
	
	//form fields 
	
	const renderRecipeInputCode=(type) => {
		return  <TextField
				error={codeUsed}
				  margin="none"
				   size="small"
				  required
				  fullWidth
				  id="code"
				 // label="Name"
				  name="code"
				 // autoComplete="name"
				  onChange={(e) => {handleOnChangeCheckCode(e,'code')}} value={recipeData['code']}
				/> 
	};
	const renderRecipeInputName=(type) => {
		return  <TextField
				  margin="none"
				   size="small"
				  required
				  fullWidth
				 
				  id="name"
				 // label="Name"
				  name="name"
				 // autoComplete="name"
				  onChange={(e) => {handleOnChangeValue(e,'name')}} value={recipeData['name']}
				/> 
	};
	
	const renderRecipeInputDescription=(type) => {
		return  <TextField
				  id="description"
				  //label="Description (optional)"
				  name="description"
				  margin="none"
				   size="small"
				  multiline
				  rows={3}
				  sx={{
					width: '100%'
				  }}
				
				    onChange={(e) => {handleOnChangeValue(e,'description')}} value={recipeData['description']}
				/>
	};
	
	const renderRecipeInputFoodFactor=(type) => {
		return  <TextField
				  margin="none"
				   size="small"
				  required
				  fullWidth
				  type="number"
				  id="food_factor"
				 // label="Name"
				  name="food_factor"
				 // autoComplete="name"
				  onChange={(e) => {handleOnChangeValue(e,'food_factor')}} value={recipeData['food_factor']}
				/> 
	};
	
	
	const renderControlBar=(func) => {
		return <div style={{ width: '100%' }}>
				<Box
					sx={{display: 'flex',flexDirection: 'row-reverse',p: 1,m: 1,borderRadius: 1,
					}}
				>
				<Button  onClick={eval(func)}  variant="contained" endIcon={<SaveIcon/>}>
				  Save
				</Button>
				</Box>
			</div>
	};
	
	const renderFormInputs=()=>{
		return <div style={{ width: '100%' }}>
				input boxes
			</div>
		
	}
	
	const renderAlertBar=() => {
		return <div style={{ width: '100%' }}>
					<Box
						sx={{display: 'flex',flexDirection: 'row-reverse',p: 1,m: 1,borderRadius: 1,
						}}
					>
					<Stack sx={{ width: '100%' }} spacing={2}>
						{ ShowErrorMessage &&
							<Alert severity="error">{ErrorText}</Alert>
							} 
						{ ShowSuccessMessage &&
							<Alert severity="success">{SuccessText}</Alert>
						}
						</Stack>
					</Box>
			</div>
	};
	
	useEffect(() => {
		if(!AuthService.isUserLoggedIn()){
			// not logged in navigate back to login
			navigate("/login");
		}else{
				setCurrentUser(AuthService.getCurrentUser());
				var account_type=AuthService.getCurrentUser().__t;
				console.log('account_type',account_type);
				
				console.log(AuthService.getCurrentUser());
				var currentUser=AuthService.getCurrentUser();
				//expired Account
				if(currentUser.expired){
					var string = "account_type="+AuthService.getCurrentUser().__t+"&type=expired";
					console.log('string',string);
					var encodedString = btoa(string); // Base64 encode the String
					console.log('encodedString',encodedString);
					navigate("/restricted/"+encodedString);
					
				}else{//unverified account
					if(!currentUser.email_verified){
						var string = "account_type="+AuthService.getCurrentUser().__t+"&type=emaiverification";
						console.log('string',string);
						var encodedString = btoa(string); // Base64 encode the String
						console.log('encodedString',encodedString);
						navigate("/restricted/"+encodedString);
						
					}else{////force change Password
						if(currentUser.force_password_change){
							//not implemented	
						
						}else{
							
							setPageMethod(formatPageMethod(method));
							//
							
							console.log('pageMethod',pageMethod);
							//load project if set 
								
							if((typeof project_id !=="undefined")&&(project_id!==null)){//project filter
								console.log('project_id',project_id);
								loadProjectById(project_id);
								if(formatPageMethod(method)=='Edit'){
									loadFoodDataByFilter({'global':false,'project':project_id});
								}
							}else{
								if(formatPageMethod(method)=='Edit'){
									loadFoodDataByFilter({'global':true});
									loadFoodTranslation();//no need project settings
								}
								if(formatPageMethod(method)=='View'){
									loadFoodTranslation();//no need project settings
								}
							}

							if(account_type=='Admin'){//Admin
						
							}else if(account_type=='Master'){//Master
								//loadFoodData();
								if(formatPageMethod(method)=='Edit'){
									console.log('Edit............');
									loadRecipe(recipe_id);
									//loadRecipeFood(recipe_id);
								}else if(formatPageMethod(method)=='View') {
									loadRecipe(recipe_id);
									//loadRecipeFood(recipe_id);
								}else if(formatPageMethod(method)=='New') {
									setRecipeData(createRecipeTemplate());
									setCodeUsed(true);//blank
								}else if(formatPageMethod(method)=='Clone') {
									loadRecipe(recipe_id);
									setCodeUsed(true);
								}
								
							}else if(account_type=='Researcher'){//Researcher
								
							}
						}
					}
				}	
		}
	}, []);
	
	function loadProjectById(project_id){
		ProjectService.getById(project_id).then(function(res){
		setProjectData(res.data);
		console.log(res.data);
		setProjectFound(true);
		loadFoodTranslation(res.data.settings.recipe_view_nutritional_values)
		}).catch(function(error){
			console.log(error);
			setProjectFound(false);
		});
	}
	
	const [recipeData,setRecipeData]=useState([]);
	const [recipeFound,setRecipeFound]=useState(false);
	
	function loadRecipe(recipe_id){
		RecipeService.getByIdFullVersion(recipe_id).then(function(res){
		setRecipeData(res.data.recipe);
		setRecipeFoodData(res.data.food);
		setRecipeFoodTotals(res.data.totals);
		setRecipeTotalQuantity(res.data.total_quantity);
		setFoodTotals(res.data.totals_food);
		setLastCode(res.data.recipe.code);
		console.log(res.data);
		setRecipeFound(true);
		//loadRecipeFood(recipe_id);
		}).catch(function(error){
			console.log(error);
			setRecipeFound(false);
		});
	}
	
	const handleOnChangeValueEdit = (e,key) => {
		console.log(e.target.value,key);
		setRecipeData({...recipeData,
			[key]: e.target.value,
		})
	};
	
	const handleOnChangeValueNew = (e,key) => {
		console.log(e.target.value,key);
		setTempRecipeData({...tempRecipeData,
			[key]: e.target.value,
		})
	};
	
	const handleOnChangeValue = (e,key) => {
		console.log(e.target.value,key);
		setRecipeData({...recipeData,
			[key]: e.target.value,
		})
	};
	
	/*used to check if code is already in use */
	const [codeUsed,setCodeUsed]=useState(false);
	const [lastCode,setLastCode]=useState("");//to store edit code
	const handleOnChangeCheckCode = (e,key) => {
		console.log('check code:',e.target.value,key);
		setRecipeData({...recipeData,
			[key]: e.target.value,
		})
		var code =e.target.value;
		code=code.trim();
		if(pageMethod=='Edit'){
			console.log('code',code,'recipe data',lastCode);
			if(code==lastCode){
				console.log('same as before');
			}else{
				if(code.length>0){
				RecipeService.getByFilter({'code':code}).then(function(res){	
					console.log(res.data);	
					if(res.data.length>0){
						setCodeUsed(true);
					}else{
						setCodeUsed(false);
					}
				}).catch(function(error){
					console.log(error);
				});
			}else{
				setCodeUsed(true);
			}
				
			}
		}
		if((pageMethod=='New')||(pageMethod=='Clone')){
			if(code.length>0){
				RecipeService.getByFilter({'code':code}).then(function(res){	
					console.log(res.data);	
					if(res.data.length>0){
						setCodeUsed(true);
					}else{
						setCodeUsed(false);
					}
				}).catch(function(error){
					console.log(error);
				});
			}else{
				setCodeUsed(true);
			}	
		}


	};
	
	//handleSaveNewRecipe
	const handleSaveNewRecipe = (e) => {
		console.log('saving.....',recipeData);
		if((typeof project_id !=="undefined")&&(project_id!==null)){//project filter
			recipeData.global=false;
			recipeData.project=project_id;
		}else{
			recipeData.global=true;
			recipeData.project=null;
		}
		recipeData.created_by=AuthService.getCurrentUser()._id;
		console.log('recipeData.code',recipeData.code)
		console.log('recipeData.code.length',recipeData.code.length)
		
		
		recipeData.name=recipeData.name.trim();
		recipeData.code=recipeData.code.trim();
		if((recipeData.name!=="")&&(recipeData.name!==" ")){
			if((recipeData.code.length>0)&&(recipeData.code!==' ')){
				if(!codeUsed){
				RecipeService.create(recipeData).then(function(res){
						//console.log(res.data);
						navigate("/recipe/edit/"+res.data._id);
						navigate(0);//refresh page
							
				}).catch(function(error){
					console.log(error);
					createErrorMessage(error);
				});
				}else{
					createErrorMessage("Code already exist!!");
				}
			}else{
				createErrorMessage("Recipe code cannot be blank!!");
			}
		}else{
			createErrorMessage("Name cannot be blank or empty!!");
		}
	};
	
	//handleCloneRecipe
	const handleCloneRecipe = (e) => {
		console.log('saving.....',recipeData);
		if((typeof project_id !=="undefined")&&(project_id!==null)){//project filter
			recipeData.global=false;
			recipeData.project=project_id;
		}else{
			recipeData.global=true;
			recipeData.project=null;
		}
		recipeData.created_by=AuthService.getCurrentUser()._id;
		//remove existing variables
		delete recipeData['_id'];
		delete recipeData['_v'];
		
		console.log('recipeData.code',recipeData.code)
		console.log('recipeData.code.length',recipeData.code.length)
		
		recipeData.name=recipeData.name.trim();
		recipeData.code=recipeData.code.trim();
		if((recipeData.name!=="")&&(recipeData.name!==" ")){
			if((recipeData.code.length>0)&&(recipeData.code!==' ')){
				if(!codeUsed){
				
				RecipeService.create(recipeData).then(function(res){
					//
					console.log(res.data);
					var _recipe_id=res.data.id;
					console.log('_recipe_id',_recipe_id);
					var _rds=[];
					console.log('recipeFoodData.length',recipeFoodData.length);
					for(var i=0;i<recipeFoodData.length;i++){
						var _tempRd=recipeFoodData[i];
						delete _tempRd['_id'];
						delete _tempRd['_v'];
						_tempRd.recipe=_recipe_id;
						_rds.push(_tempRd);
					}
					console.log(_rds);
					//copy recipe food items
					console.log('_rds.length',_rds.length);
					console.log('_rds.length',_rds);
					if(_rds.length>0){
						console.log('_rds.length',_rds);
						RecipeFoodService.createMultiple(_rds).then(function(resd){
							console.log(resd.data);
							navigate("/recipe/view/"+_recipe_id);
							navigate(0);//refresh page
						}).catch(function(error){
							console.log(error);	
							createErrorMessage(error);
						});
					}else{
						navigate("/recipe/view/"+_recipe_id);
						navigate(0);//refresh page
					}
						
				}).catch(function(error){
						console.log(error);
						createErrorMessage(error);
			});
	
				}else{
					createErrorMessage("Code already exist!!");
				}
			}else{
				createErrorMessage("Recipe code cannot be blank!!");
			}
		}else{
			createErrorMessage("Name cannot be blank or empty!!");
		}
	};
	
	//handleUpdateRecipe
	const handleUpdateRecipe = (e) => {
		console.log('updating.....',recipeData);
		//last updated by
		tempRecipeData.last_updated_by=AuthService.getCurrentUser()._id;

		recipeData.name=recipeData.name.trim();
		recipeData.code=recipeData.code.trim();
		if((recipeData.name!=="")&&(recipeData.name!==" ")){
			if((recipeData.code.length>0)&&(recipeData.code!==' ')){
				if(!codeUsed){
					RecipeService.update(recipeData).then(function(res){
						console.log(res.data);
						navigate("/recipe/view/"+res.data._id);
						navigate(0);//refresh page
							
					}).catch(function(error){
							console.log(error);
							createErrorMessage(error);
					});
				}else{
					createErrorMessage("Code already exist!!");
				}
			}else{
				createErrorMessage("Recipe code cannot be blank!!");
			}
		}else{
			createErrorMessage("Name cannot be blank or empty!!");
		}
		
	};
	
	const [recipeFoodData,setRecipeFoodData]=useState([]);
	const [recipeFoodFound,setRecipeFoodFound]=useState(false);
	
	function loadRecipeFood(recipe_id){
		RecipeFoodService.getByFilter({'recipe':recipe_id}).then(function(res){
		setRecipeFoodData(res.data);
		console.log(res.data);
		setRecipeFoodFound(true);
		generateFoodTotals();
		}).catch(function(error){
			console.log(error);
			setRecipeFoodFound(false);
		});
	}
	
	const [recipeFoodTotals,setRecipeFoodTotals]=useState({});
	const [recipeTotalQuantity,setRecipeTotalQuantity]=useState(0);
	const [foodTotals,setFoodTotals]=useState({});
	function generateFoodTotals(){
		console.log('generating.....',foodTranslations);
		var totals ={};
		//all variables
		for (let key in removeKeys(foodTranslations,['code','name'])) {
			//console.log(key+'=>'+foodTranslations[key]);
			totals[key]=0;
			//setRecipeFoodTotals(prevState => ({
			//	...prevState,
			//	[key]: 0,
			//}))
		}
		//var total_quantity=10;
		for (var i=0;i<recipeFoodData.length;i++) {
			var _rfood=recipeFoodData[i];
			for (let key in removeKeys(foodTranslations,['code','name'])) {
				if((_rfood.food[key]!=='N')&&(_rfood.food[key]!=='Tr')&&(_rfood.food[key]!=='')){
					var quantity=_rfood.quantity;
					//total_quantity=total_quantity+quantity;
					totals[key]=totals[key] +(quantity *(parseInt(_rfood.food[key])/100));
				}else{
					
				}
			}
		}
		//setRecipeTotalQuantity(total_quantity);
		setRecipeFoodTotals(totals);
	}
	/*
	const [FoodData,setFoodData]=useState([]);
	const [FoodDataLoaded,setFoodDataLoaded]=useState(false);
	
	function loadFoodData(){
		FoodService.getAll().then(function(res){
		//FoodService.getByFilter({'global':false}).then(function(res){
			setFoodData(res.data);
			setFoodDataLoaded(true);
			console.log(res.data);
			///console.log('FoodDataLoaded',FoodDataLoaded);
			//console.log(res.data);
		});
	}*/
	
	const testAddFood = (f) => {
		console.log('food',f);
		var i=getRandomInt(1,500);
		createRecipeFood({'food':f._id,'recipe':recipe_id,'quantity':i});
		//setNewFoodModalOpen(true);	
	}
	
	function createRecipeFood(rf){
		RecipeFoodService.create(rf).then(function(res){
		console.log(res.data);
		loadRecipe(recipe_id);
		}).catch(function(error){
			console.log(error);
		});
	}
	
	const [openNewFoodModal, setNewFoodModalOpen] = useState(false);
	const [tempFoodData,setTempFoodData]=useState({});
		  
	const handleNewFoodModalOpen = (f) => {
		console.log('food',f);
		setTempFoodData(f);
		setNewFoodModalOpen(true);
	};

	const handleNewFoodModalClose = () => {
		setNewFoodModalOpen(false);
	};
	
	const handleSubmitNewFood=(event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		const data = new FormData(event.currentTarget);
		var quantity=data.get('quantity');
		console.log('quantity',quantity);
		console.log('tempFoodData',tempFoodData);

		setNewFoodModalOpen(false);	
	
	};
	
	const handleAddFoodToRecipeModal = () => {
		//console.log('food',f);
		//setTempFoodData(f);
		setNewFoodModalOpen(true);
	};
	
	const [activeStep, setActiveStep] = useState(0);
	const [skipped, setSkipped] = useState(new Set());
	
	 const isStepOptional = (step) => {
    return false;//step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
   /* let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }*/
console.log('activeStep',activeStep);
if(activeStep==1){
	console.log('quantity',quantity);
	console.log('tempRecipeFood',tempRecipeFood);
	
	var rf ={'food':tempRecipeFood._id,'recipe':recipe_id,'quantity':quantity};
	RecipeFoodService.create(rf).then(function(res){
		console.log(res.data);
		loadRecipe(recipe_id);
		}).catch(function(error){
			console.log(error);
		});
/*	var clientFoodLog={
		'client_log_id': client_log_id,
		'client_meal_log_id': tempMeal._id,
		'food': tempFood._id,
		'quantity': quantity,
		'quantification_method': quantificationMethod,
		'cooking_method': cookingMethod,
		'beverage_code': beverageCode,
		'packaging_type': packagingType,
		'packaging_size': packagingSize,
		'created_by':AuthService.getCurrentUser()._id
	}
	
	ClientFoodLogService.create(clientFoodLog).then(function(res){
				console.log(res.data);

				setOpen(false);
				setActiveStep((prevActiveStep) => 0);
				setFilteredFoodData([]);
				loadClientMealFoodLogById(client_log_id);
				
			}).catch(function(error){
					console.log(error);
			});
	
	console.log('IM FINISHED.....');
		
}else{
	   setActiveStep((prevActiveStep) => prevActiveStep + 1);
	
*/}
 setActiveStep((prevActiveStep) => prevActiveStep + 1);
    //setSkipped(newSkipped);
  };
  
    const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
	setQuantity(0);
	setFilteredFoodData([]);
  };
  
  	const [openNewRecipeFoodModal, setOpenNewRecipeFoodModal] = useState(false);
	const [openEditRecipeFoodModal, setOpenEditRecipeFoodModal] = useState(false);
	const [openDeleteRecipeFoodModal, setOpenDeleteRecipeFoodModal] = useState(false);

	const [tempRecipeFood, setTempRecipeFood] = useState({'food':{'name':''}});
	const [quantity, setQuantity] = useState(0);
	const handleQuantityChange = (event) => {
		setQuantity(event.target.value);
	};
	const handleModifyRecipeFoodModal = (e,food,type) => {
		console.log('food',food,'type',type);
		setTempRecipeFood(food);
		if(type=='new'){
			setQuantity(0);
			setActiveStep(0);
			setFilteredFoodData([]);
			setOpenNewRecipeFoodModal(true);
		}
		if(type=='edit'){
			setQuantity(food.quantity);
			setOpenEditRecipeFoodModal(true);
		}
		if(type=='delete'){
			setOpenDeleteRecipeFoodModal(true);
		}
	};
	
	const handleCloseNewRecipeFoodModal = () => {
		setOpenNewRecipeFoodModal(false);
		setQuantity(0);
	};
	
	const handleAddToRecipe= (e,food) => {
		console.log('food',food);
		setTempRecipeFood(food);
		 setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};
	
	const handleCloseEditRecipeFoodModal = () => {
		setOpenEditRecipeFoodModal(false);
	};
	
	const handleCloseRecipeDeleteModal = () => {
		setOpenDeleteRecipeFoodModal(false);
	};
	
	//handleUpdateRecipeFoodModal 
	const handleUpdateRecipeFoodModal = () => {
		console.log('update recipe food ');
		tempRecipeFood.quantity=quantity;
		RecipeFoodService.update(tempRecipeFood).then(function(res){
			console.log(res.data);
			loadRecipe(recipe_id);
			setOpenEditRecipeFoodModal(false);
		}).catch(function(error){
			console.log(error);
		});
		
	};

	
	const handleDeleteRecipeFood = () => {
		RecipeFoodService.deleteById(tempRecipeFood.id).then(function(res){
			console.log(res.data);
			loadRecipe(recipe_id);
			setOpenDeleteRecipeFoodModal(false);
		}).catch(function(error){
			console.log(error);
		});
		
	};
	
	
	function getRandomInt(min, max) {
		min = Math.ceil(min);
		max = Math.floor(max);
		return Math.floor(Math.random() * (max - min + 1)) + min;
	}
	
  return(
  <>
	<TopPrivateNavigation/>
	<AppLogout>
	
		{/* error no project found */}
	{(!projectFound) ?
		<>
		
		<br/>
		<Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" href="/">
          Home
        </Link>
		<Link underline="hover" color="inherit" href="/recipes">
          <Typography color="text.primary">Recipes</Typography>
        </Link>
		  <Typography color="text.primary">{pageMethod}</Typography>
			  {/*	 {((pageMethod=='View') ||(pageMethod=='Edit')) ?
		 <>
		 	 Name of recipe
		 </>
		:<>
		
	</>} */}
      </Breadcrumbs>
	  
	  <br/>
	  <Divider />
		</>
	:<></>}
	{/* if project is found */}
	{(projectFound) ?
	<>
		<br/>
		<Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" href="/">
          Home
        </Link>
        <Link
          underline="hover"
          color="inherit"
          href="/projects"
        >
          Projects
        </Link>
		<Link
          underline="hover"
          color="inherit"
          href={`/project/${projectData._id}`}
        >
          {projectData.name}
        </Link>
		 <Link underline="hover" color="inherit" href="/recipes">
        <Typography color="text.primary">Recipes</Typography>
        </Link>
		 <Typography color="text.primary">{pageMethod}</Typography>
			 {/*
		 {((pageMethod=='View') ||(pageMethod=='Edit')) ?
		 <>
		 	 Name of recipe
		 </>
		:<>
		
		
	</>}*/}
	
      </Breadcrumbs>
	  
	  <br/>
	  <Divider />
		
	
	</>
	:<></>}
	
	{/* Admin */}
	{(currentUser.__t=='Admin') ?
	<>
	
	</>
	:<></>}
	
	{/* Master */}
	{(currentUser.__t=='Master') ?
	<>
	
				<Dialog
					open={openNewFoodModal}
				  >
				<Box component="form" onSubmit={handleSubmitNewFood} noValidate sx={{ mt: 1 }}>
					<DialogTitle>Search food</DialogTitle>
					<DialogContent>
					  <DialogContentText>
				  
					  </DialogContentText>
					  {/*  { ShowErrorMessage &&
							<Alert variant="filled" severity="error">{ErrorText}</Alert>
						} 
						{ ShowSuccessMessage &&
							<Alert variant="filled" severity="success">{SuccessText}</Alert>
				}*/}
				{/*
						 <TextField
							  margin="normal"
							  required
							  fullWidth
							  id="quantity"
							  label="Quantity"
							  name="quantity"
							  autoComplete="name"
							  type="number"
				/>*/}
				
				{(FoodDataLoaded) ?
	<>
		<br/>
		
		{(FoodData.length>0) ?
				
			<div style={{ height: 800, width: '100%' }}>
			
			<TextField fullWidth onChange={(e) => {handleFoodSearchChange(e)}}
			
			id="outlined-basic" 
			label="Search by name or code.." 
			variant="outlined"
			InputProps={{
					  startAdornment: (
						<InputAdornment position="start">
						  <SearchIcon />
						</InputAdornment>
					  ),
					}}			
			
			/>
						<br/><br/>{FilteredFoodData.length} found.
						
						
						<br/><br/>
						
		  <DataGrid
			rows={FilteredFoodData}
			columns={columns}
			initialState={{
			  pagination: {
				paginationModel: { page: 0, pageSize: 15},
			  },
			}}
			pageSizeOptions={[5, 10,15,20]}
		   
		checkboxSelection={false}
		  />
		</div>
			:<>None found!!!
			
			<br/>
			
			
			</>}

	
	<br/> <br/><br/> <br/>
	</>
	:<>
	
	 <Box sx={{ width: '100%' }}>
      <LinearProgress />
    </Box>
	
	
	</>}

					</DialogContent>
					<DialogActions>
				  <Button onClick={handleNewFoodModalClose}>Cancel</Button>
		{/*	<Button type="submit">Save</Button>*/}
					</DialogActions>
					</Box>
				  </Dialog>
				  
				  {/* delete recipe food modal*/} 
				 <Dialog
					open={openDeleteRecipeFoodModal}
					onClose={handleCloseRecipeDeleteModal}
					//aria-labelledby="alert-dialog-title"
					//aria-describedby="alert-dialog-description"
				  >
					<DialogTitle id="alert-dialog-title">
	Are you sure you want to remove {/*{tempRecipeFood.food.name}*/} from this recipe?
					</DialogTitle>
					<DialogContent>
					  <DialogContentText id="alert-dialog-description">
					  
					  </DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleCloseRecipeDeleteModal}>No</Button>
						<Button onClick={handleDeleteRecipeFood} > Yes</Button>
					</DialogActions>
				  </Dialog>
				  
				    {/* start  Modal to pick food*/}
				  
				<Modal
					open={openNewRecipeFoodModal}
					onClose={handleCloseNewRecipeFoodModal}
					aria-labelledby="parent-modal-title"
					aria-describedby="parent-modal-description"
				  >
					<Box sx={{ ...style_parent, width: 800 }}>
					  <h2 id="parent-modal-title">Add food to recipe </h2>
		  
						<Box sx={{ width: '100%' }}>
						  <Stepper activeStep={activeStep}>
							{steps.map((label, index) => {
							  const stepProps = {};
							  const labelProps = {};
							  if (isStepOptional(index)) {
								labelProps.optional = (
								  <Typography variant="caption">Optional</Typography>
								);
							  }
							  if (isStepSkipped(index)) {
								stepProps.completed = false;
							  }
							  return (
								<Step key={label} {...stepProps}>
								  <StepLabel {...labelProps}>{label}</StepLabel>
								</Step>
							  );
							})}
						  </Stepper>
					
						  {activeStep === steps.length ? (
							<Fragment>
							  <Typography sx={{ mt: 2, mb: 1 }}>
								All steps completed - you&apos;re finished
							  </Typography>
							  <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
								<Box sx={{ flex: '1 1 auto' }} />
								<Button onClick={handleReset}>Add Another</Button>
								<Button onClick={handleCloseNewRecipeFoodModal}>Finish</Button>
							  </Box>
							</Fragment>
						  ) : (
						  
							<Fragment>
							  <Typography sx={{ mt: 2, mb: 1 }}>
								
							  </Typography>
							  {(activeStep==0) ?
						<>
						
						
						
					<TextField fullWidth onChange={(e) => {handleFoodSearchChange(e)}} id="outlined-basic" label="food search" variant="outlined" />
						<br/><br/>{FilteredFoodData.length} found.
						
						
						<br/>
						
						<List sx={{ width: '100%', bgcolor: 'background.paper' }}>
						 {selectPage(FilteredFoodData).map((food) => (
						 <>
						 <ListItem alignItems="flex-start" key={food._id}>
							<ListItemAvatar style={{paddingRight:'15px'}}>
							  <Skeleton variant="rectangular" width={60} height={60} />
							  
							</ListItemAvatar>
							{/*<IconButton onClick={(e) => {handleFoodInfoOpen(e,food)}} aria-label="delete">
								<InfoOutlinedIcon />
						 </IconButton>*/}
							
							<ListItemText
							  primary={food.code}
								secondary={food.name}
							/> 
							<Button onClick={(e) => {handleAddToRecipe(e,food)}}>Add to recipe</Button>
						  </ListItem>
						  
						  <Divider variant="" component="li" />
						  <br/>
						 <Divider variant="" component="li" />
						 </>
							))}
						 
						</List>  
						<br/>
						</>
						:<></>}
						
						{(activeStep==1) ?
						<>
						
						<br/>
						<Fragment>
						
							  <Typography sx={{ mt: 2, mb: 1 }}>
							  
								   <TextField
								 type="number" 
								  label="Quantity(g)"
								  id="outlined-end-adornment"
								  
								  value={quantity}
								  onChange={handleQuantityChange}
								  InputProps={{
									endAdornment: <InputAdornment position="end">grams</InputAdornment>,
								  }}
								/>
							  </Typography>
							  <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
								<Box sx={{ flex: '1 1 auto' }} />
								
								<Button
								  color="inherit"
								  disabled={activeStep === 0}
								  onClick={handleBack}
								  sx={{ mr: 1 }}
								>
								  Back
								</Button>
								<Button 
								 disabled={quantity <= 0}
								onClick={handleNext}
								>
								  {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
						  </Button>
								
								
								
							  </Box>
							</Fragment>
						  
					 
						
						</>
						:<></>}
						
						{(activeStep==2) ?
						<>
						<br/>
						<Fragment>
							  <Typography sx={{ mt: 2, mb: 1 }}>
								  {quantity} grams of {tempRecipeFood.name} added
							  </Typography>
							  <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
								<Box sx={{ flex: '1 1 auto' }} />
								<Button onClick={handleReset}>Add Another</Button>
								<Button onClick={handleCloseNewRecipeFoodModal}>Close</Button>
							  </Box>
							</Fragment>
						
						
						</>
						:<></>}
						
						
				
						
						
							  
						
						{/*	  <br/>
							  <Button
								  color="inherit"
								  disabled={activeStep === 0}
								  onClick={handleBack}
								  sx={{ mr: 1 }}
								>
								  Back
								</Button>
								<Button onClick={handleNext}>
								  {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
						  </Button>*/}
							  
							</Fragment>
						  
						  
						  )}
						</Box>
		  
				    </Box>
				</Modal>
				  

				  
					{/*end  Modal to pick food*/}
				  
				  
				  {/* start  edit Modal to pick food*/}
					<Dialog
						fullWidth={true}
						maxWidth={'sm'}
						open={openEditRecipeFoodModal}
						onClose={handleCloseEditRecipeFoodModal}
					  >
						<DialogTitle>Update quantity</DialogTitle>
						<DialogContent>
						  <DialogContentText>
							
						  </DialogContentText>
						  <Box
							noValidate
							component="form"
							sx={{
							  display: 'flex',
							  flexDirection: 'column',
							  m: 'auto',
							  width: 'fit-content',
							}}
						  >
					
						  <br/>
						   <TextField
								 type="number" 
								  label="Quantity(g)"
								  id="outlined-end-adornment"
								  sx={{  width: '500px' }}
								  value={quantity}
								  onChange={handleQuantityChange}
								  InputProps={{
									endAdornment: <InputAdornment position="end">grams</InputAdornment>,
								  }}
								/>
						  </Box>
						</DialogContent>
						<DialogActions>
						  <Button onClick={handleCloseEditRecipeFoodModal}>Cancel</Button>
						  <Button onClick={handleUpdateRecipeFoodModal}>Save</Button>
						</DialogActions>
					  </Dialog>
				  

				  
					{/*end  Modal to pick food*/}
	  {/*{formatJson(recipeData)}*/}
	
		{/* New */}
		{(pageMethod=='New') ?
		<>
		{renderControlBar('handleSaveNewRecipe')}
		{renderAlertBar()}
		<h4>Code</h4>
		{renderRecipeInputCode('New')}
		<h4>Name</h4>
		{renderRecipeInputName('New')}
		<h4>Description</h4>
		{renderRecipeInputDescription('New')}
		<h4>Yield Factor</h4>
		{renderRecipeInputFoodFactor('New')}
		
		</>
		:<></>}
		
		{/* Edit */}
		{(pageMethod=='Edit') ?
		<>
		{renderControlBar('handleUpdateRecipe')}
		{renderAlertBar()}
		<h4>Code</h4>
		{renderRecipeInputCode('Edit')}
		<h4>Name</h4>
		{renderRecipeInputName('Edit')}
		<h4>Description</h4>
		{renderRecipeInputDescription('Edit')}
		<h4>Yield Factor</h4>
		{renderRecipeInputFoodFactor('Edit')}
		<br/>
		<br/>
		<Typography variant="h6" gutterBottom>Foods</Typography>
		{/*<Button
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{ marginLeft: 0 }}
                    onClick={() => {
                        //cloneFood(params.row)
						//navigate("/food/clone/"+params.row._id);
						console.log(params.row);
						//handleNewFoodModalOpen(params.row)
						testAddFood()
                    }}
                >
                   Add
		</Button>*/}
		  <Button  variant="contained" endIcon={<AddIcon />} onClick={(e) => handleModifyRecipeFoodModal(e,false,'new')} >
					Add Food  
			</Button><br/>
	
<br/>
		{(recipeFoodData==0) ?
			<>
				None found!
			</>
			:<>
				<TableContainer component={Paper}>
					<Table sx={{ minWidth: 650 }} aria-label="simple table">
						<TableHead>
						  <TableRow>
							<TableCell>Food</TableCell>
							<TableCell>Quantity</TableCell>
							<TableCell></TableCell>
						  </TableRow>
						</TableHead>
						<TableBody>
						  {recipeFoodData.map((row,index) => (
							<TableRow
							  key={index}
							  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
							>
							  <TableCell component="th" scope="row">
								{row.food.name}
							  </TableCell>
							 <TableCell component="th" scope="row">
								{row.quantity}g
							  </TableCell>
							   <TableCell component="th" scope="row">
								<Tooltip title="Edit">
								   <IconButton onClick={(e) => handleModifyRecipeFoodModal(e,row,'edit')}  aria-label="edit">
									<EditIcon />
								  </IconButton>
								</Tooltip>
								<Tooltip title="Delete">
								   <IconButton onClick={(e) => handleModifyRecipeFoodModal(e,row,'delete')} aria-label="delete">
									<DeleteIcon />
								  </IconButton>
								</Tooltip>
							  </TableCell>
							</TableRow>
						  ))}
						</TableBody>
					</Table>
				</TableContainer>
			</>}
			
			<br/>
			{/*}	
	{(FoodDataLoaded) ?
	<>
		<br/>
		
		{(FoodData.length>0) ?
				
			<div style={{ height: 800, width: '100%' }}>
			
			<TextField fullWidth onChange={(e) => {handleFoodSearchChange(e)}}
			
			id="outlined-basic" 
			label="Search by name or code.." 
			variant="outlined"
			InputProps={{
					  startAdornment: (
						<InputAdornment position="start">
						  <SearchIcon />
						</InputAdornment>
					  ),
					}}			
			
			/>
						<br/><br/>{FilteredFoodData.length} found.
						
						
						<br/><br/>
						
		  <DataGrid
			rows={FilteredFoodData}
			columns={columns}
			initialState={{
			  pagination: {
				paginationModel: { page: 0, pageSize: 15},
			  },
			}}
			pageSizeOptions={[5, 10,15,20]}
		   
		checkboxSelection={false}
		  />
		</div>
			:<>None found!!!
			
			<br/>
			
			
			</>}

	
	<br/> <br/><br/> <br/>
	</>
	:<>
	
	 <Box sx={{ width: '100%' }}>
      <LinearProgress />
    </Box>
	
	
	</>}
			
		*/}

		
		</>
		:<></>}
		
		{/* Clone */}
		{(pageMethod=='Clone') ?
		<>

		{renderControlBar('handleCloneRecipe')}
		{renderAlertBar()}
		<h4>Code</h4>
		{renderRecipeInputCode('Clone')}
		<h4>Name</h4>
		{renderRecipeInputName('Clone')}
		<h4>Description</h4>
		{renderRecipeInputDescription('Clone')}
		<h4>Yield Factor</h4>
		{renderRecipeInputFoodFactor('Clone')}
		
		</>
		:<></>}
		
		{/* View */}
		{(pageMethod=='View') ?
		<>
		
			<div style={{ width: '100%' }}>
				<Box
				sx={{
				  display: 'flex',
				  flexDirection: 'row-reverse',
				  p: 1,
				  m: 1,
				 // bgcolor: 'background.paper',
				  borderRadius: 1,
				}}
			  >
			<Button href={`/recipe/edit/${recipeData._id}`}   variant="contained" endIcon={<EditIcon/>}>
				 Edit
			</Button>
			  </Box>
			</div>
			<Typography variant="h5" gutterBottom>
			{recipeData.code} - {recipeData.name} <i style={{ color: '#ccc',fontWeight:'Normal' }}>{recipeData.description}</i>
			</Typography>
			<Typography variant="h6" gutterBottom>
				Food
			</Typography>
			{(recipeFoodData==0) ?
			<>
				None found!
			</>
			:<>
			
			
				
				
					<TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
			
			{Object.entries(removeKeys(foodTranslations,['code','name'])).map(([key, value]) => (
              <TableCell     key={key} align="left">
				  {value}
			  
			  </TableCell>
			  ))}
    
          </TableRow>
        </TableHead>
        <TableBody>
          {recipeFoodData.map((row,index) => (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                <strong>{row.food.name}({row.quantity}g)</strong>
              </TableCell>
			  {Object.entries(removeKeys(foodTranslations,['code','name'])).map(([key, value]) => (
              <TableCell key={key} align="left">
				{formatDecimalPlaces(foodTotals[row.id][key],3)}
			  </TableCell>
			  ))}
			  
            </TableRow>
          ))}
		  
		  <TableRow
           
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
              <strong> Total</strong>
              </TableCell>
			  {Object.entries(removeKeys(foodTranslations,['code','name'])).map(([key, value]) => (
              <TableCell     key={key} align="left">
				  {formatDecimalPlaces(recipeFoodTotals[key],3)}
			  </TableCell>
			  ))}
			  
            </TableRow>
			<TableRow
           
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
              <strong> Total per 100g</strong>
              </TableCell>
			  {Object.entries(removeKeys(foodTranslations,['code','name'])).map(([key, value]) => (
              <TableCell     key={key} align="left">
				   {formatDecimalPlaces((recipeFoodTotals[key]/recipeTotalQuantity)*100,3)}
			  </TableCell>
			  ))}
			  
            </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
				
				<br/>
				<br/>
				
			</>}
					
		</>
		:<></>}
	
	
	
	{/*	<div style={{ width: '100%' }}>
		<Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          p: 1,
          m: 1,
      //    bgcolor: 'background.paper',
          borderRadius: 1,
        }}
      >
	  
		
		<Button href={`/food/new`}  variant="contained" endIcon={<AddIcon />} >
			Create New Food 
	</Button>
	
	
		  	<Button href={`/upload/food`}  variant="outlined" endIcon={<UploadIcon />} >
			Save
		</Button>

      </Box>
    </div>
	
	{formatJson(recipeData)}

	<br/>*/}
	
	{/*		
	<Typography variant="h6" gutterBottom>
			Nutritional Information
		</Typography>

		<br/><br/>
		
			<TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
			
			{Object.entries(removeKeys(foodTranslations,['code','name'])).map(([key, value]) => (
              <TableCell     key={key} align="left">
				  {value}
			  
			  </TableCell>
			  ))}
    
          </TableRow>
        </TableHead>
        <TableBody>
          {recipeFoodData.map((row,index) => (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.food.name}({row.quantity}g)
              </TableCell>
			  {Object.entries(removeKeys(foodTranslations,['code','name'])).map(([key, value]) => (
              <TableCell     key={key} align="left">
				  {row.food[key]} per 100g <br/>
				  ({((row.food[key]/100)*row.quantity)}) total <br/>
			  </TableCell>
			  ))}
			  
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
	*/}
		{/*
	
		<h4>Food</h4>
		<ul>
		{recipeFoodData.map((row,index) => (
		<li key={index}>{row.food.name} - {row.quantity}g

		</li>
		))}
	</ul>	*/}
	 
	 {/*
		<br/>
	{(FoodDataLoaded) ?
	<>
		<br/>
		
		{(FoodData.length>0) ?
				
			<div style={{ height: 800, width: '100%' }}>
			
			<TextField fullWidth onChange={(e) => {handleFoodSearchChange(e)}}
			
			id="outlined-basic" 
			label="Search by name or code.." 
			variant="outlined"
			InputProps={{
					  startAdornment: (
						<InputAdornment position="start">
						  <SearchIcon />
						</InputAdornment>
					  ),
					}}			
			
			/>
						<br/><br/>{FilteredFoodData.length} found.
						
						
						<br/><br/>
						
		  <DataGrid
			rows={FilteredFoodData}
			columns={columns}
			initialState={{
			  pagination: {
				paginationModel: { page: 0, pageSize: 15},
			  },
			}}
			pageSizeOptions={[5, 10,15,20]}
		   
		checkboxSelection={false}
		  />
		</div>
			:<>None found!!!
			
			<br/>
			
			
			</>}

	
	<br/> <br/><br/> <br/>
	</>
	:<>
	
	 <Box sx={{ width: '100%' }}>
      <LinearProgress />
    </Box>
	
	
	</>}
	
	*/}
	
	{/*
	<h4>Food you can add</h4>
	
		<ul>
		{FoodData.map((row,index) => (
		<li key={index}>{row.name}
		
	
		
		<TextField
    id="standard-name"
    label=""
    value=""
    InputProps={{endAdornment: <Button   onClick={(e) => testAddFood(row)}  variant="outlined" >
		 quick add recipe
		</Button>}}
		/>
		
		
		</li>
		))}
	</ul>*/}
	</>
	:<></>}
	
	{/* Researcher */}
	{(currentUser.__t=='Researcher') ?
	<>
	
	</>
	:<></>}

	</AppLogout>
	</>
  ) 
  ;
};

export default Recipes;