import {useEffect,useState,useRef} from 'react';
import { useNavigate,useParams } from "react-router-dom";

import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Divider from '@mui/material/Divider';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CircleIcon from '@mui/icons-material/Circle';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';

import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
//import FormControlLabel from '@mui/material/FormControlLabel';
//import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
//import FormGroup from '@mui/material/FormGroup';
//import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import Tooltip from '@mui/material/Tooltip';

//import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
//import Dialog from '@mui/material/Dialog';
//import DialogTitle from '@mui/material/DialogTitle';
//import DialogContent from '@mui/material/DialogContent';
//import DialogActions from '@mui/material/DialogActions';
//import IconButton from '@mui/material/IconButton';
//import CloseIcon from '@mui/icons-material/Close';
//import Typography from '@mui/material/Typography';


import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import { convertToRaw,convertFromRaw, EditorState } from "draft-js";
//import { useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import { Fragment } from "react";

import parse from 'html-react-parser';

import TopPrivateNavigation from '../components/TopPrivateNavigation.jsx'
import AppLogout from '../components/AppLogout.jsx'

//services
import AuthService from '../service/auth'
import ProjectService from '../service/Project'
import ClientService from '../service/Client'
import QuestionnaireService from '../service/Questionnaire'
import ClientQuestionnaireService from '../service/ClientQuestionnaire'
import ExportsService from '../service/Exports'

const formatDateFull=function(d){
	var d = new Date(d);
	Number.prototype.padLeft = function(base,chr){
    var  len = (String(base || 10).length - String(this).length)+1;
    return len > 0? new Array(len).join(chr || '0')+this : this;
}
    var dformat = [
               d.getDate().padLeft(),
			   (d.getMonth()+1).padLeft(),
               d.getFullYear()].join('/') +' ' +
              [d.getHours().padLeft(),
               d.getMinutes().padLeft()].join(':');
	return dformat;
}

const formatDate=function(d){
	var d = new Date(d);
	Number.prototype.padLeft = function(base,chr){
    var  len = (String(base || 10).length - String(this).length)+1;
    return len > 0? new Array(len).join(chr || '0')+this : this;
}
    var dformat = [
               d.getDate().padLeft(),
			   (d.getMonth()+1).padLeft(),
               d.getFullYear()].join('/') ;
	return dformat;
}


const formatTime=function(d){
	var d = new Date(d);
	Number.prototype.padLeft = function(base,chr){
    var  len = (String(base || 10).length - String(this).length)+1;
    return len > 0? new Array(len).join(chr || '0')+this : this;
}
    var dformat = 
              [d.getHours().padLeft(),
               d.getMinutes().padLeft()].join(':');
	return dformat;
}

const formatJson=function(s){
	return JSON.stringify(s)
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const ClientLogs = () => {
	
	const navigate = useNavigate();
	//protected page check if logged in
	const [currentUser,setCurrentUser]=useState({});
	const [projectData,setProjectData]=useState([]);
	const [projectFound,setProjectFound]=useState(false);
	
	const [clientData,setClientData]=useState([]);
	const [clientLoaded,setClientLoaded]=useState(false);
		
	let params = useParams();
	let client_id =params.client_id;
	console.log('client_id',client_id);
	let project_id =ProjectService.getCurrentProject();//params.id;
	console.log('project_id',project_id);
		
	useEffect(() => {
		if(!AuthService.isUserLoggedIn()){
			// not logged in navigate back to login
			navigate("/login");
		}else{
				setCurrentUser(AuthService.getCurrentUser());
				var account_type=AuthService.getCurrentUser().__t;
				console.log('account_type',account_type);
				
				console.log(AuthService.getCurrentUser());
				var currentUser=AuthService.getCurrentUser();
				//expired Account
				if(currentUser.expired){
					var string = "account_type="+AuthService.getCurrentUser().__t+"&type=expired";
					console.log('string',string);
					var encodedString = btoa(string); // Base64 encode the String
					console.log('encodedString',encodedString);
					navigate("/restricted/"+encodedString);
					
				}else{//unverified account
					if(!currentUser.email_verified){
						var string = "account_type="+AuthService.getCurrentUser().__t+"&type=emaiverification";
						console.log('string',string);
						var encodedString = btoa(string); // Base64 encode the String
						console.log('encodedString',encodedString);
						navigate("/restricted/"+encodedString);
						
					}else{////force change Password
						if(currentUser.force_password_change){
							//not implemented	
						
						}else{
							//load project if set 
							if((typeof project_id !=="undefined")&&(project_id!==null)){//project filter
								loadProjectById(project_id);
							}
							if((typeof client_id !=="undefined")&&(client_id!==null)){
								console.log('not null or undefined client_id',client_id);
								loadClientById(client_id);
								//loadClientLogById(client_id);
							}
							//load
							if(account_type=='Admin'){//Admin
						
							}else if(account_type=='Master'){//Master
								loadQuestionnaires();
								loadClientQuestionnaires();
								
							}else if(account_type=='Researcher'){//Researcher
								
							}
						}
					}
				}	
		}
	}, []);
	
		
	//load project
	function loadProjectById(project_id){
		ProjectService.getById(project_id).then(function(res){
		setProjectData(res.data);
		console.log(res.data);
		setProjectFound(true);
		}).catch(function(error){
			console.log(error);
			setProjectFound(false);
		});
	}
	//load client
	function loadClientById(client_id){
		ClientService.getById(client_id).then(function(res){
			setClientData(res.data);
			setClientLoaded(true);
			console.log(res.data);
		}).catch(function(error){
			console.log(error);
		});
	}
	
	const [questionnaireData,setQuestionnaireData]=useState([]);
	function loadQuestionnaires(){
		var questionnaireFilter ={'global':true}
		if((typeof project_id !=="undefined")&&(project_id!==null)){//project filter
			questionnaireFilter ={'global':false,'project':project_id}
		}
		//questionnaireFilter.active=true;
		questionnaireFilter.published=true;
		console.log(questionnaireFilter);
		QuestionnaireService.getByFilter(questionnaireFilter).then(function(res){
			setQuestionnaireData(res.data);
			console.log(res.data);
		}).catch(function(error){
			console.log(error);
		});
	}
	
	const [clientQuestionnaireData,setClientQuestionnaireData]=useState({});
	function loadClientQuestionnaires(){
		ClientQuestionnaireService.getByClientId(client_id).then(function(res){
			setClientQuestionnaireData(res.data.clientQuestionnaires);
			console.log(res.data);
		}).catch(function(error){
			console.log(error);
		});
	}
	const [timeNow, setTimeNow] = useState(dayjs(new Date().setHours(0, 0, 0, 0)));
	const [dateNow, setDateNow] = useState(dayjs(new Date()));
	const [tempQuestionnaire,setTempQuestionnaire]=useState({});
	const [tempClientQuestionnaire,setTempClientQuestionnaire]=useState({});
	const [tempQuestions,setTempQuestions]=useState([]);
	const [tempQuestionsAndResponses,setTempQuestionsAndResponses]=useState([]);
	const [tempResponses,setTempReponses]=useState({});
	
	const [openClientQuestionnaireModal, setOpenClientQuestionnaireModal] = useState(false);
	const [clientQuestionnaireModalMode, setClientQuestionnaireModalMode] = useState('');
	
	const handleCompleteAddClientQuestionnaireModal=(e,questionnaire)=>{
		setClientQuestionnaireModalMode('take');
		QuestionnaireService.getById(questionnaire.id).then(function(res){
			setTempQuestionnaire(res.data.questionnaire);
			createTempResponses(res.data.questions);
			setTempQuestions(res.data.questions);
			setOpenClientQuestionnaireModal(true);
			console.log(res.data);
		}).catch(function(error){
			console.log(error);
		});
	}
	
	const handleClientQuestionnaireClose = () => {
		setOpenClientQuestionnaireModal(false);
	};
	
	const handleCompleteAddClientQuestionnaire=(e)=>{
		setOpenClientQuestionnaireModal(false);
		console.log('tempQuestionnaire',tempQuestionnaire);
		console.log('tempResponses',tempResponses);
		
		let _tempClientQuestionnaire={
			'clientQuestionnaire':{
				'questionnaire':tempQuestionnaire.id,
				'client':client_id,
				'created_by':AuthService.getCurrentUser()._id
			},'responses':tempResponses
		}
		
		console.log('_tempClientQuestionnaire',_tempClientQuestionnaire);
		ClientQuestionnaireService.create(_tempClientQuestionnaire).then(function(res){
			console.log(res.data);
			loadQuestionnaires();
			loadClientQuestionnaires();

		}).catch(function(error){
			console.log(error);
		});
	}
	
	function createTempResponses(questions){
		var responses={};
		for(let i=0;i<questions.length;i++){
			responses[questions[i].id]=[];
		}
		setTempReponses(responses);
	}
	
	const handleOnChangeResponseValue = (e,question) => {
		console.log(e);
		console.log(question);
		let question_id=question.id;
		let type=question.type;
		
		console.log(tempResponses);
		console.log(tempResponses[question_id]);
		if(type=='multi_choice'){
			let value= e.target.value;
			var _temp =tempResponses[question_id];
			if(e.target.checked){//add
				_temp.push(value);
			}else{//remove
				var index = _temp.indexOf(value);
				if (index !== -1) {
				  _temp.splice(index, 1);
				}
			}
			setTempReponses({...tempResponses,
				[question_id]: _temp,
			})
		}else if(type=='time'){
			let value= e.$d;
			setTempReponses({...tempResponses,
				[question_id]: [value],
			})
			
		}else if(type=='date'){
					let value= e.$d;
			setTempReponses({...tempResponses,
				[question_id]: [value],
			})
			
		}else if(type=='comment'){
				let value= e.target.value;
			setTempReponses({...tempResponses,
				[question_id]: [value],
			})
			
		}else if(type=='text'){
				let value= e.target.value;
			setTempReponses({...tempResponses,
				[question_id]: [value],
			})
			
		}else{
			let value= e.target.value;
			setTempReponses({...tempResponses,
				[question_id]: [value],
			})
			
		}

		console.log(tempResponses[question_id]);
	};
	
	const handleViewClientQuestionnaireModal=(e,cq)=>{
		setClientQuestionnaireModalMode('view');
		setOpenClientQuestionnaireModal(true);
		console.log('cq',cq);
		
		ClientQuestionnaireService.getById(cq.id).then(function(res){
			console.log(res.data);
			setTempQuestionsAndResponses(res.data.QuestionsAndResponses);
			setTempQuestionnaire(res.data.questionnaire);
			//loadQuestionnaires();
			//loadClientQuestionnaires();

		}).catch(function(error){
			console.log(error);
		});
	}
	
	
	
	/*delete client questionnaire*/
	const [openDeleteClientQuestionnaireModal, setOpenDeleteClientQuestionnaireModal] = useState(false);
	const handleDeleteClientQuestionnaireModal=(e,cq)=>{
		setTempClientQuestionnaire(cq);
		setOpenDeleteClientQuestionnaireModal(true);
	}

	const handleDeleteClientQuestionnaireClose  = () => {
		setOpenDeleteClientQuestionnaireModal(false);
	};
	  
	const handleDeleteClientQuestionnaire=()=>{
		console.log('tempClientQuestionnaire',tempClientQuestionnaire);
		ClientQuestionnaireService.deleteById(tempClientQuestionnaire.id).then(function(res){
			console.log(res.data);
			setOpenDeleteClientQuestionnaireModal(false);
			loadQuestionnaires();
			loadClientQuestionnaires();
		}).catch(function(error){
			console.log(error);
		});
	};
	
	/*download responses*/
	const [downloadUrl,setDownloadUrl]=useState("");
	const stateRef = useRef();
	stateRef.loaded = false;
	
	const handleDownloadQuestionnaireResponse = (e,response) => {
		console.log('response:',response);
		var _filter={'sub_type':'client_response','client_response_id':response._id};
		ExportsService.build('client_questionnaires',_filter).then(function(res){
			console.log(res.data);
			
			if(res.data.found){
				var filename =res.data.filename;
				var url=ExportsService.getUrl(filename);
				console.log('url',url);
				setDownloadUrl(url);
				
				var counter=0;
				var refreshIntervalId = setInterval(function(){
				checkIfBuilt(filename);
				if(stateRef.loaded){
					clearInterval(refreshIntervalId);
					window.open(url,'_blank');}
					if(counter==20){
						clearInterval(refreshIntervalId);
					}
					counter++;
				}, 1000);
			}else{
				console.log('Not able to download...');
			}		
		}).catch(function(error){
				console.log(error);
		});
	}
	 
	const handleDownloadQuestionnaireResponses = (e,questionnaire) => {
		console.log('questionnaire:',questionnaire);
		console.log('client_id:',client_id);
		var _filter={'sub_type':'client_questionnaire','questionnaire':questionnaire._id,'client':client_id};
		ExportsService.build('client_questionnaires',_filter).then(function(res){
			console.log(res.data);
			
			if(res.data.found){
				var filename =res.data.filename;
				var url=ExportsService.getUrl(filename);
				console.log('url',url);
				setDownloadUrl(url);
				
				var counter=0;
				var refreshIntervalId = setInterval(function(){
				checkIfBuilt(filename);
				if(stateRef.loaded){
					clearInterval(refreshIntervalId);
					window.open(url,'_blank');}
					if(counter==20){
						clearInterval(refreshIntervalId);
					}
					counter++;
				}, 1000);
			}else{
				console.log('Not able to download...');
			}
						
		}).catch(function(error){
				console.log(error);
		});
		 
	}
	
	function checkIfBuilt(filename){
		ExportsService.exists(filename).then(function(res){
			if(res.data.exists){
				stateRef.loaded=true;
			}
		}).catch(function(error){
				console.log(error);
		});
	}
	
  return(
  <>
	<TopPrivateNavigation/>
	<AppLogout>
	
		{/* error no project found */}
	{(!projectFound) ?
		<>
		
		<br/>
		<Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" href="/">
          Home
        </Link>
		 <Typography color="text.primary">Questionnaires</Typography>
      </Breadcrumbs>
	  
	  <br/>
	  <Divider />
		<br/>
		
		
		</>
	:<></>}
	{/* if project is found */}
	{(projectFound) ?
	<>
		<br/>
		<Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" href="/">
          Home
        </Link>
        <Link
          underline="hover"
          color="inherit"
          href="/projects"
        >
          Projects
        </Link>
		<Link
          underline="hover"
          color="inherit"
          href={`/project/${projectData._id}`}
        >
          {projectData.name}
        </Link>
		<Link
          underline="hover"
          color="inherit"
          href={`/client/view/${clientData._id}`}
        >
          {clientData.code}
        </Link>
		 <Typography color="text.primary">Questionnaires</Typography>
      </Breadcrumbs>
	  
	  <br/>
	  <Divider />
		<br/>
	
	</>
	:<></>}

	<br />

	{/* Admin */}
	{(currentUser.__t=='Admin') ?
	<>
	
	</>
	:<></>}
	
	{/* Master */}
	{(currentUser.__t=='Master') ?
	<>
	
	{/* View or take questionnaire */}
      <BootstrapDialog
        onClose={handleClientQuestionnaireClose}
        aria-labelledby="customized-dialog-title"
        open={openClientQuestionnaireModal}
		 fullWidth={true}
        maxWidth={'md'}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
			{tempQuestionnaire.title}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClientQuestionnaireClose}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
		<Typography  gutterBottom>
		
		{clientQuestionnaireModalMode=='take' &&
		<>

		{tempQuestions.map((q,index) => (
          <Typography key={index} gutterBottom>
				<Typography variant="h6" component="h6">
		  {index+1})	{q.question}
			</Typography>
			<div style={{  margin: '0 0 0 20px' }}>
			  {q.type=='text' &&
			  <>
				<TextField  margin="normal" required fullWidth id="" label="" name=""
					value={tempResponses[q.id]}
					onChange={(e) => {handleOnChangeResponseValue(e,q)}}
				/>
			  </>}
			  
			  {q.type=='number' &&
			  <>
				<TextField inputProps={{ type: 'number'}}  margin="normal" required fullWidth id="" label="" name=""
					value={tempResponses[q.id]}
					onChange={(e) => {handleOnChangeResponseValue(e,q)}}
				/>			
			  </>}
			  
			  {q.type=='time' &&
			  <>
				 <LocalizationProvider  dateAdapter={AdapterDayjs}>
				  <DemoContainer sx={{ m: 1, width: '500px'}} components={['TimePicker']}>
					<TimePicker
					  name=""
					  label=""
					  value={timeNow}
					  ampm={false}
					  onChange={(e) => {handleOnChangeResponseValue(e,q)}}
					/>
				  </DemoContainer>
				</LocalizationProvider>
				
				</>}
				
				{q.type=='date' &&
			  <>
				 <LocalizationProvider  dateAdapter={AdapterDayjs}>
				  <DemoContainer sx={{ m: 1, width: '500px'}} components={['DatePicker']}>
					<DatePicker 
					  name=""
					  label=""
					  format="DD/MM/YYYY"
					  value={dateNow}
					  ampm={false}
					  onChange={(e) => {handleOnChangeResponseValue(e,q)}}
					/>
				  </DemoContainer>
				</LocalizationProvider>
				
				</>}
				
				 {q.type=='comment' &&
				 <>			
				<TextField  id=""  label="" name=""  multiline rows={4}  sx={{ width: '100%' }}
					value={tempResponses[q.id]}
					onChange={(e) => {handleOnChangeResponseValue(e,q)}}
					inputProps={{ maxlength: q.settings.max_length }}
				/>
				{tempResponses[q.id][0] && <>
					{tempResponses[q.id][0].length}/{q.settings.max_length } Max char
				</>}
				{!tempResponses[q.id][0] && <>
					0/{q.settings.max_length } Max char
				</>} 						  
							
				</>}
			  {q.type=='single_choice' &&
			  <>
			  <FormControl>
				  <FormLabel id="demo-radio-buttons-group-label"></FormLabel>
				  <RadioGroup
					aria-labelledby="demo-radio-buttons-group-label"
					//defaultValue="unknown"
					name="radio-buttons-group"
					onChange={(e) => {handleOnChangeResponseValue(e,q)}}
				  >
				  {q.choices.map((opt,index) => (
					<FormControlLabel value={opt} control={<Radio />} label={opt} />
				  ))}
				  </RadioGroup>
				</FormControl>
				</>}
				
			{q.type=='multi_choice' &&
			  <>
				<FormGroup
					onChange={(e) => {handleOnChangeResponseValue(e,q)}}
				>
				{q.choices.map((opt,index) => (
				  <FormControlLabel value={opt} control={<Checkbox />} label={opt} />
				   ))}
				</FormGroup>
				</>}
				</div>
				<br/>
          </Typography>
		))}
		</>}
		
		{clientQuestionnaireModalMode=='view' &&
		<>
		{tempQuestionsAndResponses.map((qar,index) => (
          <Typography key={index} gutterBottom>
		  
		  <Typography variant="h6" component="h6">
		  {index+1})	{qar.question.question}
			</Typography>
			<br/>
			<div style={{  margin: '0 0 0 20px' }}>
				{qar.question.type=='text' &&
				<>
					{qar.response.response}
				</>}
				{qar.question.type=='number' &&
				<>
					{qar.response.response}
				</>}
			  
				{qar.question.type=='time' &&
				<>
					{formatTime(qar.response.response)}
				</>}
				
				{qar.question.type=='date' &&
				<>
					{formatDate(qar.response.response)}
				</>}
				
				{qar.question.type=='comment' &&
				<>						
					{qar.response.response}
				</>}
				
				{qar.question.type=='single_choice' &&
				<>
					{qar.response.response}
				</>}
				
				{qar.question.type=='multi_choice' &&
				<>
				{/*qar.response.response}*/}
				{qar.response.response.map((r,index) => (
					<>
					{r}<br/>
					
					</>
				))}
				</>}
				
				</div>
		
		<br/>
          </Typography>
	))}
		</>}
		</Typography>
		
        </DialogContent>
        <DialogActions>
		{clientQuestionnaireModalMode=='take' &&
		<>
			<Button onClick={handleClientQuestionnaireClose}>
				Cancel
			</Button>
			 <Button variant="contained" onClick={handleCompleteAddClientQuestionnaire}>
				Submit
			  </Button>
		</>}
		
		{clientQuestionnaireModalMode=='view' &&
		<>
			<Button onClick={handleClientQuestionnaireClose}>
				Close
			</Button>
		</>}
        </DialogActions>
      </BootstrapDialog>
	  
	  {/*   delete client questionnaire dialog */}
	 <Dialog
        open={openDeleteClientQuestionnaireModal}
        onClose={handleDeleteClientQuestionnaireClose}
        //aria-labelledby="alert-dialog-title"
		//aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to delete this client questionnaire?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
		  
          </DialogContentText>
        </DialogContent>
        <DialogActions>
			<Button onClick={handleDeleteClientQuestionnaireClose}>No</Button>
			<Button onClick={handleDeleteClientQuestionnaire} > Yes</Button>
        </DialogActions>
      </Dialog>
	
	{(questionnaireData.length==0) ?
	<>No questionnaires found</>
	:<>
	
	 <TableContainer component={Paper}>
		<Table sx={{ minWidth: 650 }} aria-label="simple table">
			<TableHead>
						  <TableRow>
							<TableCell>Title</TableCell>
							<TableCell></TableCell>
							<TableCell></TableCell>
							<TableCell></TableCell>
						  </TableRow>
						</TableHead>
						<TableBody>
						  {questionnaireData.map((row,index) => (
							<TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
							  <TableCell component="th" scope="row" style={{ verticalAlign: 'top' }}>
								{row.title}
							  </TableCell>
							  {/*<TableCell component="th" scope="row">
								{row.description}
						  </TableCell>*/}
						  
							  <TableCell component="th" scope="row" style={{ verticalAlign: 'top' }}>
							  {!clientQuestionnaireData[row.id] &&
							  <>
								None taken
							  </>}
							  {clientQuestionnaireData[row.id] &&
							  <>
								<ul>
									{clientQuestionnaireData[row.id].map((_cq,index) => (
										<li key={index}>
										{formatDateFull(_cq.created_date)} by {_cq.created_by.first_name} {_cq.created_by.last_name}
										<Button onClick={(e) => handleViewClientQuestionnaireModal(e,_cq)} size="small">View</Button>
										<Button onClick={(e) => handleDeleteClientQuestionnaireModal(e,_cq)}size="small">Delete</Button>
										<Tooltip title="Download">
									  <IconButton onClick={(e) => handleDownloadQuestionnaireResponse(e,_cq)} aria-label="download">
										<FileDownloadIcon />
									  </IconButton>
									</Tooltip>
										</li>
									 ))}
								</ul>
							  </>}
							  </TableCell>
							    <TableCell component="th" scope="row" style={{ verticalAlign: 'top' }}>
									<Tooltip title="Download">
									  <IconButton onClick={(e) => handleDownloadQuestionnaireResponses(e,row)} aria-label="download">
										<FileDownloadIcon />
									  </IconButton>
									</Tooltip>
							  </TableCell>
							   <TableCell component="th" scope="row" align="right" style={{ verticalAlign: 'bottom' }}>
								   {row.active==true &&
									<Button  variant="contained" onClick={(e) => handleCompleteAddClientQuestionnaireModal(e,row)}  >Take Questionnaire</Button>
							 }
							 </TableCell>
							  
							</TableRow>
						  ))}
						</TableBody>
					  </Table>
					</TableContainer>
	</>}
	
	</>
	:<></>}
	
	{/* Researcher */}
	{(currentUser.__t=='Researcher') ?
	<>
	
	</>
	:<></>}

	</AppLogout>
	</>
  ) 
  ;
};

export default ClientLogs;