import {useEffect,useState} from 'react';
import { useNavigate,useParams } from "react-router-dom";

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Divider from '@mui/material/Divider';
import { Button, Typography, Box } from "@mui/material";
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


import TopPrivateNavigation from '../components/TopPrivateNavigation.jsx'
import AppLogout from '../components/AppLogout.jsx'

//services
import AuthService from '../service/auth'
import ProjectService from '../service/Project'
import UploadService from '../service/Upload'
import DataBackUpService from '../service/DataBackUp'
import FoodService from '../service/Food'

function viewJsonData(json){
	return JSON.stringify(json);
}

const formatDate=function(d){
	var d = new Date(d);
	Number.prototype.padLeft = function(base,chr){
    var  len = (String(base || 10).length - String(this).length)+1;
    return len > 0? new Array(len).join(chr || '0')+this : this;
}
    var dformat = [
               d.getDate().padLeft(),
			   (d.getMonth()+1).padLeft(),
               d.getFullYear()].join('/') +' ' +
              [d.getHours().padLeft(),
               d.getMinutes().padLeft()].join(':');
	return dformat;
}

const  humanFileSize =function(bytes, si=false, dp=1) {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si 
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] 
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10**dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


  return bytes.toFixed(dp) + ' ' + units[u];
}

const formatTableName=function(str){
	return str.charAt(0).toUpperCase() + str.slice(1);
}

const checkIfTrue=function(b){
	if(b){
		return '70%'
	}else{
		return '100%'
	}
}

const Upload = () => {
	
	const navigate = useNavigate();
	//protected page check if logged in
	const [currentUser,setCurrentUser]=useState({});
	const [projectData,setProjectData]=useState([]);
	const [projectFound,setProjectFound]=useState(false);
	
	let project_id =ProjectService.getCurrentProject();//params.id;
	console.log('project_id',project_id);
	//table name
	let params = useParams();
	let table_name =formatTableName(params.table_name);
	const [tableName,setTableName]=useState(table_name);
	console.log('table_name',table_name);
	
	const [backUpData,setBackUpData]=useState([]);
	function loadDataBackUps(){
		var filter ={'global':true,'table_name':tableName,'visible':true};
		if((typeof project_id !=="undefined")&&(project_id!==null)){//project filter
			filter ={'global':false,'table_name':tableName,'project':project_id,'visible':true};
		}
		console.log('filter',filter);
		DataBackUpService.getByFilter(filter).then(function(res){
			setBackUpData(res.data);
			console.log(res.data);
		});
	}
	  
	const [loadingId, setLoadingId] = useState({});
	
	const checkLoadingId=function(id){
		return loadingId[id];
	};
	
	const clickHandler = async (e) => {
    const { id } = e.target;
		setLoadingId(ids => ({
		  ...ids,
		  [id]: true
		}));
	};

	const [selectedFile, setSelectedFile] = useState(null);
	const [error, setError] = useState(null);
	const [success, setSuccess] = useState(null);

	const handleFileChange = (event) => {
		const file = event.target.files[0];
		setSelectedFile(file);
	};
  
    const handleUpload = () => {
    if (selectedFile) {
	  
	  UploadService.uploadDataFile({'file':selectedFile}).then(function(res){
			console.log(res.data);
			setSuccess("Success: File uploaded.");
			var fileObject =res.data;
				//create back up record
				var obj ={'global':true,'project':project_id,file:fileObject._id,'table_name':tableName,'description':tableName+' items back up data file'};
				if((typeof project_id !=="undefined")&&(project_id!==null)){//project filter
					obj ={'global':false,'project':project_id,file:fileObject._id,'table_name':tableName,'description':tableName+' items back up data file '};
				}
				DataBackUpService.create(obj).then(function(res1){
				console.log(res1.data);
					loadDataBackUps();
				}).catch(function(error1){
						console.log(error1);
						setError("Error: "+error1.response.data.message);
				});
			
		}).catch(function(error){
				console.log(error);
				setError("Error: "+error.response.data.message);
		});
		
		
    } else {
      console.error("No file selected");
    }
  };
  
    const [validateData,setValidateData]=useState({});
  	const handleFileValidation=(e,backUpData) => {
		console.log('backUpData',backUpData);
		    const { id } = e.target;
				setLoadingId(ids => ({
				  ...ids,
				  [id]: true
				}));
			DataBackUpService.validate(backUpData).then(function(res1){
			console.log(res1.data);
			setValidateData(res1.data);
			loadDataBackUps();
			
			setLoadingId(ids => ({
				  ...ids,
				  [id]: false
				}));
			}).catch(function(error1){
				console.log(error1);
				setError("Error: "+error1.response.data.message);
				setLoadingId(ids => ({
				  ...ids,
				  [id]: false
				}));
			});
	};
	
  /*const [extractData,setExtractData]=useState({'headers':[],'data':[],'stats':{}});
  
  	const handleFileExtraction=(e,backUpData) => {
		console.log('backUpData',backUpData);
		    const { id } = e.target;
				setLoadingId(ids => ({
				  ...ids,
				  [id]: true
				}));
			DataBackUpService.extract(backUpData).then(function(res1){
			console.log(res1.data);
			setExtractData(res1.data);
			
			setLoadingId(ids => ({
				  ...ids,
				  [id]: false
				}));
			}).catch(function(error1){
				console.log(error1);
				setError("Error: "+error1.response.data.message);
				setLoadingId(ids => ({
				  ...ids,
				  [id]: false
				}));
			});
	};*/
	
	//delete file
	const handleFileDeleteModal=(e,backUpData) => {
		console.log('backUpData',backUpData);
		    const { id } = e.target;
			console.log('id',id);
			  setDeleteModalOpen(true);
			  setTempBackupData(backUpData);
	};
	
	  const [openDeleteModal, setDeleteModalOpen] = useState(false);
	  const [tempBackupData, setTempBackupData] = useState({});

	const handleDeleteModalClose = () => {
		setDeleteModalOpen(false);
	};
	
	const handleDeleteBackUp = () => {
		tempBackupData.visible=false;
		DataBackUpService.update(tempBackupData).then(function(res1){
			console.log(res1.data);
			setDeleteModalOpen(false);
			loadDataBackUps();
		}).catch(function(error1){
			console.log(error1);
				setError("Error: "+error1.response.data.message);
		});
	};
	
	//update food groups 
	const [updatingFoodGroups, setUpdatingFoodGroups] = useState(false);
	const [updatingFoodGroupsData, setUpdatingFoodGroupsData] = useState({});
	const handleUpdateFoodGroups = () => {
		setUpdatingFoodGroups(true);
		DataBackUpService.updateFoodGroups().then(function(res){
			console.log(res);
			setUpdatingFoodGroupsData(res.data);
			setUpdatingFoodGroups(false);
		}).catch(function(error){
			console.log(error);
				
		});
	};
	
	const handleRemoveFoodDoubles = () => {
		//setUpdatingFoodGroups(true);
		DataBackUpService.removeFoodDoubles().then(function(res){
			console.log(res.data);
			//setUpdatingFoodGroupsData(res.data);
			//setUpdatingFoodGroups(false);
		}).catch(function(error){
			console.log(error);
				
		});
	};
		
	useEffect(() => {
		if(!AuthService.isUserLoggedIn()){
			// not logged in navigate back to login
			navigate("/login");
		}else{
				setCurrentUser(AuthService.getCurrentUser());
				var account_type=AuthService.getCurrentUser().__t;
				console.log('account_type',account_type);
				
				console.log(AuthService.getCurrentUser());
				var currentUser=AuthService.getCurrentUser();
				//expired Account
				if(currentUser.expired){
					var string = "account_type="+AuthService.getCurrentUser().__t+"&type=expired";
					console.log('string',string);
					var encodedString = btoa(string); // Base64 encode the String
					console.log('encodedString',encodedString);
					navigate("/restricted/"+encodedString);
					
				}else{//unverified account
					if(!currentUser.email_verified){
						var string = "account_type="+AuthService.getCurrentUser().__t+"&type=emaiverification";
						console.log('string',string);
						var encodedString = btoa(string); // Base64 encode the String
						console.log('encodedString',encodedString);
						navigate("/restricted/"+encodedString);
						
					}else{////force change Password
						if(currentUser.force_password_change){
							//not implemented	
						
						}else{
							//load project if set 
							if((typeof project_id !=="undefined")&&(project_id!==null)){//project filter
								loadProjectById(project_id);
							}
							
							if(account_type=='Admin'){//Admin
						
							}else if(account_type=='Master'){//Master
								loadDataBackUps();
								
							}else if(account_type=='Researcher'){//Researcher
								
							}
						}
					}
				}	
		}
	}, []);
	
	function loadProjectById(project_id){
		ProjectService.getById(project_id).then(function(res){
		setProjectData(res.data);
		console.log(res.data);
		setProjectFound(true);
		}).catch(function(error){
			console.log(error);
			setProjectFound(false);
		});
	}
	
  return(
  <>
	<TopPrivateNavigation/>
	<AppLogout>
	
		{/* error no project found */}
	{(!projectFound) ?
		<>
		
		<br/>
		<Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" href="/">
          Home
        </Link>
		 <Typography color="text.primary">Upload</Typography>
		  <Typography color="text.primary">{table_name}</Typography>
      </Breadcrumbs>
	  
	  <br/>
	  <Divider />
		<br/>
		
		
		</>
	:<></>}
	{/* if project is found */}
	{(projectFound) ?
	<>
		<br/>
		<Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" href="/">
          Home
        </Link>
        <Link
          underline="hover"
          color="inherit"
          href="/projects"
        >
          Projects
        </Link>
		<Link
          underline="hover"
          color="inherit"
          href={`/project/${projectData._id}`}
        >
          {projectData.name}
        </Link>
	
		 <Typography color="text.primary">Upload</Typography>
		  <Typography color="text.primary">{table_name}</Typography>
      </Breadcrumbs>
	  
	  <br/>
	  <Divider />
		<br/>
	
	</>
	:<></>}
	
	{/* Admin */}
	{(currentUser.__t=='Admin') ?
	<>

	
	{ !updatingFoodGroups &&
		<Button  size="small"  variant="contained"  onClick={(e) => handleUpdateFoodGroups(e)} >
				Update food groups
		</Button>
	} 
	{ updatingFoodGroups &&
		<CircularProgress  />
	} 
	{/*{viewJsonData(updatingFoodGroupsData.updated)}*/}
	<br/>
	<Button  size="small"  variant="contained"  onClick={(e) => handleRemoveFoodDoubles(e)} >
				Remove food doubles
	</Button>
	</>
	:<></>}
	
	{/* Master */}
	{(currentUser.__t=='Master') ?
	<>
	

	{/* upload form*/}	
	<Box p={3} border="1px dashed #ccc" borderRadius={10} textAlign="center">
      <input
        type="file"
        onChange={handleFileChange}
        style={{ display: "none" }}
        id="file-input"
      />
      <label htmlFor="file-input">
        <Button variant="outlined" component="span">
          Select File (csv,xls,xlxs only)
        </Button>
      </label>
      {selectedFile && (
        <div>
          <Typography variant="subtitle1" mt={2}>
            Selected File: {selectedFile.name}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleUpload}
            mt={2}
          >
            Upload
          </Button>
        </div>
      )}
      {error && (
        <Typography variant="body2" color="error.dark" mt={2}>
          {error}
        </Typography>
      )}
	  {success && (
        <Typography variant="body2" color="success.main" mt={2}>
          {success}
        </Typography>
      )}
    </Box>
	
	
	
	  <br/> <br/>
	  
    <Dialog
        open={openDeleteModal}
        onClose={handleDeleteModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to delete this backup file?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
        
          </DialogContentText>
        </DialogContent>
        <DialogActions> 
			<Button color="error"  onClick={handleDeleteModalClose} > No</Button>
			<Button color="success"  onClick={handleDeleteBackUp} autoFocus >Yes</Button>
        
        </DialogActions>
      </Dialog>
	  
	  {(backUpData.length==0) ?
		<>
		<h4>No files found!!</h4>
		</>
		
		:<>
		<h4>{backUpData.length} file(s) found</h4>
		<br/>
		
		
		
	<Grid container spacing={3}>
		{backUpData.map((row,index) => (
         <Grid key={row._id} item item xs={12} sm={6} md={4}>
			<Card style={{ opacity: checkIfTrue(row.active) }} variant="outlined" sx={{ minWidth: 25 }}>
				<CardContent>
					<Typography variant="h7" component="div">
					{row.file.details.originalname}
					</Typography>
					<Typography sx={{ mb: 1.5 }} color="text.secondary">
			  
					</Typography>
					<Typography variant="body2">
						created: {formatDate(row.created_date)}<br />
						filesize: {humanFileSize(row.file.details.size)}
						<br />
					</Typography>
				</CardContent>
				<CardActions>
					
					{(!row.active) ?
					<>
						<Button id={row._id} size="small"  variant="contained"  onClick={(e) => handleFileValidation(e,row)} >
							Upload data
						</Button>
						{ checkLoadingId(row._id) &&
							<CircularProgress  />
						} 
					</>:<>
					{row.rows} rows uploaded
					<br/>
					</>}
					
					{/*<Button id={row._id} size="small"  variant="contained"  onClick={(e) => handleFileExtraction(e,row)} >
						get details
					</Button>*/}
					<Button id={row._id} size="small" color="error"  variant="contained"  onClick={(e) => handleFileDeleteModal(e,row)} >
						Delete
		</Button>
				  
				</CardActions>
			</Card>	
		</Grid>))}
	</Grid>
	
	
		</>}
	  

<br/> <br/><br/> <br/>

<br/> <br/><br/> <br/>
	
	</>
	:<></>}
	
	{/* Researcher */}
	{(currentUser.__t=='Researcher') ?
	<>
	
	</>
	:<></>}

	</AppLogout>
	</>
  ) 
  ;
};

export default Upload;