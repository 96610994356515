import {useEffect,useState} from 'react';
import { useNavigate ,useParams} from "react-router-dom";

import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Divider from '@mui/material/Divider';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';

import MenuBookIcon from '@mui/icons-material/MenuBook';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';

//import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
//import DeleteIcon from '@mui/icons-material/Delete';
import AlarmIcon from '@mui/icons-material/Alarm';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import EditIcon from '@mui/icons-material/Edit';

//import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
//import FormHelperText from '@mui/material/FormHelperText';
//import { deepOrange, green } from '@mui/material/colors';
import Skeleton from '@mui/material/Skeleton';

//import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
//import Button from '@mui/material/Button';

//import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
//import Button from '@mui/material/Button';
//import Typography from '@mui/material/Typography';
//import Icon from '@mui/material/Icon';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import IconButton from '@mui/material/IconButton';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import { convertToRaw,convertFromRaw, EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import { Fragment } from "react";

import parse from 'html-react-parser';

import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
//import Button from '@mui/material/Button';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
//import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
//import Typography from '@mui/material/Typography';
//import FastfoodIcon from '@mui/icons-material/Fastfood';
import DeleteIcon from '@mui/icons-material/Delete';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
//import FormControlLabel from '@mui/material/FormControlLabel';
//import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import TopPrivateNavigation from '../components/TopPrivateNavigation.jsx'
import AppLogout from '../components/AppLogout.jsx'
import SmartPortionComponent from '../components/SmartPortion.jsx'

//services
import AuthService from '../service/auth'
import ProjectService from '../service/Project'
import ClientService from '../service/Client'
import ClientLogService from '../service/ClientLog'
import ClientMealLogService from '../service/ClientMealLog'
import ClientFoodLogService from '../service/ClientFoodLog'
import FoodService from '../service/Food'
import RecipeService from '../service/Recipe'
import SmartPortionService from '../service/SmartPortion'

const formatTime=function(d){
	var d = new Date(d);
	Number.prototype.padLeft = function(base,chr){
    var  len = (String(base || 10).length - String(this).length)+1;
    return len > 0? new Array(len).join(chr || '0')+this : this;
}
    var dformat =[d.getHours().padLeft(),
               d.getMinutes().padLeft()].join(':');
	return dformat;
}

const formatDate=function(d){
	var d = new Date(d);
	Number.prototype.padLeft = function(base,chr){
    var  len = (String(base || 10).length - String(this).length)+1;
    return len > 0? new Array(len).join(chr || '0')+this : this;
}
    var dformat = [
               d.getDate().padLeft(),
			   (d.getMonth()+1).padLeft(),
               d.getFullYear()].join('/') ;
	return dformat;
}

const formatJson=function(s){
	return JSON.stringify(s);
}

const getRandomInt=function(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

const removeKeys=function(object,keys){
	var obj={};
	for (let key in object) {
		 if(!keys.includes(key)){
			 obj[key]=object[key];
		 }
	}
	return obj;
}

const returnPages=function(obj,pages,index){
	var keys= Object.keys(obj);
	var n=keys.length;
	var pageSize=0;
	if(n % pages == 0){
		pageSize=n/pages;
	}else{
		pageSize=(n/pages)+1;
	}
	//return pageSize*(index-1);
	return keys.splice(pageSize*(index-1), pageSize);
}

/*modal test*/
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const style_parent = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  overflow:'scroll',
    height:'80%',
    display:'block',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

function ChildModal() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
	  console.log('open...');
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Fragment>
      <Button onClick={handleOpen}>Open Child Modal</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style, width: 300 ,height:300}}>
          <h2 id="child-modal-title">Text in a child modal</h2>
          <p id="child-modal-description">
            Lorem ipsum, dolor sit amet consectetur adipisicing elit.
          </p>
          <Button onClick={handleClose}>Close Child Modal</Button>
        </Box>
      </Modal>
    </Fragment>
  );
}


const steps = ['Search food', 'Enter quantity', 'Add food/recipe'];

let quantification_methods =[
'Food atlas',
'Weighed',
'Manufacturer’s information',
'Portions data (HC)',
'Household measure',
'Average portion weights (study specific)',
'Estimated',
];

const cooking_methods=[
'Not cooked',
'Grilled',
'Boiled',
'Steamed/blanched',
'Stewed',
'Fried or stir-fried',
'Deep fat fried',
'Roasted (cooked with fat)',
'Baked (cooked without fat)',
'Microwaved',
'Barbequed',
'Combination of methods',
'Unknown',
'Air-fried/Dry-fried'

];

const beverage_codes=[
'Not applicable',
'Milk in cereal',
'Milk as a beverage',
'Milk in tea/coffee/hot chocolate',
'Milk in soup/sauce',
'Milk added to a composite meal',
'Water as a beverage',
'Water in tea/coffee/hot chocolate',
'Water in soup/sauce',
'Water added to a composite meal',
'Water in effervescent supplement',
'Other',

];

const packaging_types=[
'Glass',
'Glass & Metal',
'Glass & Plastic',
'Glass & Paper & Board',
'Plastic',
'Metallised Plastic',
'Plastic & Metal',
'Plastic & Paper & Board',
'Paper & Board',
'Tetra-pak type packaging',
'Soups/sauces type packet or sachet',
'Canned',
'Foil',
'Metal & Paper & Board',
'Waxes',
'Unknown',
'Loose/unpackaged',
'Packaging Unknown',
'Netting',

];

const meals =['Breakfast','Lunch','Lunch (light meal)','Lunch (main meal)','Evening meal(light meal)','Evening meal (main meal)','Morning snack','Afternoon snack','Evening snack','Night snack','Brunch','Desert','Non alcoholic beverages','Alcoholic beverages','Other'];
const preparedLocations =['Home','Work/College/University','Other persons home','Restaurant/hotel/public house','Coffee shop/sandwich bar/delicatessen','Shop bought','Takeaway/Fast food chain','Cinema','Social function','Meals on wheels/community care','Other'];
const eatenLocations =['Kitchen','Sitting room','Bedroom',' On the go (in house)','On the go (out of the house)','Table in a restaurant or other location (other persons home)','Desk in work','In the car','Other'];
					

const ClientLog = () => {
	
	const navigate = useNavigate();
	//protected page check if logged in
	const [currentUser,setCurrentUser]=useState({});
	
	const [projectData,setProjectData]=useState([]);
	const [projectSettings,setProjectSettings]=useState({});
	const [projectFound,setProjectFound]=useState(false);
	
	const [clientData,setClientData]=useState([]);
	const [clientLoaded,setClientLoaded]=useState(false);
	
	const [clientFoodLogsData, setClientFoodLogsData] = useState({});
	const [clientFoodLogsDataLoaded,setClientFoodLogsDataLoaded]=useState(false); 
	
	const [clientLogData,setClientLogData]=useState([]);
	const [clientMealLogData,setClientMealLogData]=useState([]);
	const [clientFoodLogData,setClientFoodLogData]=useState([]);
	const [clientMealsByIdLogData,setClientMealsByIdLogData]=useState([]);
	const [clientMealFoodLogData,setClientMealFoodLogData]=useState([]);
	
	const [nameEditMode,setNameEditMode]=useState(false);
	const [tempLogName,setTempLogName]=useState("");
	const handNameEditModeOn = (event) => {
		console.log(event);
		setNameEditMode(true);
	};
	
	const handNameEditModeOff = (event) => {
		console.log(event);
		setNameEditMode(false);
		updateClientLogName(tempLogName);
	};
	
	let params = useParams();
	let client_log_id =params.client_log_id;
	console.log('client_log_id',client_log_id);
	
	let project_id =ProjectService.getCurrentProject();//params.id;
	console.log('project_id',project_id);
		
	useEffect(() => {
		if(!AuthService.isUserLoggedIn()){
			// not logged in navigate back to login
			navigate("/login");
		}else{
				setCurrentUser(AuthService.getCurrentUser());
				var account_type=AuthService.getCurrentUser().__t;
				console.log('account_type',account_type);
				
				console.log(AuthService.getCurrentUser());
				var currentUser=AuthService.getCurrentUser();
				//expired Account
				if(currentUser.expired){
					var string = "account_type="+AuthService.getCurrentUser().__t+"&type=expired";
					console.log('string',string);
					var encodedString = btoa(string); // Base64 encode the String
					console.log('encodedString',encodedString);
					navigate("/restricted/"+encodedString);
					
				}else{//unverified account
					if(!currentUser.email_verified){
						var string = "account_type="+AuthService.getCurrentUser().__t+"&type=emaiverification";
						console.log('string',string);
						var encodedString = btoa(string); // Base64 encode the String
						console.log('encodedString',encodedString);
						navigate("/restricted/"+encodedString);
						
					}else{////force change Password
						if(currentUser.force_password_change){
							//not implemented	
						
						}else{
							if((typeof project_id !=="undefined")&&(project_id!==null)){//project filter
								loadProjectById(project_id);
								loadFoodDataByFilter({$or:true,'global':true,'approved':true,'project':project_id});
								loadRecipes({'project':project_id});
							}else{
								//loadFoodData();
								loadFoodDataByFilter({$or:true,'global':true,'active':true});
								loadRecipes({'global':true});
							}
							loadFoodTranslation()
							
							if((typeof client_log_id !=="undefined")&&(client_log_id!==null)){
								loadClientLogById(client_log_id);
								//loadClientMealLogById(client_log_id);
								//loadClientFoodLogById(client_log_id);
								loadClientMealFoodLogById(client_log_id);
							}
							//load
							if(account_type=='Admin'){//Admin
						
							}else if(account_type=='Master'){//Master
							
								
							}else if(account_type=='Researcher'){//Researcher
								
							}
						}
					}
				}	
		}
	}, []);
	
	
	function loadProjectById(project_id){
		ProjectService.getById(project_id).then(function(res){
		setProjectData(res.data);
		setProjectSettings(res.data.settings);
		//quantification_methods=['test'];
		console.log(res.data);
		setProjectFound(true);
		}).catch(function(error){
			console.log(error);
			setProjectFound(false);
		});
	}
	function loadClientById(client_id){
			ClientService.getById(client_id).then(function(res){
				setClientData(res.data);
				setClientLoaded(true);
				console.log(res.data);
				var _client= res.data;

				console.log('_client',_client);
			}).catch(function(error){
					console.log(error);
			});
	}


	/* client logs */
		function loadClientMealFoodLogById(client_log_id){
				ClientLogService.getMealFood(client_log_id).then(function(res){
					setClientLogData(res.data.log);
					setClientMealFoodLogData(res.data.meal_foods);
					setClientMealLogData(res.data.meals);
					setClientFoodLogData(res.data.foods);
					setClientMealsByIdLogData(res.data.meals_by_id);
					console.log(res.data);
					//var _client_id=res.data.client;
					//loadClientById(_client_id);
				}).catch(function(error){
						console.log(error);
				});
		}

		
		function loadClientLogById(client_log_id){
				ClientLogService.getById(client_log_id).then(function(res){
					setClientLogData(res.data);
					console.log(res.data);
					var _client_log=res.data;
					var _client_id=res.data.client;
					
					setTempLogName(_client_log.name);
					loadClientById(_client_id);
					
					var clientLogNotes = _client_log.notes;
					if(clientLogNotes==''){
						clientLogNotes={};
					}
					setLogNotesEditorState(EditorState.createWithContent(convertFromRaw(JSON.parse(clientLogNotes))));
				
				}).catch(function(error){
						console.log(error);
				});
		}
		
		function updateClientLogName(name){
			clientLogData.name=name
				ClientLogService.update(clientLogData).then(function(res){
					
					console.log(res.data);
					
				}).catch(function(error){
						console.log(error);
				});
		}
		
		function updateClientLogNotes(notes){
			clientLogData.notes=notes
				ClientLogService.update(clientLogData).then(function(res){
					
					console.log(res.data);
					
				}).catch(function(error){
						console.log(error);
				});
		}
		
		function loadClientMealLogById(client_log_id){
				ClientMealLogService.getByFilter({'client_log_id':client_log_id}).then(function(res){
					setClientMealLogData(res.data);
					console.log(res.data);
					var _mealsLogged=res.data;
					console.log('_mealsLogged',_mealsLogged);
					console.log('_mealsLogged length',_mealsLogged.length);
					/*var _m ={}
					for(var i=0;i<_mealsLogged.length;i++){
						var _meal= _mealsLogged[i];
						_m[_meal._id]=[];
					}
					console.log('_m',_m);
					setClientFoodLogsData(_m);*/
					loadClientFoodLogById(client_log_id);
					
				}).catch(function(error){
						console.log(error);
				});
		}
		

		function loadClientFoodLogById(client_log_id){
				ClientFoodLogService.getByFilter({'client_log_id':client_log_id}).then(function(res){
					//setClientFoodLogData(res.data);
					console.log(res.data);
					var loggedFoods=res.data;
					console.log(loggedFoods);
					console.log('clientMealLogData.length.length',clientMealLogData.length);
					console.log('loggedFoods.length',loggedFoods.length);
					
					var _m ={}
					for(var i=0;i<clientMealLogData.length;i++){
						var _meal= clientMealLogData[i];
						_m[_meal._id]=[];
					}
					console.log('_m',_m);
					
					for(var i=0;i<loggedFoods.length;i++){
						var _foodlog=loggedFoods[i];
						console.log('_foodlog',_foodlog);
						console.log('client_meal_log_id',_foodlog.client_meal_log_id);
						if(!_m.hasOwnProperty(_foodlog.client_meal_log_id)){
							//_m[_foodlog.client_meal_log_id]=[];
						}
						_m[_foodlog.client_meal_log_id].push(_foodlog);
						
					}
					console.log('_m',_m);
					setClientFoodLogsData(_m);
					/*
					
					var _f ={};
					
					console.log('Meal length',clientMealLogData.length);
					console.log('clientFoodLogsData',clientFoodLogsData);
					console.log('_f',_f);
					for(var i=0;i<loggedFoods.length;i++){
						var _foodlog=loggedFoods[i];
						console.log('_foodlog',_foodlog);
						console.log('client_meal_log_id',_foodlog.client_meal_log_id);
						//_f[_foodlog.client_meal_log_id].push(_foodlog);
						//if(!_f.hasOwnProperty(_foodlog.client_meal_log_id)){
						//	_f[_foodlog.client_meal_log_id]={"total":0,"foods":[]};
						//}
						//_f[_foodlog.client_meal_log_id]["foods"].push(_foodlog);
						//_f[_foodlog.client_meal_log_id]["total"]++;
					}
					console.log(_f);
					setClientFoodLogsData(_f);*/
				
				setClientFoodLogsDataLoaded(true);
				
				}).catch(function(error){
						console.log(error);
				});
		}
		
		function createClientMealLog(client_log_id,clientMealLog){
			ClientMealLogService.create(clientMealLog).then(function(res){
				console.log(res.data);
				//loadClientMealLogById(client_log_id);
				loadClientMealFoodLogById(client_log_id);
				setNewMealLogModalOpen(false);
			}).catch(function(error){
					console.log(error);
			});
		}
		
		const [ShowErrorMessage,setErrorStatus]=useState(false); 
		const [ErrorText,setErrorMessage]=useState(""); 
	
		const [ShowSuccessMessage,setSuccessStatus]=useState(false); 
		const [SuccessText,setSuccessMessage]=useState("");
	
		
		const [openNewMealLogModal, setNewMealLogModalOpen] = useState(false);
		const handleNewMealLogModalOpen = () => {
			setNewMealLogModalOpen(true);
		};
		
		const handleNewMealLogModalClose = () => {
			setNewMealLogModalOpen(false);
		};
		
		//meal type
		const [mealType, setMealType] = useState('');
		const [showOtherMealType, setShowOtherMealType] = useState(false);
		const handleMealTypeChange = (event) => {
			console.log(event.target.value);
			setMealType(event.target.value);
			if(event.target.value=="Other"){
				setShowOtherMealType(true);
			}else{
				setShowOtherMealType(false);
			}
		};
		
		//new meal log
	const handleSubmitNewMealLog=(event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		var _tempClientMealLog={'client_log_id':client_log_id,'created_by':AuthService.getCurrentUser()._id};
		//form data 
		const data = new FormData(event.currentTarget);
		//meal type
		console.log('mealType',mealType);
		var otherMealType=null;
		if(mealType=='Other'){
			otherMealType=data.get('otherMealType');
		}
		console.log('otherMealType',otherMealType);
		_tempClientMealLog.type=mealType;
		_tempClientMealLog.type_other=otherMealType;
		
		//location_prepared
		_tempClientMealLog.location_prepared=locationPrepared;
		var otherLocationPrepared=null;
		if(locationPrepared=='Other'){
			otherLocationPrepared=data.get('otherLocationPrepared');
		}
		_tempClientMealLog.location_prepared_other=otherLocationPrepared;
	
		//location_eaten
		_tempClientMealLog.location_eaten=locationEaten;
		var otherLocationEaten=null;
		if(locationEaten=='Other'){
			otherLocationEaten=data.get('otherLocationEaten');
		}
		_tempClientMealLog.location_eaten_other=otherLocationEaten;
	
		//tv_and_screens_use
		_tempClientMealLog.tv_and_screens_use=tvAndScreenUse;

		//time
		var _hrsmins=data.get('mealTime');
		var _hrminsarr = _hrsmins.split(':');
		var _tempDate= new Date(clientLogData.date);
		_tempDate.setHours(parseInt(_hrminsarr[0]));
		_tempDate.setMinutes(parseInt(_hrminsarr[1]));
		console.log(_tempDate);
		_tempClientMealLog.time=_tempDate;
		
		//notes
		/*if(tempData !== null) {
			console.log(JSON.stringify(tempData));
			_tempClientMealLog.notes=JSON.stringify(tempData);
		}else{
			_tempClientMealLog.notes=JSON.stringify(tempContent);
		}*/
		console.log('_tempClientMealLog',_tempClientMealLog);
		
		console.log('clientLogData',clientLogData.date);
		createClientMealLog(client_log_id,_tempClientMealLog)
		
		
	};
	
	//time
	//const [mealTime, setMealTime] = useState(dayjs(new Date()));
	const [mealTime, setMealTime] = useState(dayjs(new Date().setHours(0, 0, 0, 0)));
	//const [mealFormatedTime, setMealFormatedTime] = useState();
	const handleMealTimeChange = (event) => {
		console.log(event);
		//const tempDate = new Date(event.$d);
		//console.log('tempDate',tempDate);
		//console.log('tempDate time',formatTime(tempDate));
		//setMealTime(formatTime(tempDate));
		
	};
	
		//location prepared
		const [locationPrepared, setLocationPrepared] = useState('');
		const [showOtherLocationPrepared, setShowOtherLocationPrepared] = useState(false);
		const handleLocationPreparedChange = (event) => {
			console.log(event.target.value);
			setLocationPrepared(event.target.value);
			if(event.target.value=="Other"){
				setShowOtherLocationPrepared(true);
			}else{
				setShowOtherLocationPrepared(false);
			}
		};
		
		//location eaten
		const [locationEaten, setLocationEaten] = useState('');
		const [showOtherLocationEaten, setShowOtherLocationEaten] = useState(false);
		const handleLocationEatenChange = (event) => {
			console.log(event.target.value);
			setLocationEaten(event.target.value);
			if(event.target.value=="Other"){
				setShowOtherLocationEaten(true);
			}else{
				setShowOtherLocationEaten(false);
			}
		};
		
		//tv & screens yes no 
		const [tvAndScreenUse, setTvAndScreenUse] = useState('');
		const handleTvAndScreenUseChange = (event) => {
			console.log(event.target.value);
			setTvAndScreenUse(event.target.value);
		};
		
	//Notes
	const [tempData,setTempData]=useState(null);
	const tempContent ={"blocks":[{"key":"6vocr","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}};
		
	const [editorState, setEditorState] = useState(EditorState.createWithContent(convertFromRaw(tempContent)));
	  const onEditorStateChange = function (editorState) {
		setEditorState(editorState);
		const content = JSON.stringify(convertToRaw(editorState.getCurrentContent()));
		console.log(content);
		setTempData(convertToRaw(editorState.getCurrentContent()));
	};
	
	//client log Notes
	const [tempLogNotes,setTempLogNotes]=useState(null);
	const tempContent1 ={"blocks":[{"key":"6vocr","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}};
		
	const [logNotesEditorState, setLogNotesEditorState] = useState(EditorState.createWithContent(convertFromRaw(tempContent1)));
	  const onLogNotesEditorStateChange = function (logNotesEditorState) {
		setLogNotesEditorState(logNotesEditorState);
		const content = JSON.stringify(convertToRaw(logNotesEditorState.getCurrentContent()));
		console.log(content);
		console.log("##############################################");
		setTempData(convertToRaw(logNotesEditorState.getCurrentContent()));
		updateClientLogNotes(content);

	};
	
	const [FoodData,setFoodData]=useState([]);
	const [FilteredFoodData,setFilteredFoodData]=useState([]);
	const [FoodDataLoaded,setFoodDataLoaded]=useState(false);
	
	function loadFoodDataByFilter(filter){
		FoodService.getByFilter(filter).then(function(res){
			setFoodData(res.data);
			setFoodDataLoaded(true);
			console.log(res.data);
			console.log('FoodDataLoaded',FoodDataLoaded);
		});
	}
	
	const [foodTranslations,setFoodTranslations]=useState(false);
	function loadFoodTranslation(){
		FoodService.getTranslations().then(function(res){
			setFoodTranslations(res.data);
			console.log(res.data);
				//createTemplate(res.data);
		}).catch(function(error){
			console.log(error);
		});
	}
	
	const [RecipeData,setRecipeData]=useState([]);
	function loadRecipes(filter){
		RecipeService.getByFilter(filter).then(function(res){
			setRecipeData(res.data);
			console.log(res.data);
				//createTemplate(res.data);
		}).catch(function(error){
			console.log(error);
		});
	}
	
			
	/*const [openNewFoodLogModal, setNewFoodLogModalOpen] = useState(false);
	const [tempMeal, setTempMeal] = useState({});
		const handleNewFoodLogModalOpen = (e,meal) => {
			setTempMeal(meal);
			setQuantity(100);
			console.log('opening food log form modal....',quantity);
			setNewFoodLogModalOpen(true);
			
		};
		
		const handleNewFoodLogModalClose = () => {
			setNewFoodLogModalOpen(false);
		};
		
	//handleAddFoodToLog
	
	const handleAddFoodToLog = (e,food) => {
		console.log('tempMeal',tempMeal);

		console.log(food.name);
		//quantity 
		var quantity =getRandomInt(100,200);
		
		console.log('quantity',quantity);
		//setNewFoodLogModalOpen(false);
		var _clientFoodLog={};
		createClientFoodLog(tempMeal,food,quantity);
	};
		
	const handleAddRandomFood = (e) => {
		console.log('tempMeal',tempMeal);
		//console.log('FoodData',FoodData);
		var index =Math.floor(Math.random()*FoodData.length);
		console.log('FoodData.length',FoodData.length);
		console.log('index',index);
		var food = FoodData[index];
		console.log(food.name);
		//quantity 
		var quantity =getRandomInt(100,200);
		
		console.log('quantity',quantity);
		//setNewFoodLogModalOpen(false);
		var _clientFoodLog={};
		createClientFoodLog(tempMeal,food,quantity);
	};
	
	
		
		function createClientFoodLog(meal,food,quantity){
			ClientFoodLogService.create({'client_log_id':client_log_id,'client_meal_log_id':meal._id,'food':food._id,'quantity':quantity,'created_by':AuthService.getCurrentUser()._id}).then(function(res){
				console.log(res.data);
				loadClientMealFoodLogById(client_log_id);
				setNewFoodLogModalOpen(false);
			}).catch(function(error){
					console.log(error);
			});
		}
		*/
		
	//handleFoodSearchChange
	///const [FilteredFoodData,setFilteredFoodData]=useState([]);
	const handleFoodSearchChange = (event) => {
		setFilteredFoodData([]);
		var filteredList=[];
		//console.log(event.target.value);
		var keyword =event.target.value
		if(keyword.length>1){
			//search food data 
			for(var i=0;i<FoodData.length;i++){
			//if(FoodData[i].name)
				if(FoodData[i].name.toLowerCase().includes(keyword.toLowerCase()) || FoodData[i].code.toLowerCase().includes(keyword.toLowerCase())){
					var _food=FoodData[i];
					_food.type='food';
					filteredList.push(_food);
				}
			}
			//search recipe data 
			for(var i=0;i<RecipeData.length;i++){
				if(RecipeData[i].name.toLowerCase().includes(keyword.toLowerCase()) || RecipeData[i].code.toLowerCase().includes(keyword.toLowerCase())){
					var _recipe=RecipeData[i];
					_recipe.type='recipe';
					filteredList.push(_recipe);
				}
			}
			//sort by name
			let sortedData = filteredList.sort(function(a,b){
			   // here a , b is whole object, you can access its property
			   //convert both to lowercase
				  let x = a.name.toLowerCase();
				  let y = b.name.toLowerCase();

			   //compare the word which is comes first
				  if(x>y){return 1;} 
				  if(x<y){return -1;}
				  return 0;
				});
			setFilteredFoodData(sortedData);
			
		}
		
	}
	
	const handleFoodSearchChange_ = (event) => {
		
		var keyword =event.target.value
	FoodService.getByKeywords(keyword).then(function(res){
			//setFoodData(res.data);
			//setFoodDataLoaded(true);
			console.log(res.data);
		
		});
	
	}

	const selectPage=function(array,page_number){
		return array;//.splice(0,10);
	}
	
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [tempFood, setTempFood] = useState({});
    const [openFoodInfo, setFoodInfoOpen] = useState(false);
  const handleFoodInfoOpen = (e,food) => {
	  console.log('open...');
	  console.log('food...',food);
	  setTempFood(food);
    setFoodInfoOpen(true);
  };
  const handleFoodInfoClose = () => {
    setFoodInfoOpen(false);
  };
  
  
    const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());

  const isStepOptional = (step) => {
    return false;//step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
   /* let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }*/
console.log('activeStep',activeStep);
if(activeStep==2){
	
	var clientFoodLog={
		'client_log_id': client_log_id,
		'client_meal_log_id': tempMeal._id,
		'food': tempFood._id,
		'quantity': quantity,
		'quantification_method': quantificationMethod,
		'cooking_method': cookingMethod,
		'beverage_code': beverageCode,
		'packaging_type': packagingType,
		'packaging_size': packagingSize,
		'created_by':AuthService.getCurrentUser()._id
	}
	//food
	if(tempFood.type=='food'){
		clientFoodLog['food']= tempFood._id;
		clientFoodLog['food_or_recipe']= 'food';
	}
	//recipe
	if(tempFood.type=='recipe'){
		clientFoodLog['recipe']= tempFood._id;
		clientFoodLog['food_or_recipe']= 'recipe';
	}
	
	ClientFoodLogService.create(clientFoodLog).then(function(res){
				console.log(res.data);
				
				
				setOpen(false);
				setActiveStep((prevActiveStep) => 0);
				setFilteredFoodData([]);
				loadClientMealFoodLogById(client_log_id);
				
			}).catch(function(error){
					console.log(error);
			});
	
	console.log('IM FINISHED.....');
		
}else{
	   setActiveStep((prevActiveStep) => prevActiveStep + 1);
	
}
 
    //setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  
  const [tempMeal, setTempMeal] = useState({});
    const handleOpenFoodModal = (e,meal) => {
		
		console.log('add food modal.....');
		setTempMeal(meal);
		setFilteredFoodData([]);//clear search
		setActiveStep(0);//back to 1st screen
		setQuantity('');
		setOpen(true);
  };
  
  const handleAddToLog=(event,food)=>{
	  
	  setTempFood(food);
	  loadSmartPortion(food);
	  console.log();
	  console.log('move next.....');
	  setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  }
  
  
  	const [smartPortion,setSmartPortion]=useState({});	
	const [smartPortionLoaded,setSmartPortionLoaded]=useState(false);	
	function loadSmartPortion(food){
			SmartPortionService.getByFoodCode(food.code).then(function(res){
				setSmartPortion(res.data);
				console.log(res.data);
				setSmartPortionLoaded(true);
			}).catch(function(error){
					console.log(error);
			});
	}
	
	const handleClick = (e,value) => {
     setQuantity(parseInt(value));
  };
  
  
  /*COMPLETE Client lOG*/
    const [completeLogQuestions, setCompleteLogQuestions] = useState(['Was the quantity of food and drink that the participant had yesterday about what they usually have, less than usual or more than usual:','If different than usual was this because:']);
    const [completeLogAnswers, setCompleteLogAnswers] = useState(['','']);
    const [completeLogModalStatus, setCompleteLogModalStatus] = useState(false);
	const handleCompleteLogModalOpen = () => {
		setCompleteLogModalStatus(true);
	};
	const handleCompleteLogModalClose = () => {
		setCompleteLogModalStatus(false);
	};
	
	const handleCompleteLogModalCloseAndOpenFood= () => {
		setCompleteLogModalStatus(false);
	};
  //handleCompleteClientLog
  	const handleCompleteClientLogModal = (event) => {
		console.log('complete log',clientLogData);
		setCompleteLogModalStatus(true);
	}
	
	const handleCompleteClientLog = (event) => {
		console.log('complete log',clientLogData);
		console.log('completeLogAnswers',completeLogAnswers);
		var finish_questions=[
		{'question':completeLogQuestions[0],'answere':completeLogAnswers[0]},
		{'question':completeLogQuestions[1],'answere':completeLogAnswers[1]},
		];
		clientLogData.finish_questions=finish_questions;
		clientLogData.completed=true;
		ClientLogService.update(clientLogData).then(function(res){
				console.log(res.data);
			setCompleteLogModalStatus(false);
				
			}).catch(function(error){
					console.log(error);
			});
	}
	
	//quantity
	const [quantity, setQuantity] = useState('');
	const handleQuantityChange = (event) => {
		setQuantity(event.target.value);
	};
	
	//quantification method
	const [quantificationMethod, setQuantificationMethod] = useState('');
	const handleQuantificationMethodChange = (event) => {
		setQuantificationMethod(event.target.value);
	};
	
	//cooking_methods
	const [cookingMethod, setCookingMethod] = useState('');
	const handleCookingMethodChange = (event) => {
		setCookingMethod(event.target.value);
	};
	
	//beverage_codes
	const [beverageCode, setBeverageCode] = useState('');
	const handleBeverageCodeChange = (event) => {
		setBeverageCode(event.target.value);
	};
	
	
	//packaging_types
	const [packagingType, setPackagingType] = useState('');
	const handlePackagingTypeChange = (event) => {
		setPackagingType(event.target.value);
	};
	
	
	//packaging_size
	const [packagingSize, setPackagingSize] = useState(0);
	const handlePackagingSizeChange = (event) => {
		setPackagingSize(event.target.value);
	};
	
	//handleSwitchView
	const [tableView, setTableView] = useState(true);
	const handleSwitchView = (event) => {
		if(tableView){
			setTableView(false);
		}else{
			setTableView(true);
		}
		console.log('tableView',tableView);
	};
	
	const handleDeleteFood= (event,food) => {
		console.log('food',food);
		ClientFoodLogService.deleteById(food._id).then(function(res){
				console.log(res.data);
				loadClientMealFoodLogById(client_log_id);
				
			}).catch(function(error){
					console.log(error);
			});
		
		
	};
	
  return(
  <>
	<TopPrivateNavigation/>
	<AppLogout>
	
		{/* error no project found */}
	{(!projectFound) ?
		<>
		
		<br/>
		<Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" href="/">
          Home
        </Link>
		 <Typography color="text.primary">ClientLog</Typography>
      </Breadcrumbs>
	  
	  <br/>
	  <Divider />
		<br/>
		
		
		</>
	:<></>}
	{/* if project is found */}
	{(projectFound) ?
	<>
		<br/>
		<Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" href="/">
          Home
        </Link>
        <Link
          underline="hover"
          color="inherit"
          href="/projects"
        >
          Projects
        </Link>
		<Link
          underline="hover"
          color="inherit"
          href={`/project/${projectData._id}`}
        >
          {projectData.name}
        </Link>
		 <Link
          underline="hover"
          color="inherit"
          href={`/client/view/${clientData._id}`}
        >
          {clientData.code}
        </Link>
		<Link
          underline="hover"
          color="inherit"
          href={`/client/logs/${clientData._id}`}
        >
          Logs
        </Link>
		 <Typography color="text.primary">{clientLogData.name}</Typography>
      </Breadcrumbs>
	  
	  <br/>
	  <Divider />
		
	</>
	:<></>}
	 

	  
	  <table width="100%" style={{cellPadding:'3px'}}>
	  <tbody>
	 
	  <tr>
		<td><h5>Name</h5></td>
		<td>
			 <h3>
	  {nameEditMode ?
	  <>
	  <TextField
          label="Name"
          id="standard-size-small"
          //defaultValue="Small"
          size="small"
		  value={tempLogName}
          variant="standard"
		  onChange={(e) => {
			setTempLogName(e.target.value);
		}}
        />
	  <Button onClick={handNameEditModeOff}  variant="contained" size="small">
          Save
        </Button>
	  </>
	  :
	  <>
	  
	  {clientLogData.name} 
	  <IconButton onClick={handNameEditModeOn} color="primary" aria-label="">
        <EditIcon />
      </IconButton>
	  
	  </>}
	  
	  </h3>
		</td>
		<td><h5>Date</h5></td>
		<td>{formatDate(clientLogData.date)}</td>
		<td><h5>Day Of Week</h5></td>
		<td>{clientLogData.day_of_week}</td>
		<td><h5>Work/School day</h5></td>
		<td>{(clientLogData.work_school_day) ?
	<>Yes</>:<>No</>}</td>
	  </tr>
	  </tbody>
	</table>

	<Button  variant="contained" endIcon={<AddIcon />}  onClick={handleNewMealLogModalOpen}>
			Add new Meal  
	</Button>
	
	{/* add meal dialog*/}
	 <Dialog
        open={openNewMealLogModal}
      >
	  <Box component="form" onSubmit={handleSubmitNewMealLog} noValidate sx={{ mt: 1 }}>
        <DialogTitle>New Meal Log</DialogTitle>
        <DialogContent>
          <DialogContentText>
      
          </DialogContentText>
		  { ShowErrorMessage &&
				<Alert variant="filled" severity="error">{ErrorText}</Alert>
			} 
			{ ShowSuccessMessage &&
				<Alert variant="filled" severity="success">{SuccessText}</Alert>
			}
			<br/>
			
			{/* Meal type and other*/}
				<FormControl sx={{ m: 1, width: '500px'}}>
				  <InputLabel id="demo-select-large-label">Meal Type</InputLabel>
				  <Select
					labelId="demo-select-small-label"
					id="demo-select-small"
					value={mealType}
					label="Day Of Week"
					onChange={(e) => handleMealTypeChange(e)}
				  >
				  {meals.map((meal,index) => (
					  <MenuItem key={meal} value={meal}>{meal}</MenuItem>
				  ))}
				  </Select>
				</FormControl>
				{ showOtherMealType &&
				<TextField
				sx={{ m: 1, width: '500px' }}
				  margin="normal"
				  required
				  fullWidth
				  id="name"
				  label="Other meal type"
				  name="otherMealType"
				
				/>
				
				}

				<br/>
				
				 <LocalizationProvider  dateAdapter={AdapterDayjs}>
				  <DemoContainer sx={{ m: 1, width: '500px'}} components={['TimePicker']}>
					<TimePicker
					  name="mealTime"
					  label="Time"
					  value={mealTime}
					  ampm={false}
					  onChange={(e) => handleMealTimeChange(e)}
					/>
				  </DemoContainer>
				</LocalizationProvider>
				
				
				
				{/* location_prepared */}
				<FormControl sx={{ m: 1, width: '500px'}}>
				  <InputLabel id="demo-select-large-label">Location prepared</InputLabel>
				  <Select
					labelId="demo-select-small-label"
					id="demo-select-small"
					value={locationPrepared}
					label="Location prepared"
					onChange={(e) => handleLocationPreparedChange(e)}
				  >
				  {preparedLocations.map((loc,index) => (
					  <MenuItem key={loc} value={loc}>{loc}</MenuItem>
				  ))}
				  </Select>
				</FormControl>
				{ showOtherLocationPrepared &&
				<TextField
				sx={{ m: 1, width: '500px' }}
				  margin="normal"
				  required
				  fullWidth
				  id="name"
				  label="Other location prepared"
				  name="otherLocationPrepared"
				
				/>
				
				}

				<br/>
				
				{/* location_eaten */}
				<FormControl sx={{ m: 1, width: '500px'}}>
				  <InputLabel id="demo-select-large-label">Location eaten</InputLabel>
				  <Select
					labelId="demo-select-small-label"
					id="demo-select-small"
					value={locationEaten}
					label="Location eaten"
					onChange={(e) => handleLocationEatenChange(e)}
				  >
				  {eatenLocations.map((loc,index) => (
					  <MenuItem key={loc} value={loc}>{loc}</MenuItem>
				  ))}
				  </Select>
				</FormControl>
				{ showOtherLocationEaten &&
				<TextField
				sx={{ m: 1, width: '500px' }}
				  margin="normal"
				  required
				  fullWidth
				  id="name"
				  label="Other location eaten"
				  name="otherLocationEaten"
				
				/>
				
				}
				<br/>
				{/* tv_and_screens_use */}
				 <FormControl sx={{ m: 1, width: '500px' }}>
				    <InputLabel id="demo-simple-select-label">TV/Screen use during meal</InputLabel>
					  <Select
						labelId="demo-simple-select-label"
						id="demo-simple-select"
						value={tvAndScreenUse}
						label="Tv & Screen"
						name="tv_and_screen_use"
						onChange={handleTvAndScreenUseChange}
					  >
						<MenuItem value={true}>Yes</MenuItem>
						<MenuItem value={false}>No</MenuItem>
					  </Select>
				</FormControl>
				{/*
				<h3>Notes</h3>
				<Editor
				editorStyle={{ border: "1px solid grey" ,backgroundColor:"white",minHeight:"200px"}}
				editorState={editorState}
				toolbarClassName="toolbarClassName"
				wrapperClassName="wrapperClassName"
				editorClassName="editorClassName"
				onEditorStateChange={onEditorStateChange}
/>*/}

				<br/><br/>
				
        </DialogContent>
        <DialogActions>
          <Button onClick={handleNewMealLogModalClose}>Cancel</Button>
          <Button type="submit">Save</Button>
        </DialogActions>
		</Box>
      </Dialog>


	<h3>Meals</h3>
	
	{(clientMealLogData.length==0) ?
	<>
		None Found
	</>
	:<>
	
	<div style={{ width: '100%' }}>
  
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row-reverse',
          p: 0,
          m: 0,
       //   bgcolor: 'background.paper',
          borderRadius: 1,
        }}
      >
	<Button onClick={(e) => handleSwitchView(e)} endIcon={<ImportExportIcon/>}  size="small" color="primary">
		Switch View 
	</Button>
	  </Box></div>
	
	</>}
	
	
	{/* switch views */}
	{(tableView)?
	<>
		<div>
	    <TableContainer component={Paper}>
			<Table sx={{ minWidth: 650 }} aria-label="simple table">
				<TableHead>
					<TableRow>
						<TableCell></TableCell>
						{clientMealLogData.map((meal,index) => (
							<TableCell key={index} align="right">{meal.type}</TableCell>
						))}
					</TableRow>
				</TableHead>
				<TableBody>
					{clientFoodLogData.map((food) => (
					<TableRow key={food._id}  sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
					  <TableCell component="th" scope="row">
					  {/*food or recipe */}
						  {/*{food.food_or_recipe=='food'?(
					  <>
						{food.food.name}
					  </>):(<>
						{food.recipe.name}
					</>)}*/}
					  
					  {food.food_or_recipe=='food'?(
						<><FastfoodIcon /> {food.food.name}</>
						): food.food_or_recipe=='recipe'?(
						<><MenuBookIcon /> {food.recipe.name}</>
						):(
							<QuestionMarkIcon />
						)}
					  
					  </TableCell>
					  {clientMealLogData.map((meal,index) => (
					  <TableCell key={index} align="right">
						{(meal.id==food.client_meal_log_id)?<>{food.quantity}g</>:<></>}
					  </TableCell>
					  ))}
					</TableRow>
					))}
					<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
						<TableCell component="th" scope="row">
						</TableCell>
						  {clientMealLogData.map((meal,index) => (
						  <TableCell key={index} align="right">
						  <Button  variant="contained" endIcon={<AddIcon />} onClick={(e) => handleOpenFoodModal(e,meal)} >
								Add Food  
							</Button>
							
						  </TableCell>
						  ))}
					</TableRow>
				</TableBody>
			</Table>
		</TableContainer>
	</div>
	</>
	:<>
	<div>
	{clientMealLogData.map((meal,index) => (
      <Accordion key={meal._id}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          {meal.type} {formatTime(meal.time)}
        </AccordionSummary>
        <AccordionDetails>
          <Button  variant="contained" endIcon={<AddIcon />} onClick={(e) => handleOpenFoodModal(e,meal)} >
					Add Food  
			</Button>
			<br/>
			
	<List sx={{ width: '100%', maxWidth: 500, bgcolor: 'background.paper' }}>
	
	{clientMealFoodLogData[meal._id].map((food) => (
	<>
      <ListItem key={food._id} alignItems="flex-start"
		  secondaryAction={
				<IconButton onClick={(e) => handleDeleteFood(e,food)} edge="end" aria-label="delete">
					<DeleteIcon />
				</IconButton>
		  } >
        <ListItemAvatar>
		
		{food.food_or_recipe=='food'?(
		<Avatar>
            <FastfoodIcon />
          </Avatar>
			): food.food_or_recipe=='recipe'?(
			<Avatar>
            <MenuBookIcon />
          </Avatar>
		):(
		<Avatar>
            <QuestionMarkIcon />
          </Avatar>
		)}
          
        </ListItemAvatar>
        <ListItemText
         primary={food.food_or_recipe=='food'?(
					<>{food.food.name}</>
					): food.food_or_recipe=='recipe'?(
					<>{food.recipe.name}</>
					):(
						<></>
					)}
          secondary={
            <Fragment>
              <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="body2"
                color="text.primary"
              >
			  {food.quantity}g
              </Typography>
            </Fragment>
          }
        />
      </ListItem>
      <Divider variant="inset" component="li" /></>
		))}
    </List>
        </AccordionDetails>
      </Accordion>
		))}
    
</div>
	
	
	
	</>}
	
	
	

{/*test new modal*/}

<div>
{/*<Button onClick={handleOpen}>Open modal</Button>*/}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style_parent, width: 800 }}>
          <h2 id="parent-modal-title">Add food/recipe to {tempMeal.type}</h2>
		
		 
		  
          {/*<p id="parent-modal-description">
            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
</p>*/}
			 <Box sx={{ width: '100%' }}>
				  <Stepper activeStep={activeStep}>
					{steps.map((label, index) => {
					  const stepProps = {};
					  const labelProps = {};
					  if (isStepOptional(index)) {
						labelProps.optional = (
						  <Typography variant="caption">Optional</Typography>
						);
					  }
					  if (isStepSkipped(index)) {
						stepProps.completed = false;
					  }
					  return (
						<Step key={label} {...stepProps}>
						  <StepLabel {...labelProps}>{label}</StepLabel>
						</Step>
					  );
					})}
				  </Stepper>
				  {activeStep === steps.length ? (
					<Fragment>
					  <Typography sx={{ mt: 2, mb: 1 }}>
						All steps completed - you&apos;re finished
					  </Typography>
					  <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
						<Box sx={{ flex: '1 1 auto' }} />
						<Button onClick={handleReset}>Reset</Button>
					  </Box>
					</Fragment>
				  ) : (
					<Fragment>
					  <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography>
					   {(activeStep==0) ?
						<>
						
							{/*food search bar */}
					
					<TextField fullWidth onChange={(e) => {handleFoodSearchChange(e)}} id="outlined-basic" label="food/recipe search" variant="outlined" />
						<br/><br/>{FilteredFoodData.length} found.
						
						
						<br/>
						
						<List sx={{ width: '100%', bgcolor: 'background.paper' }}>
						 {selectPage(FilteredFoodData).map((food) => (
						 <>
						 <ListItem alignItems="flex-start" key={food._id}>
							<ListItemAvatar style={{paddingRight:'15px'}}>
								{food.type=='food'?(
									<FastfoodIcon sx={{ fontSize: 50 }} />
								):(
									<MenuBookIcon sx={{ fontSize: 50 }} />
								)}
							  
							  
							</ListItemAvatar>
							<IconButton onClick={(e) => {handleFoodInfoOpen(e,food)}} aria-label="delete">
								<InfoOutlinedIcon />
							  </IconButton>
							
							<ListItemText
							  primary={food.code} 
								secondary={food.name}
							/> 
							<Button onClick={(e) => {handleAddToLog(e,food)}}>Add {food.type} to log</Button>
						  </ListItem>
						  
						  <Divider variant="" component="li" />
						  <br/>
						 <Divider variant="" component="li" />
						 </>
							))}
						 
						</List>
						<br/>
						</>
						:<></>}
						
						{(activeStep==1) ?
						<>
							<br/><br/>
							<TextField
								 type="number" 
								  label="Quantity(g)"
								  id="outlined-end-adornment"
								  sx={{  width: '500px' }}
								  value={quantity}
								  onChange={handleQuantityChange}
								  InputProps={{
									endAdornment: <InputAdornment position="end">grams</InputAdornment>,
								  }}
								/>
							<br/><br/>
							<SmartPortionComponent smartPortionLoaded={smartPortionLoaded} smartPortion={smartPortion} onClick={handleClick}  />
						</>
						:<></>}
						
						{(activeStep==2) ?
						<>
							

	{/*//quantification method
	const [quantificationMethod, setQuantificationMethod] = useState('');
	const handleQuantificationMethodChange = (event) => {
		setQuantificationMethod(event.target.value);
							};*/}
		<FormControl  sx={{ m: 1, width: 500 }}>
			<InputLabel id="demo-select-large-label">Quantification Method</InputLabel>
			<Select
				labelId="demo-select-small-label"
				id="demo-select-small"
				value={quantificationMethod}
				label="Quantification Method"
				onChange={(e) => handleQuantificationMethodChange(e)}
				>
				{quantification_methods.map((opt,index) => (
					<MenuItem key={opt} value={opt}>{opt}</MenuItem>
				))}
			</Select>
		</FormControl>
		  
		  <br/>
		  
		  						{/*}
	//cooking_methods
	const [cookingMethod, setCookingMethod] = useState('');
	const handleCookingMethodChange = (event) => {
		setCookingMethod(event.target.value);
						};*/}
		  
		 <FormControl  sx={{ m: 1, width: 500 }}>
			<InputLabel id="demo-select-large-label">Cooking Method</InputLabel>
			<Select
				labelId="demo-select-small-label"
				id="demo-select-small"
				value={cookingMethod}
				label="Cooking Method"
				onChange={(e) => handleCookingMethodChange(e)}
				>
				{cooking_methods.map((opt,index) => (
					<MenuItem key={opt} value={opt}>{opt}</MenuItem>
				))}
			</Select>
		</FormControl>
		  
		  <br/>
		  
		  
	{/*//beverage_codes
	const [beverageCode, setBeverageCode] = useState('');
	const handleBeverageCodeChange = (event) => {
		setBeverageCode(event.target.value);
	};
	*/}
			<FormControl  sx={{ m: 1, width: 500 }}>
			<InputLabel id="demo-select-large-label">Beverage Code</InputLabel>
			<Select
				labelId="demo-select-small-label"
				id="demo-select-small"
				value={beverageCode}
				label="Beverage Code"
				onChange={(e) => handleBeverageCodeChange(e)}
				>
				{beverage_codes.map((opt,index) => (
					<MenuItem key={opt} value={opt}>{opt}</MenuItem>
				))}
			</Select>
		</FormControl>
		  
		  <br/>
		  
		  {/*
	//packaging_types
	const [packagingType, setPackagingType] = useState('');
	const handlePackagingTypeChange = (event) => {
		setPackagingType(event.target.value);
	};*/}

		<FormControl  sx={{ m: 1, width: 500 }}>
			<InputLabel id="demo-select-large-label">Packaging Type</InputLabel>
			<Select
				labelId="demo-select-small-label"
				id="demo-select-small"
				value={packagingType}
				label="Packaging Type"
				onChange={(e) => handlePackagingTypeChange(e)}
				>
				{packaging_types.map((opt,index) => (
					<MenuItem key={opt} value={opt}>{opt}</MenuItem>
				))}
			</Select>
		</FormControl>
		  
		  <br/>  <br/>
							<TextField
								 type="number" 
								  label="Packaging Size(g)"
								  id="outlined-end-adornment"
								  sx={{  width: '500px' }}
								  value={packagingSize}
								  onChange={(e) => handlePackagingSizeChange(e)}
								  InputProps={{
									endAdornment: <InputAdornment position="end">grams</InputAdornment>,
								  }}
								/>
							<br/><br/>


		  
							
							
							
							
						</>
						:<></>}
					 
					  
					  
					  <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
			
						<Button
						  color="inherit"
						  disabled={activeStep === 0}
						  onClick={handleBack}
						  sx={{ mr: 1 }}
						>
						  Back
						</Button>
						<Box sx={{ flex: '1 1 auto' }} />
						{isStepOptional(activeStep) && (
						  <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
							Skip
						  </Button>
						)}
						{activeStep!==0?
						<>
						
							
								{quantity>0?
								<>
								
									
								<Button onClick={handleNext}>
								  {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
								</Button>
								</>:
								<>
						
						
								</>}
						</>:
						<>
						
						
						</>
						}
						
						
					  </Box>
					</Fragment>
				  )}
				</Box>



				{/*<ChildModal />*/}
				
				<Fragment>
				{/*<Button onClick={handleFoodInfoOpen}>Open Child Modal</Button>*/}
      <Modal
        open={openFoodInfo}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style, width: 500 ,height:500}}>
          <h2 id="child-modal-title">{tempFood.code} - {tempFood.name}</h2>
		  <Button onClick={handleFoodInfoClose}>Close</Button>
          <p id="child-modal-description">

		  {/*
			<ul>{Array.from(Array(10), (e, i) => {
    return <li key={i}>{i}</li>
  })}</ul>*/}
			
			{/*}
			<table width="100%">
			  <tr>
				<td style={{verticalAlign: 'top'}} width="30%">
				
				 <TableContainer component={Paper}>
			  <Table sx={{ Width: '100%' }} aria-label="simple table">
				<TableBody>
				  {returnPages(removeKeys(foodTranslations,['code','name']),5,1).map((key) => (
					<TableRow key={key}>
					  <TableCell component="th" scope="row">{foodTranslations[key]}</TableCell>
					<TableCell component="td" scope="row">{tempFood[key]}</TableCell>
					</TableRow>
				  ))}
				</TableBody>
			  </Table>
			</TableContainer>
				
				
				</td>
				<td style={{verticalAlign: 'top'}}  width="30%">
				
				<TableContainer component={Paper}>
			  <Table sx={{ Width: '50%' }} aria-label="simple table">
				<TableBody>
				  {returnPages(removeKeys(foodTranslations,['code','name']),5,2).map((key) => (
					<TableRow key={key}>
					  <TableCell component="th" scope="row">{foodTranslations[key]}</TableCell>
					<TableCell component="td" scope="row">{tempFood[key]}</TableCell>
					</TableRow>
				  ))}
				</TableBody>
			  </Table>
			</TableContainer>
				
				
				</td>
				<td style={{verticalAlign: 'top'}}  width="30%">
				
				<TableContainer component={Paper}>
			  <Table sx={{ Width: '50%' }} aria-label="simple table">
				<TableBody>
				  {returnPages(removeKeys(foodTranslations,['code','name']),5,3).map((key) => (
					<TableRow key={key}>
					  <TableCell component="th" scope="row">{foodTranslations[key]}</TableCell>
					<TableCell component="td" scope="row">{tempFood[key]}</TableCell>
					</TableRow>
				  ))}
				</TableBody>
			  </Table>
			</TableContainer>
				
				
				</td>
				<td style={{verticalAlign: 'top'}}  width="30%">
				
				<TableContainer component={Paper}>
			  <Table sx={{ Width: '50%' }} aria-label="simple table">
				<TableBody>
				  {returnPages(removeKeys(foodTranslations,['code','name']),5,4).map((key) => (
					<TableRow key={key}>
					  <TableCell component="th" scope="row">{foodTranslations[key]}</TableCell>
					<TableCell component="td" scope="row">{tempFood[key]}</TableCell>
					</TableRow>
				  ))}
				</TableBody>
			  </Table>
			</TableContainer>
				
				
				</td>
				<td style={{verticalAlign: 'top'}}  width="30%">
				
				<TableContainer component={Paper}>
			  <Table sx={{ Width: '50%' }} aria-label="simple table">
				<TableBody>
				  {returnPages(removeKeys(foodTranslations,['code','name']),5,5).map((key) => (
					<TableRow key={key}>
					  <TableCell component="th" scope="row">{foodTranslations[key]}</TableCell>
					<TableCell component="td" scope="row">{tempFood[key]}</TableCell>
					</TableRow>
				  ))}
				</TableBody>
			  </Table>
			</TableContainer>
				
				
				</td>
			  </tr>
			</table>
			
			
			*/}
           
		   {/*{formatJson(tempFood)}*/}
          </p>
          
        </Box>
      </Modal>
    </Fragment>
	
        </Box>
      </Modal>
    </div>
{/*end test new modal*/}


	
	  
	
		<h3>Notes <Chip label="Autosave" size="small" /></h3>
		<Editor
				editorStyle={{ border: "1px solid grey" ,backgroundColor:"white",minHeight:"200px"}}
				editorState={logNotesEditorState}
				toolbarClassName="toolbarClassName"
				wrapperClassName="wrapperClassName"
				editorClassName="editorClassName"
				onEditorStateChange={onLogNotesEditorStateChange}
				/>

				<br/><br/>
	<br/>
	
	
	<div style={{ width: '100%' }}>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row-reverse',
          p: 1,
          m: 1,
       //   bgcolor: 'background.paper',
          borderRadius: 1,
        }}
      >
<Button onClick={(e) => handleCompleteClientLogModal(e)} variant="contained" color="primary">
		Complete 
	</Button>
	
	<Dialog
        fullWidth={true}
        maxWidth={'md'}
        open={completeLogModalStatus}
        onClose={handleCompleteLogModalClose}
      >
        <DialogTitle>Complete 24hr recall</DialogTitle>
        <DialogContent>
		  <h3>Did you forget to add some Food? If so go back and add them</h3>
		     <ul>
			{['Coffee, tea, soft drinks, fizzy drinks, milk, juice or water','Sugar, sweetener (added to beverages, cereal, etc...)','Beer, wine, cocktails, spirits or other drinks','Biscuits, chocolate, ice cream, crisps or other sweets','Butter, margarine, mayonnaise or other spread','Fruits, vegetables or cheese','Bread, rolls, other bread or crackers'].map((food,index) => (
				<li key={food} >{food}</li>
			))}
			</ul>
			<Button onClick={handleCompleteLogModalCloseAndOpenFood}>Opps! yes I did</Button>
		    <h3>End of recall questions</h3>
			
			<h4>Question 1</h4>
			<h5>{completeLogQuestions[0]}</h5>
			
			<FormControl>
			  <RadioGroup
				aria-labelledby="demo-radio-buttons-group-label"
				//defaultValue="female"
				name="radio-buttons-group"
				value={completeLogAnswers[0]}
				onChange={(e) => {
							console.log(e.target.value);
							let newArr = [...completeLogAnswers];
							newArr[0]=e.target.value;
							setCompleteLogAnswers(newArr);
					  }}
			  >
				<FormControlLabel value="Less than usual" control={<Radio />} label="Less than usual" />
				<FormControlLabel value="About the same as usual" control={<Radio />} label="About the same as usual" />
				<FormControlLabel value="More than usual" control={<Radio />} label="More than usual" />
			  </RadioGroup>
			</FormControl>
			
			<h4>Question 2</h4>
			<h5>{completeLogQuestions[1]}</h5>
			<FormControl>
			  <RadioGroup
				aria-labelledby="demo-radio-buttons-group-label"
				//defaultValue="female"
				name="radio-buttons-group"
				value={completeLogAnswers[1]}
				onChange={(e) => {
							console.log(e.target.value);
							let newArr = [...completeLogAnswers];
							newArr[1]=e.target.value;
							setCompleteLogAnswers(newArr);
					  }}
			  >
				<FormControlLabel value="They were feeling unwell" control={<Radio />} label="They were feeling unwell" />
				<FormControlLabel value="It was not a typical week" control={<Radio />} label="It was not a typical week" />
				<FormControlLabel value="Other" control={<Radio />} label="Other" />
			  </RadioGroup>
			</FormControl>
			
        </DialogContent>
        <DialogActions>
		<Button onClick={handleCompleteLogModalClose}>Cancel</Button>
          <Button onClick={handleCompleteClientLog}>Complete</Button>
        </DialogActions>
      </Dialog>
	  </Box></div>
	
	{/* Admin */}
	{(currentUser.__t=='Admin') ?
	<>
	
	</>
	:<></>}
	
	{/* Master */}
	{(currentUser.__t=='Master') ?
	<>
	
	</>
	:<></>}
	
	{/* Researcher */}
	{(currentUser.__t=='Researcher') ?
	<>
	
	</>
	:<></>}

	</AppLogout>
	</>
  ) 
  ;
};

export default ClientLog;