import {useEffect,useState} from 'react';
import { useNavigate } from "react-router-dom";

import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Divider from '@mui/material/Divider';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import VisibilityIcon from '@mui/icons-material/Visibility';

import Utils from '../components/Utils.jsx'

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';

import TextField from '@mui/material/TextField';

import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';


import TopPrivateNavigation from '../components/TopPrivateNavigation.jsx'
import AppLogout from '../components/AppLogout.jsx'

//services
import AuthService from '../service/auth'
import AccountProjectService from '../service/AccountProject'
import ProjectService from '../service/Project'

import AdminAccountService from '../service/AdminAccount'
import MasterAccountService from '../service/MasterAccount'
import ResearcherAccountService from '../service/ResearcherAccount'

//account requests
import AccountRequestService from '../service/AccountRequest';
import AccountInviteService from '../service/AccountInvite';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const formatDate=function(d){
	var d = new Date(d);
	Number.prototype.padLeft = function(base,chr){
    var  len = (String(base || 10).length - String(this).length)+1;
    return len > 0? new Array(len).join(chr || '0')+this : this;
}
    var dformat = [
               d.getDate().padLeft(),
			   (d.getMonth()+1).padLeft(),
               d.getFullYear()].join('/') +' ' +
              [d.getHours().padLeft(),
               d.getMinutes().padLeft()].join(':');
	return dformat;
}

const Home = () => {
	
	const navigate = useNavigate();
	//protected page check if logged in
	const [currentUser,setCurrentUser]=useState({});
	
	const [accountProjectData,setAccountProjectData]=useState([]);
	function loadProjectsByAccount(){//by account
		AccountProjectService.getByFilter({'account':AuthService.getCurrentUser()._id}).then(function(res){
			console.log(res.data);
			setAccountProjectData(res.data);

		}).catch(function(error){
				console.log(error);
		});
	};
	
	//master/researcher cards 
	const [dashboardCards,setDashboardCards]=useState({
		"Master":[
			{"cards":[	
				{"name":"Projects","link":"/projects","active":true},
				{"name":"Clients","link":"/clients","active":true},
				{"name":"Researchers","link":"/researchers","active":true},

			]},
			{"cards":[
				{"name":"Foods","link":"/foods","active":true},
				{"name":"Recipes","link":"/recipes","active":false}
			]},
			{"cards":[
				{"name":"Exports","link":"/exports","active":true},
			]}
		],
		"Researcher":[
			{"cards":[	
				{"name":"Projects","link":"/projects","active":true},
				{"name":"Clients","link":"/clients","active":true},
				{"name":"Researchers","link":"/researchers","active":true},

			]},
			{"cards":[
				{"name":"Foods","link":"/foods","active":true},
				{"name":"Recipes","link":"/recipes","active":false}
			]},
			{"cards":[
				{"name":"Exports","link":"/exports","active":true},
			]}
		]
	});
	
	
	//admin tabs
	const [value, setValue] = useState(0);

	  const handleChange = (event, newValue) => {
		setValue(newValue);
	  };
		
	useEffect(() => {
		if(!AuthService.isUserLoggedIn()){
			// not logged in navigate back to login
			navigate("/login");
		}else{
				setCurrentUser(AuthService.getCurrentUser());
				var account_type=AuthService.getCurrentUser().__t;
				console.log('account_type',account_type);
				
				console.log(AuthService.getCurrentUser());
				var currentUser=AuthService.getCurrentUser();
				//expired Account
				if(currentUser.expired){
					var string = "account_type="+AuthService.getCurrentUser().__t+"&type=expired";
					console.log('string',string);
					var encodedString = btoa(string); // Base64 encode the String
					console.log('encodedString',encodedString);
					navigate("/restricted/"+encodedString);
					
				}else{//unverified account
					if(!currentUser.email_verified){
						var string = "account_type="+AuthService.getCurrentUser().__t+"&type=emaiverification";
						console.log('string',string);
						var encodedString = btoa(string); // Base64 encode the String
						console.log('encodedString',encodedString);
						navigate("/restricted/"+encodedString);
						
					}else{////force change Password
						if(currentUser.force_password_change){
								
						
						}else{
							ProjectService.clearCurrentProject();
							//load
							if(account_type=='Admin'){//Admin
								loadAdminAccounts();
								loadMasterAccounts();
								loadResearcherAccounts();
								loadMasterAdminAccountRequests();
							}else if(account_type=='Master'){//Master
								//loadProjectsByAccount();
								
							}else if(account_type=='Researcher'){//Researcher
								//loadProjectsByAccount();
							}
						}
					}
				}	
			}
	}, []);
	
	//handleChangeProject
	const handleChangeProject = (e,project) => {
			console.log(project);
			ProjectService.setCurrentProject(project._id);
			navigate("/project/"+project._id);
	};
	
	const [adminAccountData,setAdminAccountData]=useState([]);
	const [masterAccountData,setMasterAccountData]=useState([]);
	const [researcherAccountData,setResearcherAccountData]=useState([]);
	 function loadAdminAccounts(){
		  AdminAccountService.getAll().then(function(res){
				setAdminAccountData(res.data);
				console.log(res.data);
		});
	  }
	  
	  function loadMasterAccounts(){
		  MasterAccountService.getAll().then(function(res){
				setMasterAccountData(res.data);
				console.log(res.data);
		});
	  }
	  
	   function loadResearcherAccounts(){
		  ResearcherAccountService.getAll().then(function(res){
				setResearcherAccountData(res.data);
				console.log(res.data);
		});
	  }
	  
	  
	const [accountRequestsData,setAccountRequestsData]=useState([]);
	const [accountProcessedRequestsData,setAccountProcessedRequestsData]=useState([]);
	const [accountUnProcessedRequestsData,setAccountUnProcessedRequestsData]=useState([]);
	  function loadMasterAdminAccountRequests(){
		  //processed
		  AccountRequestService.getByFilter({'account_type':'Master','processed':true}).then(function(res){
			setAccountProcessedRequestsData(res.data);
			console.log(res.data);
		});
		//not processed yet
		AccountRequestService.getByFilter({'account_type':'Master','processed':false}).then(function(res){
			setAccountUnProcessedRequestsData(res.data);
			console.log(res.data);
		});
	  }
	  
	const updateMasterAccountRequest=(e,request,updateObject) => {
		let updatedRequest = {
			...request,
			...updateObject
		};
		updatedRequest.processed_by =currentUser._id;
		//request[key]=value;
		AccountRequestService.update(updatedRequest).then(function(res){
			console.log(res.data);
			loadMasterAdminAccountRequests();
		}).catch(function(error){
				console.log(error);
		});
	};
	
	const updateMasterAccountRequestAndSendResponse=(e,request,updateObject) => {
		let updatedRequest = {
			...request,
			...updateObject
		};
		updatedRequest.processed_by =currentUser._id;
		//request[key]=value;
		AccountRequestService.updateRequest(updatedRequest).then(function(res){
			console.log(res.data);
			loadMasterAdminAccountRequests();
		}).catch(function(error){
				console.log(error);
		});
	};
	
	//master Invites handleSendMasterInvite
	const [firstname,setFirstname]=useState("");
	const [email,setEmail]=useState("");
	const handleSendMasterInvite=(e) => {
		var invite ={
			'firstname':firstname,
			'email':email
		}
		AccountInviteService.sendMasterInvite(invite).then(function(res){
			console.log(res.data);
			setFirstname("");
			setEmail("");
			//loadMasterAdminAccountRequests();
		}).catch(function(error){
				console.log(error);
		});
	};
	
	const handleOnChangeValue = (e,key) => {
		console.log(e.target.value,key);
		if(key=='firstname'){
			setFirstname(e.target.value);
		}
		if(key=='email'){
			setEmail(e.target.value);
		}
	};
	
	
  return(
  <>
	<TopPrivateNavigation/>
	<AppLogout>

		<br/>
	<Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" href="/">
          Home
        </Link>
      </Breadcrumbs>
	<br/>
	  <Divider />
	<br/>
	
	{/* Admin */}
	{(currentUser.__t=='Admin') ?
	<>
		   <Box sx={{ width: '100%' }}>
			  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
				<Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
				  <Tab label="Account Requests" {...a11yProps(0)} />
				  <Tab label="Master Invites" {...a11yProps(1)} />
				  <Tab label="Admin Accounts" {...a11yProps(2)} />
				  <Tab label="Master Accounts" {...a11yProps(3)} />
				  <Tab label="Researcher Accounts" {...a11yProps(4)} />
				</Tabs>
			  </Box>
			  
			  {/* Account Requests */}
			  <CustomTabPanel value={value} index={0}>
			  <Typography variant="h5" component="h5">
				  Awaiting approval
				</Typography><br/>
				{(accountUnProcessedRequestsData.length==0) ?
					<>None found!
					</>
				:
				<>
				<TableContainer component={Paper}>
				  <Table sx={{ minWidth: 650,width:'100%'}} aria-label="simple table">
					<TableHead>
					<TableRow>
						<TableCell>First Name</TableCell>
						<TableCell>Last Name</TableCell>
						<TableCell>Email</TableCell>
						<TableCell>Organisation</TableCell>
						<TableCell>Date Requested</TableCell>
						<TableCell>Message</TableCell>
						<TableCell></TableCell>
					  </TableRow>
					</TableHead>
					   <TableBody>
						{accountUnProcessedRequestsData.map((row,index) => (
						<TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
						  <TableCell align="left">{row.first_name}</TableCell>
						  <TableCell align="left">{row.last_name}</TableCell>
						  <TableCell align="left">{row.email}</TableCell>
						  <TableCell align="left">{row.organisation}</TableCell>
						  <TableCell align="left">{formatDate(row.date_requested)}</TableCell>
						  <TableCell align="left">{row.request_text}</TableCell>
						  <TableCell align="left">
							<Stack direction="row" spacing={2}>
								{(row.processed) ?//processed 
								<span>
									{(row.approved) ?//processed 
									<span>
										Approved by {row.processed_by.first_name} {row.processed_by.last_name}
									</span>
									:<>
									<span>
										Rejected by {row.processed_by.first_name} {row.processed_by.last_name}
									</span>
									</>}
								</span>
								:<>
									<Button  onClick={(e) => updateMasterAccountRequestAndSendResponse(e,row,{'processed':true,'approved':true})} variant="contained" color="success">
										Accept
									</Button>
									<Button  onClick={(e) => updateMasterAccountRequestAndSendResponse(e,row,{'processed':true,'approved':false})} variant="contained" color="error">
										Decline
									</Button>
								</>}
							</Stack>
						  
						  
						  </TableCell>
						</TableRow>
						))}
				  
					</TableBody>
					</Table>
				</TableContainer>
				</>
				}
				<br/><br/>
				<Typography variant="h5" component="h5">
				  Approved/Rejected
				</Typography><br/>
				{(accountProcessedRequestsData.length==0) ?
					<>
						None found!
					</>:<>
					
				<TableContainer component={Paper}>
				  <Table sx={{ minWidth: 650,width:'100%'}} aria-label="simple table">
					<TableHead>
					<TableRow>
						<TableCell>First Name</TableCell>
						<TableCell>Last Name</TableCell>
						<TableCell>Email</TableCell>
						<TableCell>Organisation</TableCell>
						<TableCell>Date Requested</TableCell>
						<TableCell>Message</TableCell>
						<TableCell></TableCell>
						<TableCell></TableCell>
					  </TableRow>
					</TableHead>
					   <TableBody>
						{accountProcessedRequestsData.map((row,index) => (
						<TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
						  <TableCell align="left">{row.first_name}</TableCell>
						  <TableCell align="left">{row.last_name}</TableCell>
						  <TableCell align="left">{row.email}</TableCell>
						  <TableCell align="left">{row.organisation}</TableCell>
						  <TableCell align="left">{formatDate(row.date_requested)}</TableCell>
						  <TableCell align="left">{row.request_text}</TableCell>
						  <TableCell align="left">
							<Stack direction="row" spacing={2}>
								{(row.processed) ?//processed 
								<span>
									{(row.approved) ?//processed 
									<span>
										Approved by <br/>{row.processed_by.first_name} {row.processed_by.last_name}
									</span>
									:<>
									<span>
										Rejected by <br/>{row.processed_by.first_name} {row.processed_by.last_name}
									</span>
									
									</>}
									<br/>
									
									<Link style={{ cursor: 'pointer' }} onClick={(e) => updateMasterAccountRequest(e,row,{'processed':false,'approved':false})} >change mind??</Link>
								
								</span>
								:<>
									<Button  onClick={(e) => updateMasterAccountRequestAndSendResponse(e,row,{'processed':true,'approved':true})} variant="contained" color="success">
										Accept
									</Button>
									<Button  onClick={(e) => updateMasterAccountRequestAndSendResponse(e,row,{'processed':true,'approved':false})} variant="contained" color="error">
										Decline
									</Button>
								</>}
							</Stack>
						  
						  </TableCell>
						</TableRow>
						))}
				  
					</TableBody>
					</Table>
				</TableContainer>
				</>
				}
				
			  </CustomTabPanel>
			  {/*Master invites*/}
			  <CustomTabPanel value={value} index={1}>
			   <TextField onChange={(e) => {handleOnChangeValue(e,'firstname')}} value={firstname} id="standard-basic" label="Firstname" variant="standard" />
			   <br/>
				<TextField onChange={(e) => {handleOnChangeValue(e,'email')}} value={email} id="standard-basic" label="Email" variant="standard" />
			   <br/><br/>
			  <Button  onClick={(e) => handleSendMasterInvite(e)} variant="contained" color="primary">
										Send Invite
				</Button>
			   </CustomTabPanel>

			  {/* Admin accounts */}
			  <CustomTabPanel value={value} index={2}>
				{(adminAccountData.length==0) ?
					<>None found!
					</>
				:
				<>
				<TableContainer component={Paper}>
				  <Table sx={{ minWidth: 650,width:'100%'}} aria-label="simple table">
					<TableHead>
					<TableRow>
						<TableCell>First Name</TableCell>
						<TableCell>Last Name</TableCell>
						<TableCell>Email</TableCell>
					  </TableRow>
					</TableHead>
					   <TableBody>
						{adminAccountData.map((row,index) => (
						<TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
						  <TableCell align="left">{row.first_name}</TableCell>
						  <TableCell align="left">{row.last_name}</TableCell>
						  <TableCell align="left">{row.email}</TableCell>
						</TableRow>
						))}
				  
					</TableBody>
					</Table>
				</TableContainer>
				</>
				}
				
			  </CustomTabPanel>
			  <CustomTabPanel value={value} index={3}>
				{(masterAccountData.length==0) ?
					<>None found!
					</>
				:
				<>
				<TableContainer component={Paper}>
				  <Table sx={{ minWidth: 650,width:'100%'}} aria-label="simple table">
					<TableHead>
					<TableRow>
						<TableCell>First Name</TableCell>
						<TableCell>Last Name</TableCell>
						<TableCell>Email</TableCell>
					  </TableRow>
					</TableHead>
					   <TableBody>
						{masterAccountData.map((row,index) => (
						<TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
						  <TableCell align="left">{row.first_name}</TableCell>
						  <TableCell align="left">{row.last_name}</TableCell>
						  <TableCell align="left">{row.email}</TableCell>
						</TableRow>
						))}
				  
					</TableBody>
					</Table>
				</TableContainer>
				</>
				}
				
			  </CustomTabPanel>
			  <CustomTabPanel value={value} index={4}>
				{(researcherAccountData.length==0) ?
					<>None found!
					</>
				:
				<>
				<TableContainer component={Paper}>
				  <Table sx={{ minWidth: 650,width:'100%'}} aria-label="simple table">
					<TableHead>
					<TableRow>
						<TableCell>First Name</TableCell>
						<TableCell>Last Name</TableCell>
						<TableCell>Email</TableCell>
					  </TableRow>
					</TableHead>
					   <TableBody>
						{researcherAccountData.map((row,index) => (
						<TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
						  <TableCell align="left">{row.first_name}</TableCell>
						  <TableCell align="left">{row.last_name}</TableCell>
						  <TableCell align="left">{row.email}</TableCell>
						</TableRow>
						))}
				  
					</TableBody>
					</Table>
				</TableContainer>
				</>
				}
				
			  </CustomTabPanel>
			</Box>
	</>
	:<></>}
	
	{/* Master */}
	{(currentUser.__t=='Master') ?
	<>
	
	  <br/> <br/>
	  
	{dashboardCards[currentUser.__t].map((row,index) => (
	<div key={index}>

	<Grid  container spacing={3}>
	{row.cards.map((card,i) => (
		   <Grid key={card.name} item item xs={12} sm={6} md={4}>
			    <Card  variant="outlined" sx={{ minWidth: 275 }}>
				  <CardContent style={{ textAlign: 'center' }} >
					<Typography variant="h5" component="div">
					{card.name}
					</Typography>
					<Typography sx={{ mb: 1.5 }} color="text.secondary">
					  
					</Typography>
				  </CardContent>
				  <CardActions style={{justifyContent:'center'}}>
					<Button size="small" href={`${card.link}`} >View all</Button>
				  </CardActions>
			</Card>
		</Grid>
		))}
	  </Grid>
	<br/>
	{/*
	<Grid container spacing={2}>
  <Grid item xs={6} md={8}>
    1
  </Grid>
  <Grid item xs={6} md={4}>
   2
  </Grid>
  <Grid item xs={6} md={4}>
   3
  </Grid>
  <Grid item xs={6} md={8}>
   4
  </Grid>
	</Grid>*/}
	
	  <Divider variant="middle" />
	<br/>
	</div>
	))}
	  
	  {/*
	  <Grid container spacing={3}>
	  {dashboardCards[currentUser.__t].cards.map((row,index) => (
		   <Grid key={row.name} item item xs={12} sm={6} md={4}>
			    <Card  variant="outlined" sx={{ minWidth: 275 }}>
				  <CardContent style={{ textAlign: 'center' }} >
					<Typography variant="h5" component="div">
					{row.name}
					</Typography>
					<Typography sx={{ mb: 1.5 }} color="text.secondary">
					  
					</Typography>
				  </CardContent>
				  <CardActions style={{justifyContent:'center'}}>
					<Button size="small" href={`${row.link}`} >View all</Button>
				  </CardActions>
			</Card>
		</Grid>
	  
	  ))}
	  </Grid>*/}
	
	</>
	:<></>}
	
	{/* Researcher */}
	{(currentUser.__t=='Researcher') ?
	<>
	
	   <br/> <br/>
	  
	{dashboardCards[currentUser.__t].map((row,index) => (
	<>

	<Grid key={index} container spacing={3}>
	{row.cards.map((card,i) => (
		   <Grid key={card.name} item item xs={12} sm={6} md={4}>
			    <Card  variant="outlined" sx={{ minWidth: 275 }}>
				  <CardContent style={{ textAlign: 'center' }} >
					<Typography variant="h5" component="div">
					{card.name}
					</Typography>
					<Typography sx={{ mb: 1.5 }} color="text.secondary">
					  
					</Typography>
				  </CardContent>
				  <CardActions style={{justifyContent:'center'}}>
					<Button size="small" href={`${card.link}`} >View all</Button>
				  </CardActions>
			</Card>
		</Grid>
		))}
	  </Grid>
	<br/>
	  <Divider variant="middle"  />
	<br/>
	</>
	))}
	
	  {/*<Grid container spacing={3}>
	  {dashboardCards[currentUser.__t].cards.map((row,index) => (
		   <Grid key={row.name} item item xs={12} sm={6} md={4}>
			    <Card  variant="outlined" sx={{ minWidth: 275 }}>
				  <CardContent style={{ textAlign: 'center' }} >
					<Typography variant="h5" component="div">
					{row.name}
					</Typography>
					<Typography sx={{ mb: 1.5 }} color="text.secondary">
					  
					</Typography>
				  </CardContent>
				  <CardActions style={{justifyContent:'center'}}>
					<Button size="small" href={`${row.link}`} >View all</Button>
				  </CardActions>
			</Card>
		</Grid>
	  
	  ))}
	</Grid>*/}
	
	</>
	:<></>}
	
	</AppLogout>
	</>
  ) 
  ;
};

export default Home;