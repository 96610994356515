import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { styled } from '@mui/material/styles';
//import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import SmartPortionService from '../service/SmartPortion'

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#fff',
  width:'120px',
  minHeight:'300px',
  margin:'5px',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: (theme.vars ?? theme).palette.text.secondary,
  ...theme.applyStyles('dark', {
    backgroundColor: '#1A2027',
  }),
}));


const SmallItem = styled(Paper)(({ theme }) => ({
  backgroundColor: '#fff',
  width:'120px',
  minHeight:'150px',
  margin:'5px',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: (theme.vars ?? theme).palette.text.secondary,
  ...theme.applyStyles('dark', {
    backgroundColor: '#1A2027',
  }),
}));

function formatJson(json){
	return JSON.stringify(json);
}

function formatFractions(volume,fraction){
	if(fraction=="1"){
		return volume;
	}else{
		let s =fraction.split('/')
		return (volume/s[1])*s[0];
	}
}

function getImageUrl(id){
		return SmartPortionService.getImageUrl(id);
	}


export default function SmartPortion({smartPortionLoaded,smartPortion,onClick}) {
	
	const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
   return (
            <div>
			
			{smartPortionLoaded &&
			<>
				<Box sx={{ width: '100%' }}>
					<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
					<Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
					  <Tab label="HHM" {...a11yProps(0)} />
					  <Tab label="Images" {...a11yProps(1)} />
					  <Tab label="Standard Portion" {...a11yProps(2)} />
					</Tabs>
					</Box>
					
					<CustomTabPanel value={value} index={0}>
						{smartPortion.sp.hhm_exists &&
						<>
						
						    <Box sx={{ flexGrow: 1 }}>
						<Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
							  {smartPortion['hhm'].map((row,index) => (
							  <>
							  
						
						<Grid size={4}><br/>
						  <Item >
						  <span sx={{minHeight:'50px'}}>
						  <b>{row.name}</b>
						  </span>
						  <br/>
						  <div>
						
							</div>
							<br/>
							 {row['fractions'].map((f,i) => (
									<>
									<span key={i}>
										<Button variant="contained" size="small" onClick={(e) => onClick(e,formatFractions(row.volume,f))} >{f}</Button>	
									<br/><br/>
									</span>
									</>
									))}
						  </Item>
						</Grid>
							  </>
							  ))}
						</Grid>
					</Box>
					
					
						</>}
					</CustomTabPanel>
					
				  <CustomTabPanel value={value} index={1}>
				  
					  {smartPortion.sp.images_exists &&
					  <>
					  
					  		  
					  <Box sx={{ flexGrow: 1 }}>
						<Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
						{smartPortion['images'].map((row,index) => (
						<>
						
						<Grid size={4}><br/>
						  <Item>
						  <span sx={{minHeight:'50px'}}>
						  <b>{row.title}</b>
						  </span>
						  <br/>
						  <div>
							<img width='80px' height='80px' src={`${getImageUrl(row.img.id)}`}></img>
							</div>
							<br/>
							{row['description_array'].map((v,i) => (
									<>
									<span key={i}>
											<Button variant="contained" size="small" onClick={(e) => onClick(e,row['weight'][i])} >{row['volume'][i]}</Button>	
									<br/>
									<br/>
									</span>
									</>
							))}
						  </Item>
						</Grid>
						</>
						))}
						</Grid>
					</Box>

					  </>}
					 
				  </CustomTabPanel>
				  
				  
				  <CustomTabPanel value={value} index={2}>
					{smartPortion.sp.standard_exists &&
				<>
				
				   <Box sx={{ flexGrow: 1 }}>
						<Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
							  {smartPortion['standard'].map((row,index) => (
							  <>
							  
						
						<Grid size={4}><br/>
						  <SmallItem>
						  <span sx={{minHeight:'50px'}}>
						  <b>  {row.title}<br/>{row.description}</b>
						  </span>
						  <br/>
						  <div>
						
							</div>
							<br/>

									<span >
										<Button variant="contained" size="small" onClick={(e) => onClick(e,row.weight)} >{row.weight}</Button>	
									</span>
								
						  </SmallItem>
						</Grid>
							  </>
							  ))}
						</Grid>
					</Box>
				{/*	<TableContainer component={Paper}>
						 <Table sx={{ minWidth: 650 }} aria-label="simple table">
							<TableHead>
							  <TableRow>
								<TableCell align="left">Description</TableCell>
								<TableCell align="left">Volume</TableCell>
								<TableCell align="left">Weight</TableCell>
							  </TableRow>
							</TableHead>
							<TableBody>
							   {smartPortion['standard'].map((row,index) => (
								<TableRow
								  key={index}
								  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
								>
								  <TableCell component="th" scope="row">
								  {row.title}<br/>{row.description}
								  </TableCell>
								
								   <TableCell align="left">
									{row.volume}
								  </TableCell>
								  <TableCell align="left">
									{row.weight}
								  </TableCell>
								  
								</TableRow>
							  ))}
							</TableBody>
						  </Table>
					</TableContainer>*/}
				</>}
					
					
				  </CustomTabPanel>
				</Box>
			
			</>}
			{!smartPortionLoaded &&
			<>
				<Box sx={{ display: 'flex' }}>
				  <CircularProgress />
				</Box>
			</>}
            </div>
        );
}
 

