import axios from "axios"
var Config = require('../config.json')
import AuthService from '../service/auth'
var entity='smartPortion';

const Service = {
    getByFoodCode: function(food_code) {
		var bearerToken =AuthService.getBearerToken();
		const header = {
			headers: { Authorization: 'Bearer '+bearerToken }
		};
		return axios.get(Config.api_url+'/'+entity+'/food/'+food_code,header);
    },	
	getImageUrl: function(id) {
		return Config.api_url+'/public/smartportion/'+id;
    }
};
	
export default Service;