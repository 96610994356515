import {useEffect,useState} from 'react';
import { useNavigate } from "react-router-dom";

import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Checkbox from '@mui/material/Checkbox';
import Avatar from '@mui/material/Avatar';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
//import Box from '@mui/material/Box';
import PropTypes from 'prop-types';

import TopPrivateNavigation from '../components/TopPrivateNavigation.jsx'
import AppLogout from '../components/AppLogout.jsx'

 import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';

//services
import AuthService from '../service/auth'
import ProjectService from '../service/Project'

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const formatJson=function(json){
	return JSON.stringify(json);
}
const removeKeys=function(object,keys){
		var obj={};
		for (let key in object) {
		  if(!keys.includes(key)){
			  obj[key]=object[key];
		  }
		}
		return obj;
}

const Settings = () => {
	
	const navigate = useNavigate();
	//protected page check if logged in
	const [currentUser,setCurrentUser]=useState({});
	const [projectData,setProjectData]=useState([]);
	const [projectFound,setProjectFound]=useState(false);
	const [projectSettingsGroupData,setProjectSettingsGroupData]=useState({});

	//let params = useParams();
	let project_id =ProjectService.getCurrentProject();//params.id;
	//console.log('project_id',project_id);
	
		//admin tabs
	const [value, setValue] = useState(0);

	  const handleChange = (event, newValue) => {
		setValue(newValue);
	  };
		
	useEffect(() => {
		if(!AuthService.isUserLoggedIn()){
			// not logged in navigate back to login
			navigate("/login");
		}else{
				setCurrentUser(AuthService.getCurrentUser());
				var account_type=AuthService.getCurrentUser().__t;
				console.log('account_type',account_type);
				
				console.log(AuthService.getCurrentUser());
				var currentUser=AuthService.getCurrentUser();
				//expired Account
				if(currentUser.expired){
					var string = "account_type="+AuthService.getCurrentUser().__t+"&type=expired";
					console.log('string',string);
					var encodedString = btoa(string); // Base64 encode the String
					console.log('encodedString',encodedString);
					navigate("/restricted/"+encodedString);
					
				}else{//unverified account
					if(!currentUser.email_verified){
						var string = "account_type="+AuthService.getCurrentUser().__t+"&type=emaiverification";
						console.log('string',string);
						var encodedString = btoa(string); // Base64 encode the String
						console.log('encodedString',encodedString);
						navigate("/restricted/"+encodedString);
						
					}else{////force change Password
						if(currentUser.force_password_change){
							//not implemented	
						
						}else{
							//load project if set 
							if((typeof project_id !=="undefined")&&(project_id!==null)){//project filter
								loadProjectById(project_id);
							}
							//load
							if(account_type=='Admin'){//Admin
						
							}else if(account_type=='Master'){//Master
							
								
							}else if(account_type=='Researcher'){//Researcher
								
							}
						}
					}
				}	
		}
	}, []);
	
	
	const [checked, setChecked] = useState([0]);

  const handleToggle = (id,value) => () => {

	  var _checkBoxes=tempProjectSettingsCheckBoxes;
	 if(_checkBoxes[id][value]){
		 _checkBoxes[id][value]=false;
	 }else{
		  _checkBoxes[id][value]=true;
	 }
	 
	 var newArray=[];

	 for (var key in _checkBoxes[id]) {

		 if(_checkBoxes[id][key]){
			 newArray.push(key);
		 }
		
	}
	setTempProjectSettings((prevState) => ({ ...prevState, [id]: newArray}));
	 
	 
		const currentIndex = checked.indexOf(value);
		const newChecked = [...checked];

		if (currentIndex === -1) {
		  newChecked.push(value);
		} else {
		  newChecked.splice(currentIndex, 1);
		}

		setChecked(newChecked);
	  };
	  
	const checkifchecked=function(setting_id,key){
		if(typeof tempProjectSettingsCheckBoxes[setting_id]!=="undefined"){
			if(typeof tempProjectSettingsCheckBoxes[setting_id][key]!=="undefined"){
				return true;
			}
		}
		return false;
	}
	
	
	const [projectName,setProjectName]=useState("");
	const [projectDescription,setProjectDescription]=useState("");
	
	const [projectPrivate,setProjectPrivate]=useState(true);
	const handlePublicChange = (event, newStatus) => {
		setProjectPrivate(newStatus);
	  };  
	  
	const [tempProjectSettings,setTempProjectSettings]=useState({}); 
	const [tempProjectSettingsCheckBoxes,setTempProjectSettingsCheckBoxes]=useState({}); 
	const [tempProjectSettingsOthers,setTempProjectSettingsOthers]=useState({}); 
	const [tempProjectSettingsTemplate,setTempProjectSettingsTemplate]=useState([]); 
	
	function loadProjectById(project_id){
		ProjectService.getByIdWithSettings(project_id).then(function(res){
			setProjectData(res.data.project);
			console.log(res.data.project);
			setProjectFound(true);
			var _project=res.data.project;
			//Name
			setProjectName(_project.name);
			//Description
			setProjectDescription(_project.description);
			//Private
			setProjectPrivate(_project.is_private);
			
			console.log('project....',_project.settings);
			setTempProjectSettings(_project.settings);
			console.log('project.template...',_project.settings_template);
			setTempProjectSettingsTemplate(_project.settings_template);
			setProjectSettingsGroupData(res.data.grouped_settings);
			
			var _checkBoxes={};
			var _others={};

			for(var i=0;i<_project.settings_template.length;i++){

				var _settings=_project.settings_template[i];
				
				//multi select 
				if(_settings.type=='multi_select'){
					_checkBoxes[_settings.id]={};
					_others[_settings.id]='';
					for(var e=0;e<_settings.options.length;e++){
						var _option=_settings.options[e];

						if(_project.settings[_settings.id].includes(_option)){
							_checkBoxes[_settings.id][_option]=true;
						}else{
							_checkBoxes[_settings.id][_option]=false;
						}

					}
				}
				
				if(_settings.type=='multi_select_object'){
					_checkBoxes[_settings.id]={};
					_others[_settings.id]='';
					for(var e=0;e<_settings.options.length;e++){
						var _option=_settings.options[e];

						if(_project.settings[_settings.id].includes(_option)){
							_checkBoxes[_settings.id][_option]=true;
						}else{
							_checkBoxes[_settings.id][_option]=false;
						}

					}
				}
			}
			setTempProjectSettingsCheckBoxes(_checkBoxes);
			setTempProjectSettingsOthers(_others);

			
		}).catch(function(error){
			console.log(error);
			setProjectFound(false);
		});
	}
	
	const handleProjectReset = (e) => {
		projectData.settings={};
		ProjectService.update(projectData).then(function(res){
				//setProjectData(res.data);
				console.log(res.data);

				loadProjectById(project_id);
	  
			}).catch(function(error){
					console.log(error);
			
			});
	
	};
	
	const handleSettingsChange = (e,key) => {
		console.log('key',key);
		setTempProjectSettings((prevState) => ({ ...prevState, [key]: e.target.value }));
	};
	
	const handleSettingsOtherChange = (e,key) => {
		console.log('key',key);
		setTempProjectSettingsOthers((prevState) => ({ ...prevState, [key]: e.target.value }));
	};
	
	const handleSubmitOtherSettings = (e,settings) => {
		console.log('settings',settings);
		
		console.log('tempProjectSettingsOthers',tempProjectSettingsOthers[settings.id]);
	};
	
	const handleSubmitEditSettings=() => {
			console.log(tempProjectSettings);
			projectData.settings=tempProjectSettings;
			
			projectData.name=projectName;
			projectData.description=projectDescription;
			projectData.is_private=projectPrivate;
			console.log('projectData',projectData);
			ProjectService.update(projectData).then(function(res){
				//setProjectData(res.data);
				console.log(res.data);
				//setProjectFound(true);
				loadProjectById(project_id);
				//setEditSettingsModal(false);
				
				
				//view toast popup message
				toast.success("Settings updated !", {
					position:"top-right",
					autoClose:1000,
					hideProgressBar:true,
					theme:"colored",

						  });
	  
	  
			}).catch(function(error){
					console.log(error);
					setProjectFound(false);
			});
		};
		
	//https://fkhadra.github.io/react-toastify/introduction/
	const notify = () => toast.success("Success Notification !", {
        position:"top-right",
		autoClose:1000,
		hideProgressBar:true,
		theme:"colored",
     });
	
  return(
  <>
	<TopPrivateNavigation/>
	<AppLogout>
	
	<div>
	{/*<button onClick={notify}>Notify!</button>*/}
       <ToastContainer  />
      </div>
	
		{/* error no project found */}
	{(!projectFound) ?
		<>
		
		<br/>
		<Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" href="/">
          Home
        </Link>
		 <Typography color="text.primary">Settings</Typography>
      </Breadcrumbs>
	  
	  <br/>
	  <Divider />
		<br/>
		
		
		</>
	:<></>}
	{/* if project is found */}
	{(projectFound) ?
	<>
		<br/>
		<Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" href="/">
          Home
        </Link>
        <Link
          underline="hover"
          color="inherit"
          href="/projects"
        >
          Projects
        </Link>
		<Link
          underline="hover"
          color="inherit"
          href={`/project/${projectData._id}`}
        >
          {projectData.name}
        </Link>
		 <Typography color="text.primary">Settings</Typography>
      </Breadcrumbs>
	  
	  <br/>
	  <Divider />
		
	
	</>
	:<></>}
	
	{/* Admin */}
	{(currentUser.__t=='Admin') ?
	<>
	
	</>
	:<></>}
	
	{/* Master */}
	{(currentUser.__t=='Master') ?
	<>
	
		<div style={{ width: '100%' }}>
				<Box
				sx={{
				  display: 'flex',
				  flexDirection: 'row-reverse',
				  p: 1,
				  m: 1,
				 // bgcolor: 'background.paper',
				  borderRadius: 1,
				}}
						>
						{/*		<Button onClick={(e) => handleProjectReset(e)}  variant="outlined" >
				  Reset Project settings
	</Button>*/}
				<Button onClick={(e) => handleSubmitEditSettings(e)}  variant="contained" startIcon={<SaveIcon/>}>
				  Save Settings
				</Button>
			
			</Box>
		</div>


	
	{(projectFound) ?
	<>
	

			<Box sx={{ width: '100%' }}>
			  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
				<Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
				  <Tab label="General" {...a11yProps(0)} />
				  {Object.entries(projectSettingsGroupData).map(([key, value]) => (
					<Tab label={key} {...a11yProps(key)} />
				  ))}
				</Tabs>
			  </Box>

			  <CustomTabPanel value={value} index={0}>
				<h3>General Settings</h3>
				
				<h3>Name</h3>
				<TextField
					  margin="normal"
					  required
					  fullWidth
					  id="name"
					  label="Name"
					  name="name"
					  autoComplete="name"
					  value={projectName}
					  onChange={(e) => {
							  console.log(e.target.value);
								setProjectName(e.target.value);
						  }}
					/>

				<h3>Description</h3>
				<TextField
					  id="description"
					  label=""
					  name="Description"
					  multiline
					  rows={3}
						required
					  sx={{
						width: '100%'
					  }}
					 // defaultValue=""
					  value={projectDescription}
					  onChange={(e) => {
							  console.log(e.target.value);
								setProjectDescription(e.target.value);
						  }}
					/>
					
					<br/>
					<h3>Private</h3>
					 <ToggleButtonGroup
					  color="primary"
					  value={projectPrivate}
					  exclusive
					  onChange={handlePublicChange}
					  aria-label="Platform"
					>
					  <ToggleButton value={true}>Yes</ToggleButton>
					  <ToggleButton value={false}>No</ToggleButton>
					</ToggleButtonGroup>
			  </CustomTabPanel>
			  {Object.entries(projectSettingsGroupData).map(([key, s],i) => (
				<CustomTabPanel value={value} index={i+1}>
				<h3>{key} settings</h3>
				
				{projectSettingsGroupData[key].map((settings,index) => (
				<>
				
				<div key={settings.id}>
					<h4>{settings.name}</h4>
					<i style={{ color: '#ccc',fontWeight:'Small' }}>{settings.description}</i>
					<br/>
					{settings.type=='single_select' &&
						<>
						<FormControl  sx={{ m: 1, width: 250 }}>
							<Select
								labelId="demo-select-small-label"
								id="demo-select-small"
								value={tempProjectSettings[settings.id]}
								//label={index}
								onChange={(e) => handleSettingsChange(e,settings.id)}
								>
								{settings.options.map((opt,index) => (
									<MenuItem key={opt.id} value={opt.id}>{opt.label}</MenuItem>
								))}
							</Select>
						</FormControl>
						</>
					}
		
					{settings.type=='multi_select' &&
						
						<>
						<List dense sx={{ width: '100%', maxWidth: 360,  }}>
							{settings.options.map((value) => {
						const labelId = `checkbox-list-secondary-label-${value}`;
						return (
						  <ListItem
							key={value}
							secondaryAction={
							  <Checkbox
								edge="end"
								onChange={handleToggle(settings.id,value)}
								checked={tempProjectSettingsCheckBoxes[settings.id][value]}
								inputProps={{ 'aria-labelledby': labelId }}
							  />
							}
							disablePadding
						  >
							<ListItemButton>
							  <ListItemText id={labelId} primary={value} />
							</ListItemButton>
						  </ListItem>
							);
						})}
						</List><br/>
						<br/>
						
						</>
					}
		
					{settings.type=='multi_select_object' &&
						<>
						<List dense sx={{ width: '100%', maxWidth: 360,  }}>
							{settings.options.map((value) => {
						const labelId = `checkbox-list-secondary-label-${value}`;
						return (
						  <ListItem
							key={value}
							secondaryAction={
							  <Checkbox
								edge="end"
								onChange={handleToggle(settings.id,value)}
								checked={tempProjectSettingsCheckBoxes[settings.id][value]}
								inputProps={{ 'aria-labelledby': labelId }}
							  />
							}
							disablePadding
						  >
							<ListItemButton>
							  <ListItemText id={labelId} primary={settings.object[value]} />
							</ListItemButton>
						  </ListItem>
							);
						})}
						</List><br/>
		
					</>
					}
		
				</div>
				</>
				))}
			  </CustomTabPanel>
			  ))}
			</Box>
		</>
		:<>
			Project Not loaded
		
		</>}
	
	</>
	:<></>}
	
	{/* Researcher */}
	{(currentUser.__t=='Researcher') ?
	<>
	
	</>
	:<></>}

	</AppLogout>
	</>
  ) 
  ;
};

export default Settings;