import React from 'react';

class NewComponent extends React.Component {

    clickMe = () => {
        this.props.clickMe()
    }

    render() {
        return (
            <div>
                <button onClick={this.clickMe}>Click Me!</button>
            </div>
        )
    }
}

export default NewComponent;