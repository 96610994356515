import {useEffect,useState} from 'react';
import { useNavigate,useParams } from "react-router-dom";

import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Divider from '@mui/material/Divider';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import SettingsIcon from '@mui/icons-material/Settings';
import PublishIcon from '@mui/icons-material/Publish';
import PreviewIcon from '@mui/icons-material/Preview';
import ClearIcon from '@mui/icons-material/Clear';
import {styled } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import CloseIcon from '@mui/icons-material/Close';

import Switch from '@mui/material/Switch';

import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

//import { styled } from '@mui/material/styles';
//import { Box } from "@material-ui/core";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

import TopPrivateNavigation from '../components/TopPrivateNavigation.jsx'
import AppLogout from '../components/AppLogout.jsx'

//components
import QuestionnaireComponent from '../components/Questionnaire';

//services
import AuthService from '../service/auth'
import ProjectService from '../service/Project'
import QuestionnaireService from '../service/Questionnaire'
import ClientQuestionnaireService from '../service/ClientQuestionnaire'

const formatPageMethod=function(word) {
  return word.charAt(0).toUpperCase()+ word.slice(1);
}

const formatJson=function(s){
	return JSON.stringify(s)
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const numberToLetter=function(i){
	return ((i+1) + 9).toString(36);
}

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
  color: theme.palette.text.secondary,
  ...theme.applyStyles('dark', {
    backgroundColor: '#1A2027',
  }),
}));

function removeObjectByKey(array,keys){
	var _objs=[];
	for(let i=0;i<array.length;i++){
		if(!keys.includes(array[i].id)){
			_objs.push(array[i]);
		}
	}
	return _objs;
}



const Questionnaire = () => {
	
	const navigate = useNavigate();
	//protected page check if logged in
	const [currentUser,setCurrentUser]=useState({});
	const [devMode,setDevMode]=useState({});
	const [projectData,setProjectData]=useState([]);
	const [projectFound,setProjectFound]=useState(false);
	
	const [pageMethod,setPageMethod]=useState("");
	const [questionnaireData,setQuestionnaireData]=useState({'title':'','description':''});
	const [tempQuestionnaireData,setTempQuestionnaireData]=useState({'title':'', 'description':''});
	const [questionnaireQuestions,setQuestionnaireQuestions]=useState([]);
	const [tempQuestionnaireQuestions,setTempQuestionnaireQuestions]=useState([]);
	const [tempQuestionnaireQuestionsAndSections,setTempQuestionnaireQuestionsAndSections]=useState([]);
	const [tempQuestionnaireSectionsQuestions,setTempQuestionnaireSectionsQuestions]=useState({});
	//question
	const [tempQuestion,setTempQuestion]=useState({'question':'', 'type':'','options':[],'settings':{}});
	const [tempQuestionSettings,setTempQuestionSettings]=useState({});
	const [tempQuestionChoices,setTempQuestionChoices]=useState([]);
	const [tempQuestionFieldList,setTempQuestionFieldList]=useState([]);
	//question section
	const [tempQuestionSection,setTempQuestionSection]=useState({'title':'', 'sub_title':'','display_type': ''});
	const [tempQuestionSectionSettings,setTempQuestionSectionSettings]=useState({});
	const [tempQuestionSectionChoices,setTempQuestionSectionChoices]=useState([]);
	
		const [timeNow, setTimeNow] = useState(dayjs(new Date().setHours(0, 0, 0, 0)));
	const [dateNow, setDateNow] = useState(dayjs(new Date()));
	//let params = useParams();
	let project_id =ProjectService.getCurrentProject();//params.id;
	//console.log('project_id',project_id);
	
	let params = useParams();
	//page method 
	let method =formatPageMethod(params.method);//page method
	//console.log('method',method);
	///questionnaire id 
	let questionnaire_id =params.questionnaire_id;
	//console.log('questionnaire',questionnaire_id);
		
	useEffect(() => {
		if(!AuthService.isUserLoggedIn()){
			// not logged in navigate back to login
			navigate("/login");
		}else{
				setCurrentUser(AuthService.getCurrentUser());
				setDevMode(AuthService.isDevMode());
				var account_type=AuthService.getCurrentUser().__t;
				console.log('account_type',account_type);
				
				console.log(AuthService.getCurrentUser());
				var currentUser=AuthService.getCurrentUser();
				//expired Account
				if(currentUser.expired){
					var string = "account_type="+AuthService.getCurrentUser().__t+"&type=expired";
					console.log('string',string);
					var encodedString = btoa(string); // Base64 encode the String
					console.log('encodedString',encodedString);
					navigate("/restricted/"+encodedString);
					
				}else{//unverified account
					if(!currentUser.email_verified){
						var string = "account_type="+AuthService.getCurrentUser().__t+"&type=emaiverification";
						console.log('string',string);
						var encodedString = btoa(string); // Base64 encode the String
						console.log('encodedString',encodedString);
						navigate("/restricted/"+encodedString);
						
					}else{////force change Password
						if(currentUser.force_password_change){
							//not implemented	
						
						}else{
							setPageMethod(method);
							//load project if set 
							if((typeof project_id !=="undefined")&&(project_id!==null)){//project filter
								loadProjectById(project_id);
							}
							
							//load
							if(account_type=='Admin'){//Admin
						
							}else if(account_type=='Master'){//Master
								loadQuestionnaireTypes();
								if(method=='Edit'){
									loadQuestionnaireById(questionnaire_id);
								}else if(method=='View') {
									loadQuestionnaireById(questionnaire_id);
								}else if(method=='New') {
									loadQuestionnaireTemplate();
								}else if(method=='Clone') {
									loadQuestionnaireById(questionnaire_id);
								}
								
							}else if(account_type=='Researcher'){//Researcher
								
							}
						}
					}
				}	
		}
	}, []);
	
	/*Notifications  */
	const [ShowErrorMessage,setErrorStatus]=useState(false); 
	const [ErrorText,setErrorMessage]=useState(""); 
	
	const [ShowSuccessMessage,setSuccessStatus]=useState(false); 
	const [SuccessText,setSuccessMessage]=useState("");
	
	function clearMessages(){
		setErrorStatus(false);
		setErrorMessage("");
		setSuccessStatus(false);
		setSuccessMessage("");
	}
	
	function createErrorMessage(msg){
		clearMessages();
		setErrorStatus(true);
		setErrorMessage(msg);
	}
	
	function createSuccessMessage(msg){
		clearMessages();
		setSuccessStatus(true);
		setSuccessMessage(msg);
	}
	
	const renderAlertBar=() => {
		return <div style={{ width: '100%' }}>
					<Box
						sx={{display: 'flex',flexDirection: 'row-reverse',p: 1,m: 1,borderRadius: 1,
						}}
					>
					<Stack sx={{ width: '100%' }} spacing={2}>
						{ ShowErrorMessage &&
							<Alert severity="error">{ErrorText}</Alert>
							} 
						{ ShowSuccessMessage &&
							<Alert severity="success">{SuccessText}</Alert>
						}
						</Stack>
					</Box>
			</div>
	};
	
	//load project 
	function loadProjectById(project_id){
		ProjectService.getById(project_id).then(function(res){
		setProjectData(res.data);
		console.log(res.data);
		setProjectFound(true);
		}).catch(function(error){
			console.log(error);
			setProjectFound(false);
		});
	}
	
	//load questionnaire 
	function loadQuestionnaireById(questionnaire_id){
		QuestionnaireService.getById(questionnaire_id).then(function(res){
		setQuestionnaireData(res.data.questionnaire);
		setTempQuestionnaireData(res.data.questionnaire);
		setQuestionnaireQuestions(res.data.questions);
		setTempQuestionnaireQuestions(res.data.questions);
		setTempQuestionnaireQuestionsAndSections(res.data.allQuestionsAndSections);
		setTempQuestionnaireSectionsQuestions(res.data.section_questions);
		
		
		createTempResponses(res.data.questions,res.data.section_questions);
		console.log(res.data);
		}).catch(function(error){
			console.log(error);
		});
	}
	
	function loadQuestionnaireTemplate(){
		var questionnaire={
			'title':'',
		    'description':''
		}
		if((typeof project_id !=="undefined")&&(project_id!==null)){
			questionnaire.project=project_id;
			questionnaire.global=false;
		}else{
			questionnaire.project=null;
			questionnaire.global=true;
		}
		questionnaire.created_by=AuthService.getCurrentUser()._id;
		setQuestionnaireData(questionnaire);
		setTempQuestionnaireData(questionnaire);
	}
	
	const [defaultQuestionType,setDefaultQuestionTypes]=useState({});
	const [questionTypes,setQuestionTypes]=useState([]);
	const [questionTypesObject,setQuestionTypesObject]=useState({});
	function loadQuestionnaireTypes(){
		QuestionnaireService.getTypes().then(function(res){
			let _questionTypes =res.data.array;
			setQuestionTypes(_questionTypes);
			setQuestionTypesObject(res.data.obj);
			if(_questionTypes.length>0){
				setDefaultQuestionTypes(_questionTypes[0]);
			}
			console.log(res.data);
		}).catch(function(error){
			console.log(error);
		});
	}
	
	const handleOnChangeValue = (e,key) => {
		setTempQuestionnaireData({...tempQuestionnaireData,
			[key]: e.target.value,
		})
	};
	
	//handleOnChangeQuestionValue
	const handleOnChangeQuestionValue = (e,key) => {
		setTempQuestion({...tempQuestion,
			[key]: e.target.value,
		})
	};
	
	const handleOnChangeQuestionType = (e) => {
		let _type=e.target.value;
				console.log('type',_type);
			console.log('question type object:',questionTypesObject[_type].default_settings)
		setTempQuestion({...tempQuestion,
			['type']: _type,
		})
		setTempQuestionSettings(generateSettings(questionTypesObject[e.target.value].default_settings));
	};
	
	const handleOnChangeQuestionSettings = (e,key) => {
		setTempQuestionSettings({...tempQuestionSettings,
			[key]:e.target.value,
		})
	};
	
	function generateSettings(_def,){
		var _settings ={};
		for(let i=0;i<_def.length;i++){
				_settings[_def[i].id]=_def[i].value;
		}
		return _settings;
	}
	
	const handleAddingNewQuestion = (e) => {
		let question={
			question: '',
			choices: [''],
			other_choice_text: 'Other',
			other_choice_added: false,
			list_name: '',
			field_list: [{'field':'','help_text':''}],
			questionnaire: questionnaire_id,
			//options: { type: Object, required: false,default:[] },//multiple select and single select 
			type: defaultQuestionType.id,//setting max length 
			settings: generateSettings(defaultQuestionType.default_settings),//setting max length 
		}
		question.created_by=AuthService.getCurrentUser()._id;
		console.log('question',question);
		QuestionnaireService.addQuestion(question).then(function(res){
			console.log(res.data);
			loadQuestionnaireById(questionnaire_id);
			var _question=res.data;
			
			setTempQuestion(_question);
			setTempQuestionSettings(_question.settings);
			setTempQuestionChoices(_question.choices);
			setTempQuestionFieldList(_question.field_list);
			setOtherChoiceAdded(_question.other_choice_added);
			setOtherChoiceText(_question.other_choice_text)
	
			//reset all others
			for (var _id in loadingId) {
				setLoadingId(ids => ({
					 ...ids,
					[_id]: false
				}));
			}
			setLoadingId(ids => ({
				 ...ids,
				[_question.id]: true
			}));
		}).catch(function(error){
			console.log(error);
		});
	};
	
	
	const handleAddingNewQuestionToSelection = (e,questionSelection) => {
		let question={
			question: '',
			help_text: '',
			choices: [],
			other_choice_text: 'Other',
			other_choice_added: false,
			questionnaire: questionnaire_id,
			question_section :questionSelection._id,
			part_of_section:true,	
			//options: { type: Object, required: false,default:[] },//multiple select and single select 
			type: 'single_choice_text',//defaultQuestionType.id,//setting max length 
			settings: generateSettings(defaultQuestionType.default_settings),//setting max length 
		}
		question.created_by=AuthService.getCurrentUser()._id;
		console.log('question',question);
		console.log('questionSelection',questionSelection);
		QuestionnaireService.addQuestion(question).then(function(res){
			console.log(res.data);
			loadQuestionnaireById(questionnaire_id);
			var _question=res.data;
			
			setTempQuestion(_question);
			setTempQuestionSettings(_question.settings);
			setTempQuestionChoices(_question.choices);
			setTempQuestionFieldList(_question.field_list);
			setOtherChoiceAdded(_question.other_choice_added);
			setOtherChoiceText(_question.other_choice)
			
			//reset all others
			for (var _id in loadingId) {
				setLoadingId(ids => ({
					 ...ids,
					[_id]: false
				}));
			}
			setLoadingId(ids => ({
				 ...ids,
				[_question.id]: true
			}));
		}).catch(function(error){
			console.log(error);
		});
	};
	
	
	//openAddNewQuestionSectionModal
	const [openAddNewQuestionSectionModal, setOpenAddNewQuestionSectionModal] = useState(false);
	const [jsonCode, setJsonCode] = useState('{}');
	const handleAddingNewQuestionSectionModal = (e) => {
		console.log('show modal....',questionnaire_id);
		setOpenAddNewQuestionSectionModal(true);
	}
	
	const handleAddNewQuestionSectionModalClose = () => {
		setOpenAddNewQuestionSectionModal(false);
	};
	
	const handleAddNewQuestionSection = () => {
		
		let _jsonCode=JSON.parse(jsonCode);
		
		let questionSection= _jsonCode.questionSection;
		questionSection.questionnaire= questionnaire_id;
		questionSection.created_by= AuthService.getCurrentUser()._id;
		let questions= _jsonCode.questions;
		console.log('questionSection',questionSection);
		console.log('questions',questions);
		//setOpenAddNewQuestionSectionModal(false);
		
			QuestionnaireService.addQuestionSection(questionSection).then(function(res){
				console.log(res.data);
				//loadQuestionnaireById(questionnaire_id);
				var _questionSection=res.data;
				for(let i=0;i<questions.length;i++){
					
					let _question=questions[i];
					_question.created_by=AuthService.getCurrentUser()._id;
					_question.questionnaire=questionnaire_id;
					_question.question_section=_questionSection._id;
					console.log('_question',_question);
					QuestionnaireService.addQuestion(_question).then(function(res1){
						console.log(res1.data);
						//var _question=res.data;
						
					}).catch(function(error1){
						console.log(error1);
					});
					
				}
				loadQuestionnaireById(questionnaire_id);
				
				//setTempQuestionSection(_questionSection);
				//setTempQuestionSectionSettings(_questionSection.settings);
				//setTempQuestionSectionChoices(_questionSection.choices);
				
				//reset all questions
				/*for (var _id in loadingId) {
					setLoadingId(ids => ({
						 ...ids,
						[_id]: false
					}));
				}*/
				//reset all sections
				/*for (var _id in sectionId) {
					setSectionId(ids => ({
						 ...ids,
						[_id]: false
					}));
				}
				//
				 let _questionSectionId = _questionSection.id;
				 console.log(_id);
					setSectionId(ids => ({
					 ...ids,
					[_questionSectionId]: true
				}));*/
		
		}).catch(function(error){
			console.log(error);
		});
	};
	
	const handleOnChangeJsonCode = (e) => {
		setJsonCode(e.target.value);
	};
	
	
	const handleAddingNewQuestionSection = (e) => {
		let questionSection={
			title: '',
			sub_title: '',
			help_text: '',
			display_type: 'list',
			question_type: 'mixed',
			choices: [],
			questionnaire: questionnaire_id,
			//options: { type: Object, required: false,default:[] },//multiple select and single select 
			//type: defaultQuestionType.id,//setting max length 
			//settings: generateSettings(defaultQuestionType.default_settings),//setting max length 
		}
		
		
		questionSection.created_by=AuthService.getCurrentUser()._id;
		console.log('questionSection',questionSection);
		QuestionnaireService.addQuestionSection(questionSection).then(function(res){
			console.log(res.data);
			loadQuestionnaireById(questionnaire_id);
			var _questionSection=res.data;
			
			setTempQuestionSection(_questionSection);
			setTempQuestionSectionSettings(_questionSection.settings);
			setTempQuestionSectionChoices(_questionSection.choices);
			
			//reset all questions
			/*for (var _id in loadingId) {
				setLoadingId(ids => ({
					 ...ids,
					[_id]: false
				}));
			}*/
			//reset all sections
			for (var _id in sectionId) {
				setSectionId(ids => ({
					 ...ids,
					[_id]: false
				}));
			}
			//
			 let _questionSectionId = _questionSection.id;
			 console.log(_id);
				setSectionId(ids => ({
				 ...ids,
				[_questionSectionId]: true
			}));
		
		}).catch(function(error){
			console.log(error);
		});
	}
	
	const [loadingId, setLoadingId] = useState({});
	const checkLoadingId=function(id){
		return loadingId[id];
	};
	
	const handleCancelEdit=(e,question) => {
		const { id } = e.target;
			setLoadingId(ids => ({
			 ...ids,
			[id]: false
		}));
	};
	
	
	//section 
	const [sectionId, setSectionId] = useState({});
	const checkSectionId=function(id){
		return sectionId[id];
	};
	
	const handleEditSectionMode=(e,questionSection) => {
		setTempQuestionSection(questionSection);
		setTempQuestionSectionSettings(questionSection.settings);
		setTempQuestionSectionChoices(questionSection.choices);
		//setTempQuestionFieldList(question.field_list);
		//reset all questions
		/*for (var _id in loadingId) {
			setLoadingId(ids => ({
				 ...ids,
				[_id]: false
			}));
		}*/
		//reset all others
		for (var _id in sectionId) {
			setSectionId(ids => ({
				 ...ids,
				[_id]: false
			}));
		}
		//
		 const { id } = e.target;
			setSectionId(ids => ({
			 ...ids,
			[id]: true
		}));
	};
	
	const handleSaveSectionMode=(e,row) => {
		
		console.log('setTempQuestionSection',tempQuestionSection);
		tempQuestionSection.choices=tempQuestionSectionChoices;
		QuestionnaireService.updateQuestionSection(tempQuestionSection).then(function(res){
			console.log(res.data);
			var _questionSection=res.data;
			loadQuestionnaireById(questionnaire_id);
			setTempQuestionSection(_questionSection);
			setTempQuestionSectionSettings(_questionSection.settings);
			setTempQuestionSectionChoices(_questionSection.choices);
			
			
		 const _id = row.id;
			setSectionId(ids => ({
				 ...ids,
				[_id]: false
			}));

		}).catch(function(error){
			console.log(error);
		});
		 /*
		 
		 
		 */
	};
	
	const handleCancelSectionMode=(e,row) => {
		const _id = row.id;
		setSectionId(ids => ({
				 ...ids,
				[_id]: false
			}));
	
	
	};
	
	//handleOnChangeQuestionSection
	const handleOnChangeQuestionSection = (e,key) => {
		setTempQuestionSection({...tempQuestionSection,
			[key]:e.target.value,
		})
	};
	
	const sectionDisplayTypes=[
		{'id':'list','name':'List'},
		{'id':'table','name':'Table'}
	];
	
	const handleOnChangeSectionDisplayType = (e) => {
		let _type=e.target.value;
		setTempQuestionSection({...tempQuestionSection,
			['display_type']: _type,
		})
	
	};
	
	const sectionQuestionTypes={
		'list':[
			{'id':'mixed','name':'Mixed'},
		],
		'table':[
			{'id':'single_choice','name':'Single Choice'},
			{'id':'single_choice_text','name':'Single Choice with text input'},
		],
	};
	
	const handleOnChangeSectionQuestionType = (e) => {
		let _type=e.target.value;
		setTempQuestionSection({...tempQuestionSection,
			['question_type']: _type,
		})
	
	};
	
	const [openClientQuestionnaireModal, setOpenClientQuestionnaireModal] = useState(false);
	
	
	const handleClientQuestionnaireClose = () => {
		setOpenClientQuestionnaireModal(false);
	};
	
	const handleViewQuesionnaireSubmit=(e) => {
		console.log('tempResponses',tempResponses)
	};
	
	const handleTestSubmit=(e) => {
		console.log('handleTestSubmit')
		console.log('tempResponses',tempResponses);
		
		
		let _tempClientQuestionnaire={
			'clientQuestionnaire':{
				'questionnaire':questionnaire_id,
				'client':null,
				'test':true,
				'created_by':AuthService.getCurrentUser()._id
			},'responses':tempResponses
		}
		
		console.log('_tempClientQuestionnaire',_tempClientQuestionnaire);
		ClientQuestionnaireService.create(_tempClientQuestionnaire).then(function(res){
			console.log(res.data);
			//setTestTempReponses(res.data);
			///setOpenClientQuestionnaireModal(true);
			let clientQuestionnaire_id=res.data.clientQuestionnaire.id;
			console.log('clientQuestionnaire_id',clientQuestionnaire_id);
			ClientQuestionnaireService.getById(clientQuestionnaire_id).then(function(res1){
				console.log(res1.data);
				setTestTempReponses(res1.data);
				setOpenClientQuestionnaireModal(true);
			}).catch(function(error1){
				console.log(error1);
			});
		}).catch(function(error){
			console.log(error);
		});
	};
	
	const handleSaveMode=(e,question) => {
			//save mode 
			//console.log('save changes');
	 const { id } = e.target;
			setLoadingId(ids => ({
			 ...ids,
			[id]: false
		}));
		console.log('question',question);
		console.log('tempQuestion',tempQuestion);
		console.log('tempQuestionSettings',tempQuestionSettings);
		tempQuestion.settings=tempQuestionSettings;
		tempQuestion.choices=tempQuestionChoices;
		tempQuestion.field_list=tempQuestionFieldList;
		tempQuestion.other_choice_added=otherChoiceAdded;
		tempQuestion.other_choice_text=otherChoiceText;

		QuestionnaireService.updateQuestion(tempQuestion).then(function(res){
			console.log(res.data);
			loadQuestionnaireById(questionnaire_id);

		}).catch(function(error){
			console.log(error);
		});
	};
	
	const handleEditMode=(e,question) => {
		setTempQuestion(question);
		setTempQuestionSettings(question.settings);
		setTempQuestionChoices(question.choices);
		setTempQuestionFieldList(question.field_list);
		setOtherChoiceAdded(question.other_choice_added);
		setOtherChoiceText(question.other_choice_text)
		//reset all others
		for (var _id in loadingId) {
			setLoadingId(ids => ({
				 ...ids,
				[_id]: false
			}));
		}
		//reset all sections
		/*	for (var _id in sectionId) {
				setSectionId(ids => ({
					 ...ids,
					[_id]: false
				}));
			}*/
		//
		 const { id } = e.target;
			setLoadingId(ids => ({
			 ...ids,
			[id]: true
		}));
	};
	
	/*field list*/
	const handleAddFieldList=(e) => {
		console.log(e);
		const newFieldList = [...tempQuestionFieldList];
		newFieldList.push({'field':'','help_text':''});
		setTempQuestionFieldList(newFieldList);
	}
	
	const handleRemoveFieldList=(e,index) => {
		console.log(e,index);
			
		const newFieldList = [...tempQuestionFieldList];
		newFieldList.splice(index, 1);
		setTempQuestionFieldList(newFieldList);
	}
	
	const handleOnChangeQuestionFieldList = (e,index,key) => {
		console.log(e.target.value,index);
		
		const newFieldList = [...tempQuestionFieldList];
		newFieldList[index][key]=e.target.value;
		setTempQuestionFieldList(newFieldList);
		
	};
	
	/*choice*/
	const handleAddChoice=(e) => {
		console.log(e);
		const newChoiceList = [...tempQuestionChoices];
		newChoiceList.push("");
		setTempQuestionChoices(newChoiceList);
	}
	
	const handleRemoveChoice=(e,index) => {
		console.log(e,index);
			
		const newChoiceList = [...tempQuestionChoices];
		newChoiceList.splice(index, 1);
		setTempQuestionChoices(newChoiceList);
	}
	
	const handleOnChangeQuestionChoices = (e,index) => {
		console.log(e.target.value,index);
		
		const newChoiceList = [...tempQuestionChoices];
		newChoiceList[index]=e.target.value;
		setTempQuestionChoices(newChoiceList);
		
	};
	
	//handleAutoPopulateChoice
	const handleAutoPopulateChoice = (e,sub_type) => {
		console.log('sub_type:',sub_type.values);
		setTempQuestionChoices(sub_type.values);
		
	};
	
	//handleClearChoice
	const handleClearChoice = (e) => {
		setTempQuestionChoices([]);
		
	};
	
	//handleAddOtherChoice
	const [otherChoiceAdded, setOtherChoiceAdded] = useState(false);
	const [otherChoiceText, setOtherChoiceText] = useState("Other");
	const handleAddOtherChoice = (e) => {
		//setTempQuestionChoices([]);
		console.log('e',e.target.checked);
		setOtherChoiceAdded(e.target.checked);
		
	};
	
	const handleAddOtherChoiceText = (e) => {
		setOtherChoiceText(e.target.value);
		
	};
	
	/*choice question section*/
	const handleAddQuestionSectionChoice=(e) => {
		console.log(e);
		const newChoiceList = [...tempQuestionSectionChoices];
		newChoiceList.push("");
		setTempQuestionSectionChoices(newChoiceList);
	}
	
	const handleRemoveQuestionSectionChoice=(e,index) => {
		console.log(e,index);
			
		const newChoiceList = [...tempQuestionSectionChoices];
		newChoiceList.splice(index, 1);
		setTempQuestionSectionChoices(newChoiceList);
	}
	//handleOnChangeQuestionSectionChoices
	const handleOnChangeQuestionSectionChoices = (e,index) => {
		console.log(e.target.value,index);
		const newChoiceList = [...tempQuestionSectionChoices];
		newChoiceList[index]=e.target.value;
		setTempQuestionSectionChoices(newChoiceList);
	};
	
	//handleAutoPopulateChoice
	const handleAutoPopulateQuestionSectionChoice = (e,sub_type) => {
		console.log('sub_type:',sub_type.values);
		setTempQuestionSectionChoices(sub_type.values);
		
	};
	
	//handleClearChoice
	const handleClearQuestionSectionChoice = (e) => {
		console.log('tempQuestionSectionChoices',tempQuestionSectionChoices);
		setTempQuestionSectionChoices([]);
		
	};
	
	//handleAddOtherChoice
	/*const [otherChoiceAdded, setOtherChoiceAdded] = useState(false);
	const [otherChoiceText, setOtherChoiceText] = useState("Other");
	const handleAddOtherChoice = (e) => {
		//setTempQuestionChoices([]);
		console.log('e',e.target.checked);
		setOtherChoiceAdded(e.target.checked);
		
	};
	
	const handleAddOtherChoiceText = (e) => {
		setOtherChoiceText(e.target.value);
		
	};*/
	
	/* Delete Question  */
	const [openDeleteQuestionModal, setOpenDeleteQuestionModal] = useState(false);
	
	const handleDeleteQuestionClose = () => {
		setOpenDeleteQuestionModal(false);
	  };
	  
	const handleDeleteQuestionModal=(e,question) => {
		setTempQuestion(question);
		setOpenDeleteQuestionModal(true);
	}
	
	const handleDeleteQuestion=(e) => {
		QuestionnaireService.deleteQuestion(tempQuestion).then(function(res){
			console.log(res.data);
			loadQuestionnaireById(questionnaire_id);
			setOpenDeleteQuestionModal(false);
		}).catch(function(error){
			console.log(error);
		});
	}
	
	/* Delete Question  section*/
	const [openDeleteQuestionSectionModal, setOpenDeleteQuestionSectionModal] = useState(false);
	
	const handleDeleteQuestionSectionClose = () => {
		setOpenDeleteQuestionSectionModal(false);
	  };
	  
	const handleDeleteQuestionSectionModal=(e,questionSection) => {
		setTempQuestionSection(questionSection);
		setOpenDeleteQuestionSectionModal(true);
	}
	
	const handleDeleteQuestionSection=(e) => {
		QuestionnaireService.deleteQuestionSection(tempQuestionSection).then(function(res){
			console.log(res.data);
			loadQuestionnaireById(questionnaire_id);
			setOpenDeleteQuestionSectionModal(false);
		}).catch(function(error){
			console.log(error);
		});
	}
	
	
	const handleMoveQuestion=(e,row,direction) => {
		console.log('row',row,'direction',direction);
		/*QuestionnaireService.deleteQuestionSection(tempQuestionSection).then(function(res){
			console.log(res.data);
			loadQuestionnaireById(questionnaire_id);
			setOpenDeleteQuestionSectionModal(false);
		}).catch(function(error){
			console.log(error);
		});*/
	}
	
	const handleMoveQuestionSection=(e,row,direction) => {
		console.log('row',row,'direction',direction);
		/*QuestionnaireService.deleteQuestionSection(tempQuestionSection).then(function(res){
			console.log(res.data);
			loadQuestionnaireById(questionnaire_id);
			setOpenDeleteQuestionSectionModal(false);
		}).catch(function(error){
			console.log(error);
		});*/
	}
	
	const handleSubmitQuestionnaire=(e,m) => {

		console.log('e',e);
		console.log('method',m);
		
		if(m=='New'){
			console.log('tempQuestionnaireData',tempQuestionnaireData);
			
			if(tempQuestionnaireData.title!==''){
				QuestionnaireService.create(tempQuestionnaireData).then(function(res){
					console.log(res.data);
					navigate("/questionnaire/edit/"+res.data._id);
					navigate(0);//refresh page
				}).catch(function(error){
					console.log(error);
					
				});
			}else{
				createErrorMessage("Please give questionnaire a title");
				//console.log('no name set !!!');
			}
			
		}
		if(m=='Edit'){
			if(tempQuestionnaireData.title!==''){
				QuestionnaireService.update(tempQuestionnaireData).then(function(res){
					console.log(res.data);
					loadQuestionnaireById(questionnaire_id);
					createSuccessMessage("Questionnaire updated");
				}).catch(function(error){
					console.log(error);
				});
			}else{
				createErrorMessage("Please give questionnaire a title");
				//console.log('no name set !!!');
			}
		}
		if(m=='Publish'){
			if(tempQuestionnaireData.title!==''){
				tempQuestionnaireData.published=true;
				QuestionnaireService.update(tempQuestionnaireData).then(function(res){
					console.log(res.data);
					loadQuestionnaireById(questionnaire_id);
					createSuccessMessage("Questionnaire published.");
				}).catch(function(error){
					console.log(error);
				});
			}else{
				createErrorMessage("Please give questionnaire a title");
				//console.log('no name set !!!');
			}
		}
		if(m=='View'){
			//edit
			navigate("/questionnaire/edit/"+questionnaire_id);
			navigate(0);//refresh page
		}
		
		if(m=='Clone'){
			//update then redirect to view or edit with alert
			
			/*if(tempQuestionnaireData.title!==''){
				QuestionnaireService.addQuestion(tempQuestionnaireData).then(function(res){
					console.log(res.data);
					loadQuestionnaireById(questionnaire_id);
					createSuccessMessage("Questionnaire updated");
				}).catch(function(error){
					console.log(error);
				});
			}else{
				createErrorMessage("Please give questionnaire a title");
				//console.log('no name set !!!');
			}*/
			
		}
	};
	
	//View
	
	// click event popup
		const [tempResponses,setTempReponses]=useState({});
		const [tempTestResponses,setTestTempReponses]=useState({});
		//question
		const handleOnChangeQuestionResponseValue = (e,question,key,index) => {
			console.log('e',e);
			console.log('question',question);
			console.log('key',key);
			console.log('index',index);
			let question_id=question.id;
			let type=question.type;
			console.log('Type:',type);
			
			if(type=='text'){
				let value= e.target.value;
				setTempReponses({...tempResponses,
					[question_id]: [value],
				})
			}
			else if(type=='number'){
				let value= e.target.value;
				setTempReponses({...tempResponses,
					[question_id]: [value],
				})
			}
			else if(type=='date'){
				let value= e.$d;
				setTempReponses({...tempResponses,
					[question_id]: [value],
				})
			}
			else if(type=='time'){
				let value= e.$d;
				setTempReponses({...tempResponses,
					[question_id]: [value],
				})
			}
			else if(type=='multi_choice'){
				let value= e.target.value;
				var _temp =tempResponses[question_id];
				if(e.target.checked){//add
					_temp.push(value);
				}else{//remove
					var _index = _temp.indexOf(value);
					if (_index !== -1) {
					  _temp.splice(_index, 1);
					}
				}
				setTempReponses({...tempResponses,
					[question_id]: _temp,
				})
			}
			else if(type=='single_choice'){
					let value= e.target.value;
				setTempReponses({...tempResponses,
					[question_id]: [value],
				})
				console.log('single choice value',value);
			}
			else if(type=='comment'){
					let value= e.target.value;
					setTempReponses({...tempResponses,
						[question_id]: [value],
					})
				}else{
				let value= e.target.value;
				setTempReponses({...tempResponses,
					[question_id]: [value],
				})
				
			}
			console.log(tempResponses[question_id]);
		}
		//question section
		const handleOnChangeQuestionSectionResponseValue = (e,question_section,question,key,index) => {
			console.log('e',e);
			console.log('question_section',question_section);
			console.log('question',question);
			let question_section_id=question_section.id;
			let question_id=question.id;
			let display_type=question_section.display_type;
			if(display_type=='table'){
				let question_type=question_section.question_type;
				console.log('question_type',question_type)
				if(question_type=='single_choice'){
						let value= e.target.value;
					setTempReponses({...tempResponses,
						[question_id]: [value],
					})
				}
				if(question_type=='single_choice_text'){
						let value= e.target.value;
						var _temp =tempResponses[question_id];
						console.log('_temp',_temp);
						console.log('key',key);
						let t={};
						for(let _k in _temp){
							t[_k]='';
						}//test
						//if(typeof _temp =="undefined"){
						//	_temp=[{}];
						//}
						//clear all other values
						t[key]=value;
						//_temp[0][key]=value;
					setTempReponses({...tempResponses,
						[question_id]: t,
					})
				}
				
			}else{//mixed 
				if(type=='text'){
					let value= e.target.value;
					setTempReponses({...tempResponses,
						[question_id]: [value],
					})
				}
				else if(type=='number'){
					let value= e.target.value;
					setTempReponses({...tempResponses,
						[question_id]: [value],
					})
				}
				else if(type=='date'){
					let value= e.target.value;
					setTempReponses({...tempResponses,
						[question_id]: [value],
					})
				}
				else if(type=='time'){
					let value= e.target.value;
					setTempReponses({...tempResponses,
						[question_id]: [value],
					})
				}
				else if(type=='multi_choice'){
					let value= e.target.value;
					var _temp =tempResponses[question_id];
					if(e.target.checked){//add
						_temp.push(value);
					}else{//remove
						var _index = _temp.indexOf(value);
						if (_index !== -1) {
						  _temp.splice(_index, 1);
						}
					}
					setTempReponses({...tempResponses,
						[question_id]: _temp,
					})
				}
				else if(type=='single_choice'){
						let value= e.target.value;
					setTempReponses({...tempResponses,
						[question_id]: [value],
					})
				}
				else if(type=='comment'){
						let value= e.target.value;
						setTempReponses({...tempResponses,
							[question_id]: [value],
						})
				}else{
					let value= e.target.value;
					setTempReponses({...tempResponses,
						[question_id]: [value],
					})
				}
			}
		}
		
		const handleOnChangeResponseValue = (e,question,key,index) => {
		console.log(e);
		console.log(key);
		console.log(question);
		console.log(index);
		let question_id=question.id;
		let type=question.type;
		
		console.log(tempResponses);
		console.log(tempResponses[question_id]);
		console.log('Type:',type);
		if(type=='multi_choice'){
			let value= e.target.value;
			var _temp =tempResponses[question_id];
			if(e.target.checked){//add
				_temp.push(value);
			}else{//remove
				var _index = _temp.indexOf(value);
				if (_index !== -1) {
				  _temp.splice(_index, 1);
				}
			}
			setTempReponses({...tempResponses,
				[question_id]: _temp,
			})
		}
		else if(type=='single_choice'){
				let value= e.target.value;
			setTempReponses({...tempResponses,
				[question_id]: [value],
			})
		}
		else if(type=='single_choice_text'){
				let value= e.target.value;
				var _temp =tempResponses[question_id];
				console.log('_temp',_temp);
				console.log('key',key);
				let t={};
				for(let _k in _temp){
					t[_k]='';
				}
				//if(typeof _temp =="undefined"){
				//	_temp=[{}];
				//}
				//clear all other values
				t[key]=value;
				//_temp[0][key]=value;
			setTempReponses({...tempResponses,
				[question_id]: t,
			})
		}
		else if(type=='time'){
			let value= e.$d;
			setTempReponses({...tempResponses,
				[question_id]: [value],
			})
			
		}else if(type=='date'){
					let value= e.$d;
			setTempReponses({...tempResponses,
				[question_id]: [value],
			})
			
		}else if(type=='comment'){
				let value= e.target.value;
			setTempReponses({...tempResponses,
				[question_id]: [value],
			})
			
		}else if(type=='text'){
				let value= e.target.value;
			setTempReponses({...tempResponses,
				[question_id]: [value],
			})
			
		}else if(type=='list'){//key and index
				let value= e.target.value;
			const newReponses = {...tempResponses};
				//console.log('newReponses',newReponses);
				//console.log('index',index);
				//console.log('key',key);
				//console.log('newReponses[question_id][index][key]',newReponses[question_id][index][key]);
				newReponses[question_id][index][key]=e.target.value;
				setTempReponses(newReponses);
				//setTempReponses({...tempResponses,
				//[question_id]: newReponses,
			//})
		}else{
			let value= e.target.value;
			setTempReponses({...tempResponses,
				[question_id]: [value],
			})
			
		}

		console.log(tempResponses[question_id]);
	};
	
	//handleAddListResponse
	const handleAddListResponse = (e,question) => {
		addBlankListItemToQuestion(question);
	};
	
	const handleRemoveListResponse = (e,question,index) => {
		removeBlankListItemToQuestion(question,index);
	};
	
	function createTempResponses(questions,section_questions){
		var responses={};
		for(let i=0;i<questions.length;i++){
			console.log('counter',i);
			//var _type=questions[i].type;
			responses[questions[i].id]=[];
		}
		
		for (var question_id in section_questions) {
			let _questions=section_questions[question_id];
			for(let i=0;i<_questions.length;i++){
				let _q=_questions[i];
				let _t=_q.type;
				if(_t=='single_choice_text'){
					let opt={};
					for(let c=0;c<_q.choices.length;c++){
						opt[_q.choices[c]]='';
					}
					responses[_q.id]=opt;
				}else{
					responses[_q.id]=[];
				}
				
			}
			
		}
		
		console.log('section_questions',section_questions);
		console.log('questions',questions);
		console.log('responses',responses);
		setTempReponses(responses);
	}
	
	function addBlankListItemToQuestion(question){
		console.log('question',question);
		const newReponses = {...tempResponses};
		let _fields ={};
		for(let f=0;f<question.field_list.length;f++){
			_fields[question.field_list[f].field]=''
		}
		newReponses[question.id].push(_fields);
		setTempReponses(newReponses);
	}
	
	function removeBlankListItemToQuestion(question,index){
		console.log('question',question);
		const newReponses = {...tempResponses};
		newReponses[question.id].splice(index,1);
		setTempReponses(newReponses);
	}

	
  return(
  <>
	<TopPrivateNavigation/>
	<AppLogout>
	
		{/* error no project found */}
	{(!projectFound) ?
		<>
		
		<br/>
		<Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" href="/">
          Home
        </Link>
		<Link
          underline="hover"
          color="inherit"
          href={`/Questionnaires`}
        >
          Questionnaires
        </Link>
		 <Typography color="text.primary">{method}</Typography>
      </Breadcrumbs>
	  
	  <br/>
	  <Divider />
		<br/>
		
		
		</>
	:<></>}
	{/* if project is found */}
	{(projectFound) ?
	<>
		<br/>
		<Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" href="/">
          Home
        </Link>
        <Link
          underline="hover"
          color="inherit"
          href="/projects"
        >
          Projects
        </Link>
		<Link
          underline="hover"
          color="inherit"
          href={`/project/${projectData._id}`}
        >
          {projectData.name}
        </Link>
		<Link
          underline="hover"
          color="inherit"
          href={`/Questionnaires`}
        >
          Questionnaires
        </Link>
		 <Typography color="text.primary">{method}</Typography>
      </Breadcrumbs>
	  
	  <br/>
	  <Divider />
		<br/>
	
	</>
	:<></>}
	
	{/* Admin */}
	{(currentUser.__t=='Admin') ?
	<>
	
	</>
	:<></>}
	
	{/* Master */}
	{(currentUser.__t=='Master') ?
	<>
	
	 <Dialog
        open={openDeleteQuestionModal}
        onClose={handleDeleteQuestionClose}
        //aria-labelledby="alert-dialog-title"
		//aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to delete this question?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
		  
          </DialogContentText>
        </DialogContent>
        <DialogActions>
			<Button onClick={handleDeleteQuestionClose}>No</Button>
			<Button onClick={handleDeleteQuestion} > Yes</Button>
        </DialogActions>
      </Dialog>
	  
	  <Dialog
        open={openDeleteQuestionSectionModal}
        onClose={handleDeleteQuestionSectionClose}
        //aria-labelledby="alert-dialog-title"
		//aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to delete this section?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
		  
          </DialogContentText>
        </DialogContent>
        <DialogActions>
			<Button onClick={handleDeleteQuestionSectionClose}>No</Button>
			<Button onClick={handleDeleteQuestionSection} > Yes</Button>
        </DialogActions>
      </Dialog>
	  
	  <Dialog
        open={openAddNewQuestionSectionModal}
        onClose={handleAddNewQuestionSectionModalClose}
        //aria-labelledby="alert-dialog-title"
		//aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
         
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
		  
		  <TextField  id=""  label="" name=""  multiline rows={4}  sx={{ width: '100%' }}
								value={jsonCode}
								onChange={(e) => {handleOnChangeJsonCode(e)}}
								
							/>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
			<Button onClick={handleAddNewQuestionSectionModalClose}>No</Button>
			<Button onClick={handleAddNewQuestionSection} > Yes</Button>
        </DialogActions>
      </Dialog>
	  
	  {/* notification alert bar*/}
	  {renderAlertBar()}

		{/* New */}
		{(pageMethod=='New') ?
		<>
		<h4>Title</h4>
		<TextField  margin="normal" required fullWidth id="title" label="Title" name="title"
			value={tempQuestionnaireData['title']}
			onChange={(e) => {handleOnChangeValue(e,'title')}}
		/>
	
			{/*<h4>Description</h4>
		<TextField  id="description"  label="Description" name="Description"  multiline rows={3}  sx={{ width: '100%' }}
			value={tempQuestionnaireData['description']}
			onChange={(e) => {handleOnChangeValue(e,'description')}}
		/>*/}
		
		<div style={{ width: '100%' }}>
			<Box sx={{ display: 'flex', flexDirection: 'row-reverse',  p: 1,  m: 1, borderRadius: 1, }} >	
				<Button onClick={(e) => handleSubmitQuestionnaire(e,'New')}  variant="contained" startIcon={<SaveIcon/>}>
				  Create
				</Button>
			</Box>
		</div>
	
		</>
		:<></>}
		
		{/* Edit */}
		{(pageMethod=='Edit') ?
		<>
			<div style={{ width: '100%' }}>
				<Box sx={{ display: 'flex', flexDirection: 'row-reverse',  p: 1,  m: 1, borderRadius: 1, }} >	
					{tempQuestionnaireData.published==false &&
					<>
					<Button onClick={(e) => handleSubmitQuestionnaire(e,'Publish')}  variant="contained" startIcon={<PublishIcon/>}>
					  Publish
					</Button>
					</>}
					&nbsp;
					<Button href={`/questionnaire/view/${tempQuestionnaireData._id}`}   variant="contained" startIcon={<PreviewIcon/>}>
					  Preview
					</Button>
				</Box> 
			</div>
		
			<h3>Title</h3>
			<TextField
				fullWidth
				id="standard-name"
				label=""
				value=""
				value={tempQuestionnaireData['title']}
				onChange={(e) => {handleOnChangeValue(e,'title')}}
				InputProps={{endAdornment: <Button variant="contained" onClick={(e) => handleSubmitQuestionnaire(e,'Edit')} >Update</Button>}}
			/>
	
			{/*<h4>Description</h4>
				<TextField  id="description"  label="Description" name="Description"  multiline rows={3}  sx={{ width: '100%' }}
					value={tempQuestionnaireData['description']}
					onChange={(e) => {handleOnChangeValue(e,'description')}}
				/>
				<div style={{ width: '100%' }}>
					<Box sx={{ display: 'flex', flexDirection: 'row-reverse',  p: 1,  m: 1, borderRadius: 1, }} >	
						<Button onClick={(e) => handleSubmitQuestionnaire(e,'Edit')}  variant="contained" startIcon={<SaveIcon/>}>
						  Update
						</Button>
						<br/>
					</Box>
				</div>*/}
		
			<br/>
			<br/>
			<h3>Questions and sections</h3>
			{tempQuestionnaireQuestionsAndSections.length==0 &&
			<>
				<h5>No questions</h5>			
			</>
			}
			{tempQuestionnaireQuestionsAndSections.length>0 &&
			<>
				<h5>{tempQuestionnaireQuestionsAndSections.length} question(s)/section(s) Found</h5>				
			</>}
			
			<Box sx={{ flexGrow: 1 }}>
				<Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
					{tempQuestionnaireQuestionsAndSections.map((row,index) => (
						<Grid item xs={12} sm={12} md={12} key={index}>
						<Item style={{ color: 'black', backgroundColor: '#ececec' ,'padding':'10px'}} >
						<h4 >Type: {row._type} </h4>
						
						{row._type=='Question' &&
						<>
							<h4>{index+1}) {row.question}</h4>
							{checkLoadingId(row._id) &&
							<>
								<TextField sx={{ m: 1, width: '95%'}}  margin="dense" required fullWidth id="heading" label="Heading" name="heading"
									value={tempQuestion['heading']}
									onChange={(e) => {handleOnChangeQuestionValue(e,'heading')}}
								/>
								<br/>
								<TextField sx={{ m: 1, width: '95%'}}  margin="dense" required fullWidth id="question" label="Question" name="question"
									value={tempQuestion['question']}
									onChange={(e) => {handleOnChangeQuestionValue(e,'question')}}
								/>
								<br/>
								<TextField sx={{ m: 1, width: '95%'}} margin="dense" fullWidth id="" label="Help Text" name=""
									value={tempQuestion['help_text']}
									onChange={(e) => {handleOnChangeQuestionValue(e,'help_text')}}
								/>
								<br/>
								<FormControl sx={{ m: 1, width: '95%'}}>
									  <InputLabel id="demo-select-large-label">Question Type</InputLabel>
									  <Select
										labelId="demo-select-small-label"
										id="demo-select-small"
										value={tempQuestion['type']}
										label="QuestionType"
										onChange={(e) => handleOnChangeQuestionType(e)}
									  >
									  {removeObjectByKey(questionTypes,['single_choice_text']).map((t,index) => (
										  <MenuItem key={index} value={t.id}>{t.name}</MenuItem>
									  ))}
									  </Select>
								</FormControl>
								<br/>
								
								{tempQuestion.type=='text' &&
								<>
									
								</>}
								
								{tempQuestion.type=='number' &&
								<>
									
								</>}
								
								{tempQuestion.type=='date' &&
								<>
									
								</>}
								
								{tempQuestion.type=='time' &&
								<>
									
								</>}
								{tempQuestion.type=='comment' &&
								<>
								
								</>}
								
								{tempQuestion.type=='single_choice' &&
								<>
									<br/>Quick select<br/>
									{questionTypesObject[tempQuestion.type].sub_types.map((sub_type,index) => (
									
									<>
										<Button size="small"  variant="contained"  onClick={(e) => handleAutoPopulateChoice(e,sub_type)} >
											{sub_type.name}
										</Button>&nbsp;
									</>
									))}
									<Button size="small"    onClick={(e) => handleClearChoice(e)} >
											Clear
										</Button>&nbsp;
								
									<br/><br/>
									{tempQuestionChoices.map((choice,index) => (
										<> {numberToLetter(index)})&nbsp;
										<TextField  inputProps={{ type: 'text'}} size="small"  margin="dense" id="" label="" name=""
												value={tempQuestionChoices[index]}
												onChange={(e) => {handleOnChangeQuestionChoices(e,index)}}
											/>
										<IconButton aria-label="remove" onClick={(e) => handleRemoveChoice(e,index)} > <ClearIcon sx={{color: "#ccc",fontSize: 16 }} /></IconButton>					<br/>
										</>
									))}
									<IconButton aria-label="add" onClick={(e) => handleAddChoice(e)} >
										<AddIcon sx={{color: "#ccc",fontSize: 16 }} />
									</IconButton>&nbsp;Add
									{/*
									<FormGroup>
										<FormControlLabel control={<Switch  checked={otherChoiceAdded} onChange={(e) => handleAddOtherChoice(e)}  />} label="Add 'Other' option with text entry" />
									</FormGroup>
									{otherChoiceAdded &&
										<> 
										
										<TextField  inputProps={{ type: 'text'}} size="small"  margin="dense" id="" label="" name=""
												value={otherChoiceText}
												onChange={(e) => {handleAddOtherChoiceText(e)}}
											/>
										</>
								}*/}
								</>}
								
								{tempQuestion.type=='multi_choice' &&
								<>
									<br/>
									{tempQuestionChoices.map((choice,index) => (
										<> {numberToLetter(index)})&nbsp;
										<TextField  inputProps={{ type: 'text'}} size="small"  margin="dense" id="" label="" name=""
												value={tempQuestionChoices[index]}
												onChange={(e) => {handleOnChangeQuestionChoices(e,index)}}
											/>
										<IconButton aria-label="remove" onClick={(e) => handleRemoveChoice(e,index)} > <ClearIcon sx={{color: "#ccc",fontSize: 16 }} /></IconButton>					<br/>
										</>
									))}
									<IconButton aria-label="add" onClick={(e) => handleAddChoice(e)} >
										<AddIcon sx={{color: "#ccc",fontSize: 16 }} />
									</IconButton>&nbsp;Add
								</>}
								
								{tempQuestion.type=='list' &&
									<>&nbsp;&nbsp;
									<TextField  inputProps={{ type: 'text'}} size="small"  margin="dense"  id="" label="List Name" name=""
										value={tempQuestion['list_name']}
										onChange={(e) => {handleOnChangeQuestionValue(e,'list_name')}}
									/>
									<br/>
									<TableContainer component={Paper}>
									  <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
										<TableHead>
										  <TableRow>
											<TableCell align="left">Title</TableCell>
											<TableCell align="left">Helptext</TableCell>
											<TableCell align="left"></TableCell>
										  </TableRow>
										</TableHead>
										<TableBody>
									
										  {tempQuestionFieldList.map((field_list,index) => (
											<TableRow
											  key={index}
											  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
											>
											  <TableCell align="left">
												<TextField  inputProps={{ type: 'text'}} size="small"  margin="dense" id="" label="" name=""
													value={field_list.field}
													onChange={(e) => {handleOnChangeQuestionFieldList(e,index,'field')}}
												/>
											  </TableCell>
											  <TableCell align="left">
													<TextField  inputProps={{ type: 'text'}} size="small"  margin="dense" id="" label="" name=""
													value={field_list.help_text}
													onChange={(e) => {handleOnChangeQuestionFieldList(e,index,'help_text')}}
													/>
													
											  </TableCell>
											  <TableCell align="left">
													
													<IconButton aria-label="remove" onClick={(e) => handleRemoveFieldList(e,index)} > <ClearIcon sx={{color: "#ccc",fontSize: 16 }} /></IconButton>					<br/>
										
											  </TableCell>
									
											</TableRow>
										  ))}
										</TableBody>
									  </Table>
									</TableContainer>
									
									<IconButton aria-label="add" onClick={(e) => handleAddFieldList(e)} >
										<AddIcon sx={{color: "#ccc",fontSize: 16 }} />
									</IconButton>&nbsp;Add
									
									<IconButton aria-label="add" onClick={(e) => handleAddOtherChoice(e)} >
										<AddIcon sx={{color: "#ccc",fontSize: 16 }} />
									</IconButton>&nbsp;Add Other option
								</>}
								{/* additional settings */}
								{questionTypesObject[tempQuestion.type].default_settings.length>0 &&
								 <div>
								  <Accordion>
									<AccordionSummary
									  expandIcon={<ExpandMoreIcon />}
									  aria-controls="panel1-content"
									  id="panel1-header"
									>
									<SettingsIcon sx={{color: "#ccc",fontSize: 18 }} /> &nbsp; Settings
									</AccordionSummary>
									<AccordionDetails>
									
									{questionTypesObject[tempQuestion.type].default_settings.map((_settings,index) => (
												<>
												<h5 key={index}>{_settings.name}</h5>
												<TextField  inputProps={{ type: _settings.type}} size="small"  margin="none" id="" label="" name=""
													value={tempQuestionSettings[_settings.id]}
													onChange={(e) => {handleOnChangeQuestionSettings(e,_settings.id)}}
												/>
												</>
										))}
									</AccordionDetails>
								  </Accordion>
								 
								</div>
								}
								<br/><br/>
								
								<Button id={row._id} size="small"  variant="contained"  onClick={(e) => handleCancelEdit(e,row)} >
									Cancel
								</Button>
								&nbsp;
								<Button id={row._id} size="small"  variant="contained"  onClick={(e) => handleSaveMode(e,row)} >
									Save
								</Button>
								</>
								} 
								{!checkLoadingId(row._id) &&
									<>
									<Button id={row._id} size="small"  variant="contained"  onClick={(e) => handleEditMode(e,row)} >
										Edit
									</Button>
									</>
								} 
								&nbsp;
								<Button id={row._id} size="small"  variant="contained"  onClick={(e) => handleDeleteQuestionModal(e,row)} >
									Delete
								</Button>
								{/*
								&nbsp;
								<Button id={row._id} size="small"  variant="contained"  onClick={(e) => handleDuplicateQuestion(e,row)} >
									Copy Question
								</Button>
								
								<Button id={row._id} size="small"  variant="contained"  onClick={(e) => handleMoveQuestion(e,row,'up')} >
									Move Up 
								</Button>
								<Button id={row._id} size="small"  variant="contained"  onClick={(e) => handleMoveQuestion(e,row,'down')} >
									Move Down 
								</Button>*/}
							
						</>
						}{/* end of question */}
					
					
						{row._type=='Section' &&
						<>
							<h4>{index+1}) {row.title}</h4>
							<h5>{row.sub_title}</h5>
							{checkSectionId(row._id) &&
							<>
							<TextField sx={{ m: 1, width: '95%'}}  margin="dense" fullWidth id="" label="Title" name=""
								value={tempQuestionSection['title']}
								onChange={(e) => {handleOnChangeQuestionSection(e,'title')}}
							/>
										
							<TextField sx={{ m: 1, width: '95%'}} margin="dense" fullWidth id="" label="Sub Title" name=""
								value={tempQuestionSection['sub_title']}
								onChange={(e) => {handleOnChangeQuestionSection(e,'sub_title')}}
							/>
										
							<TextField sx={{ m: 1, width: '95%'}}  margin="dense" fullWidth id="question" label="Help Text" name=""
								value={tempQuestionSection['help_text']}
								onChange={(e) => {handleOnChangeQuestionSection(e,'help_text')}}
							/>
										
							{/* display type of section */}
							<FormControl sx={{ m: 1, width: '95%'}}>
								<InputLabel id="demo-select-large-label">Display Type</InputLabel>
								<Select
									labelId="demo-select-small-label"
									id="demo-select-small"
									value={tempQuestionSection['display_type']}
									label="Display Type"
									onChange={(e) => handleOnChangeSectionDisplayType(e)}
									>
									{sectionDisplayTypes.map((t,index) => (
										<MenuItem key={t.id} value={t.id}>{t.name}</MenuItem>
									))}
								</Select>
							</FormControl>
										
							{/* question type of section */}
							<FormControl sx={{ m: 1, width: '95%'}}>
								<InputLabel id="demo-select-large-label">Question Type</InputLabel>
									<Select
										labelId="demo-select-small-label"
										id="demo-select-small"
										value={tempQuestionSection['question_type']}
										label="Question Type"
										onChange={(e) => handleOnChangeSectionQuestionType(e)}
									>
									{sectionQuestionTypes[tempQuestionSection['display_type']].map((t,index) => (
										<MenuItem key={t.id} value={t.id}>{t.name}</MenuItem>
									))}
									</Select>
							</FormControl>
							<br/>
							{tempQuestionSection['display_type']=='table'&&
							<>
							<br/>
								{tempQuestionSection['question_type']=='single_choice' &&
								<>
									{questionTypesObject[tempQuestionSection['question_type']].sub_types.map((sub_type,index) => (
										<span key={index}>
											<Button size="small"  variant="contained"  onClick={(e) => handleAutoPopulateQuestionSectionChoice(e,sub_type)} >
												{sub_type.name}
											</Button>&nbsp;
										</span>
									))}
									<Button size="small"    onClick={(e) => handleClearQuestionSectionChoice(e)} >
										Clear
									</Button>&nbsp;		
									<br/><br/>
									{tempQuestionSectionChoices.map((choice,index) => (
									<> {numberToLetter(index)})&nbsp;
										<TextField  inputProps={{ type: 'text'}} size="small"  margin="dense" id="" label="" name=""
											value={tempQuestionSectionChoices[index]}
											onChange={(e) => {handleOnChangeQuestionSectionChoices(e,index)}}
										/>
										<IconButton aria-label="remove" onClick={(e) => handleRemoveQuestionSectionChoice(e,index)} > <ClearIcon sx={{color: "#ccc",fontSize: 16 }} /></IconButton>					<br/>
									</>
									))}
									<IconButton aria-label="add" onClick={(e) => handleAddQuestionSectionChoice(e)} >
										<AddIcon sx={{color: "#ccc",fontSize: 16 }} />
									</IconButton>&nbsp;Add	
											
								</>}
								{tempQuestionSection['question_type']=='single_choice_text' &&
								<>
									{questionTypesObject[tempQuestionSection['question_type']].sub_types.map((sub_type,index) => (
										<span key={index}>
											<Button size="small"  variant="contained"  onClick={(e) => handleAutoPopulateQuestionSectionChoice(e,sub_type)} >
												{sub_type.name}
											</Button>&nbsp;
										</span>
									))}
									<Button size="small"    onClick={(e) => handleClearQuestionSectionChoice(e)} >
										Clear
									</Button>&nbsp;		
									<br/><br/>
									{tempQuestionSectionChoices.map((choice,index) => (
									<> {numberToLetter(index)})&nbsp;
										<TextField  inputProps={{ type: 'text'}} size="small"  margin="dense" id="" label="" name=""
											value={tempQuestionSectionChoices[index]}
											onChange={(e) => {handleOnChangeQuestionSectionChoices(e,index)}}
										/>
										<IconButton aria-label="remove" onClick={(e) => handleRemoveQuestionSectionChoice(e,index)} > <ClearIcon sx={{color: "#ccc",fontSize: 16 }} /></IconButton>					<br/>
									</>
									))}
									<IconButton aria-label="add" onClick={(e) => handleAddQuestionSectionChoice(e)} >
										<AddIcon sx={{color: "#ccc",fontSize: 16 }} />
									</IconButton>&nbsp;Add	
											
								</>}
								<br/>
								{/*List of questions */}
								{tempQuestionnaireSectionsQuestions[row.id].length==0 &&
								<>
									No questions			
								</>
								}
								{tempQuestionnaireSectionsQuestions[row.id].length>0 &&
								<>
									<h4>{tempQuestionnaireSectionsQuestions[row.id].length} question(s)</h4>
									
									{tempQuestionnaireSectionsQuestions[row.id].map((row,index) => (
											
										<div key={index} style={{ border: '1px solid #ccc', backgroundColor: '#ddd',padding:'10px',margin:'5px' }} >
											<b>{index+1}) {row.question}</b>
											<Box sx={{ color: 'text.secondary' }}>{row.help_text}</Box><br/>
											
											{checkLoadingId(row._id) &&
											<>
												<TextField sx={{ m: 1, width: '95%'}}  margin="dense" required fullWidth id="question" label="Question" name="question"
													value={tempQuestion['question']}
													onChange={(e) => {handleOnChangeQuestionValue(e,'question')}}
												/>
												
												<TextField sx={{ m: 1, width: '95%'}} margin="dense" fullWidth id="" label="Help Text" name=""
													value={tempQuestion['help_text']}
													onChange={(e) => {handleOnChangeQuestionValue(e,'help_text')}}
												/>
												
												<TextField sx={{ m: 1, width: '95%'}} margin="dense" fullWidth id="" label="Footnote" name=""
													value={tempQuestion['footnote']}
													onChange={(e) => {handleOnChangeQuestionValue(e,'footnote')}}
												/>
													<br/>
													<Button id={row._id} size="small"  variant="contained"  onClick={(e) => handleCancelEdit(e,row)} >
														Cancel
													</Button>
													&nbsp;
													<Button id={row._id} size="small"  variant="contained"  onClick={(e) => handleSaveMode(e,row)} >
														Save
													</Button>
											</>} 
											{row.sequence_number}
											{!checkLoadingId(row._id) &&
											<>
												<Button id={row._id} size="small"  variant="contained"  onClick={(e) => handleEditMode(e,row)} >
													Edit
												</Button>
											</>} 
											&nbsp;
											<Button id={row._id} size="small"  variant="contained"  onClick={(e) => handleDeleteQuestionModal(e,row)} >
												Delete
											</Button>
										</div>
									))}
								
								</>}
								
							</>}
							{tempQuestionSection['display_type']=='list'&&
							<> 
								{/*List of questions */}
								{tempQuestionnaireSectionsQuestions[row.id].length==0 &&
								<>
									No questions			
								</>
								}
								{tempQuestionnaireSectionsQuestions[row.id].length>0 &&
								<>
								<h4>{tempQuestionnaireSectionsQuestions[row.id].length} question(s)</h4>
								{tempQuestionnaireSectionsQuestions[row.id].map((row,index) => (
									
									<div key={index} style={{ border: '1px solid #ccc', backgroundColor: '#ddd',padding:'10px',margin:'5px' }} >
											<b>{index+1}) {row.question}</b>
											<Box sx={{ color: 'text.secondary' }}>{row.help_text}</Box><br/>
											
											{checkLoadingId(row._id) &&
											<>
												<TextField sx={{ m: 1, width: '95%'}}  margin="dense" required fullWidth id="heading" label="Heading" name="heading"
													value={tempQuestion['heading']}
													onChange={(e) => {handleOnChangeQuestionValue(e,'heading')}}
												/>
												
												<TextField sx={{ m: 1, width: '95%'}}  margin="dense" required fullWidth id="question" label="Question" name="question"
													value={tempQuestion['question']}
													onChange={(e) => {handleOnChangeQuestionValue(e,'question')}}
												/>
												
												<TextField sx={{ m: 1, width: '95%'}} margin="dense" fullWidth id="" label="Help Text" name=""
													value={tempQuestion['help_text']}
													onChange={(e) => {handleOnChangeQuestionValue(e,'help_text')}}
												/>
												<br/>
												
												<FormControl sx={{ m: 1, width: '95%'}}>
													<InputLabel id="demo-select-large-label">Question Type</InputLabel>
													<Select
														labelId="demo-select-small-label"
														id="demo-select-small"
														value={tempQuestion['type']}
														label="QuestionType"
														onChange={(e) => handleOnChangeQuestionType(e)}
													>
														{removeObjectByKey(questionTypes,['list','single_choice_text']).map((t,index) => (
															<MenuItem key={t.id} value={t.id}>{t.name}</MenuItem>
														))}
													</Select>
												</FormControl>
												<br/>
												{tempQuestion.type=='text' &&
												<>
													
												</>}
												{tempQuestion.type=='number' &&
												<>
													
												</>}
												{tempQuestion.type=='date' &&
												<>
													
												</>}
								
												{tempQuestion.type=='time' &&
												<>
													
												</>}
												{tempQuestion.type=='comment' &&
												<>
												
												</>}
								
												{tempQuestion.type=='single_choice' &&
												<>
													<br/>Quick select<br/>
													{questionTypesObject[tempQuestion.type].sub_types.map((sub_type,index) => (
														<>
															<Button size="small"  variant="contained"  onClick={(e) => handleAutoPopulateChoice(e,sub_type)} >
																{sub_type.name}
															</Button>&nbsp;
														</>
														))}
														<Button size="small"    onClick={(e) => handleClearChoice(e)} >
																Clear
															</Button>&nbsp;
													
														<br/><br/>
														{tempQuestionChoices.map((choice,index) => (
															<> {numberToLetter(index)})&nbsp;
															<TextField  inputProps={{ type: 'text'}} size="small"  margin="dense" id="" label="" name=""
																	value={tempQuestionChoices[index]}
																	onChange={(e) => {handleOnChangeQuestionChoices(e,index)}}
																/>
															<IconButton aria-label="remove" onClick={(e) => handleRemoveChoice(e,index)} > <ClearIcon sx={{color: "#ccc",fontSize: 16 }} /></IconButton>					<br/>
															</>
														))}
														<IconButton aria-label="add" onClick={(e) => handleAddChoice(e)} >
															<AddIcon sx={{color: "#ccc",fontSize: 16 }} />
														</IconButton>&nbsp;Add
														{/*
														<FormGroup>
															<FormControlLabel control={<Switch  checked={otherChoiceAdded} onChange={(e) => handleAddOtherChoice(e)}  />} label="Add 'Other' option with text entry" />
														</FormGroup>
														{otherChoiceAdded &&
															<> 
															
															<TextField  inputProps={{ type: 'text'}} size="small"  margin="dense" id="" label="" name=""
																	value={otherChoiceText}
																	onChange={(e) => {handleAddOtherChoiceText(e)}}
																/>
															</>
													}*/}
												</>}
								
												{tempQuestion.type=='multi_choice' &&
												<>
													<br/>
													{tempQuestionChoices.map((choice,index) => (
													<> {numberToLetter(index)})&nbsp;
														<TextField  inputProps={{ type: 'text'}} size="small"  margin="dense" id="" label="" name=""
															value={tempQuestionChoices[index]}
															onChange={(e) => {handleOnChangeQuestionChoices(e,index)}}
														/>
													<IconButton aria-label="remove" onClick={(e) => handleRemoveChoice(e,index)} > <ClearIcon sx={{color: "#ccc",fontSize: 16 }} /></IconButton>					<br/>
													</>
													))}
													<IconButton aria-label="add" onClick={(e) => handleAddChoice(e)} >
														<AddIcon sx={{color: "#ccc",fontSize: 16 }} />
													</IconButton>&nbsp;Add
												</>}
								
												{tempQuestion.type=='list' &&
													<>&nbsp;&nbsp;
													<TextField  inputProps={{ type: 'text'}} size="small"  margin="dense"  id="" label="List Name" name=""
														value={tempQuestion['list_name']}
														onChange={(e) => {handleOnChangeQuestionValue(e,'list_name')}}
													/>
													<br/>
													<TableContainer component={Paper}>
													  <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
														<TableHead>
														  <TableRow>
															<TableCell align="left">Title</TableCell>
															<TableCell align="left">Helptext</TableCell>
															<TableCell align="left"></TableCell>
														  </TableRow>
														</TableHead>
														<TableBody>
													
														  {tempQuestionFieldList.map((field_list,index) => (
															<TableRow
															  key={index}
															  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
															>
															  <TableCell align="left">
																<TextField  inputProps={{ type: 'text'}} size="small"  margin="dense" id="" label="" name=""
																	value={field_list.field}
																	onChange={(e) => {handleOnChangeQuestionFieldList(e,index,'field')}}
																/>
															  </TableCell>
															  <TableCell align="left">
																	<TextField  inputProps={{ type: 'text'}} size="small"  margin="dense" id="" label="" name=""
																	value={field_list.help_text}
																	onChange={(e) => {handleOnChangeQuestionFieldList(e,index,'help_text')}}
																	/>
																	
															  </TableCell>
															  <TableCell align="left">
																	
																	<IconButton aria-label="remove" onClick={(e) => handleRemoveFieldList(e,index)} > <ClearIcon sx={{color: "#ccc",fontSize: 16 }} /></IconButton>					<br/>
														
															  </TableCell>
													
															</TableRow>
														  ))}
														</TableBody>
													  </Table>
													</TableContainer>
													
													<IconButton aria-label="add" onClick={(e) => handleAddFieldList(e)} >
														<AddIcon sx={{color: "#ccc",fontSize: 16 }} />
													</IconButton>&nbsp;Add
													
													<IconButton aria-label="add" onClick={(e) => handleAddOtherChoice(e)} >
														<AddIcon sx={{color: "#ccc",fontSize: 16 }} />
													</IconButton>&nbsp;Add Other option
												</>}
												{/* additional settings */}
												{questionTypesObject[tempQuestion.type].default_settings.length>0 &&
												 <div>
												  <Accordion>
													<AccordionSummary
													  expandIcon={<ExpandMoreIcon />}
													  aria-controls="panel1-content"
													  id="panel1-header"
													>
													<SettingsIcon sx={{color: "#ccc",fontSize: 18 }} /> &nbsp; Settings
													</AccordionSummary>
													<AccordionDetails>
													
													{questionTypesObject[tempQuestion.type].default_settings.map((_settings,index) => (
																<>
																<h5 key={index}>{_settings.name}</h5>
																<TextField  inputProps={{ type: _settings.type}} size="small"  margin="none" id="" label="" name=""
																	value={tempQuestionSettings[_settings.id]}
																	onChange={(e) => {handleOnChangeQuestionSettings(e,_settings.id)}}
																/>
																</>
														))}
													</AccordionDetails>
												  </Accordion>
												 
												</div>
												}
												<br/><br/>
												<Button id={row._id} size="small"  variant="contained"  onClick={(e) => handleCancelEdit(e,row)} >
													Cancel
												</Button>
												&nbsp;
												<Button id={row._id} size="small"  variant="contained"  onClick={(e) => handleSaveMode(e,row)} >
													Save
												</Button>
											</>
											} 
											{!checkLoadingId(row._id) &&
											<>
												<Button id={row._id} size="small"  variant="contained"  onClick={(e) => handleEditMode(e,row)} >
													Edit
												</Button>
											</>
											} 
											&nbsp;
											<Button id={row._id} size="small"  variant="contained"  onClick={(e) => handleDeleteQuestionModal(e,row)} >
												Delete
											</Button>
										</div>
									))}
								</>
								}
							</>}
							<br/>
							<Button id={row._id} size="small"  variant="contained"  onClick={(e) => handleAddingNewQuestionToSelection(e,row)} >
								Add Question
							</Button>
							<br/>
						
							
							
						{/*List of questions */}
							{/*	{tempQuestionnaireSectionsQuestions[row.id].length==0 &&
						<>
							No questions			
						</>
						}
						{tempQuestionnaireSectionsQuestions[row.id].length>0 &&
						<>
							{tempQuestionnaireSectionsQuestions[row.id].length} question(s)
							
						
						{tempQuestionnaireSectionsQuestions[row.id].map((row,index) => (
											
							
							<div key={index} style={{ border: '1px solid #ccc', backgroundColor: '#ddd',padding:'10px',margin:'5px' }} >
							<h4>{index+1}) {row.question}</h4>
							
							{checkLoadingId(row._id) &&
							<>
								&nbsp;<TextField  margin="dense" required fullWidth id="question" label="Question" name="question"
									value={tempQuestion['question']}
									onChange={(e) => {handleOnChangeQuestionValue(e,'question')}}
								/>
								<br/>
								&nbsp;<TextField  margin="dense" fullWidth id="" label="Help Text" name=""
									value={tempQuestion['help_text']}
									onChange={(e) => {handleOnChangeQuestionValue(e,'help_text')}}
								/>
								<br/>
								<FormControl sx={{ m: 1, width: '50%'}}>
									  <InputLabel id="demo-select-large-label">Question Type</InputLabel>
									  <Select
										labelId="demo-select-small-label"
										id="demo-select-small"
										value={tempQuestion['type']}
										label="QuestionType"
										onChange={(e) => handleOnChangeQuestionType(e)}
									  >
									  {questionTypes.map((t,index) => (
										  <MenuItem key={t.id} value={t.id}>{t.name}</MenuItem>
									  ))}
									  </Select>
								</FormControl>
								<br/>
								
								{tempQuestion.type=='text' &&
								<>
									
								</>}
								
								{tempQuestion.type=='number' &&
								<>
									
								</>}
								
								{tempQuestion.type=='date' &&
								<>
									
								</>}
								
								{tempQuestion.type=='time' &&
								<>
									
								</>}
								{tempQuestion.type=='comment' &&
								<>
								
								</>}
								
								{tempQuestion.type=='single_choice' &&
								<>
									<br/>Quick select<br/>
									{questionTypesObject[tempQuestion.type].sub_types.map((sub_type,index) => (
									
									<>
										<Button size="small"  variant="contained"  onClick={(e) => handleAutoPopulateChoice(e,sub_type)} >
											{sub_type.name}
										</Button>&nbsp;
									</>
									))}
									<Button size="small"    onClick={(e) => handleClearChoice(e)} >
										Clear
									</Button>&nbsp;
								
									<br/><br/>
									{tempQuestionChoices.map((choice,index) => (
										<> {numberToLetter(index)})&nbsp;
										<TextField  inputProps={{ type: 'text'}} size="small"  margin="dense" id="" label="" name=""
												value={tempQuestionChoices[index]}
												onChange={(e) => {handleOnChangeQuestionChoices(e,index)}}
											/>
										<IconButton aria-label="remove" onClick={(e) => handleRemoveChoice(e,index)} > <ClearIcon sx={{color: "#ccc",fontSize: 16 }} /></IconButton>					<br/>
										</>
									))}
									<IconButton aria-label="add" onClick={(e) => handleAddChoice(e)} >
										<AddIcon sx={{color: "#ccc",fontSize: 16 }} />
									</IconButton>&nbsp;Add
									
									<IconButton aria-label="add" onClick={(e) => handleAddOtherChoice(e)} >
										<AddIcon sx={{color: "#ccc",fontSize: 16 }} />
									</IconButton>&nbsp;Add Other 
								</>}
								
								{tempQuestion.type=='single_choice_text' &&
								<>
									<br/>Quick select<br/>
									{questionTypesObject[tempQuestion.type].sub_types.map((sub_type,index) => (
									
									<>
										<Button size="small"  variant="contained"  onClick={(e) => handleAutoPopulateChoice(e,sub_type)} >
											{sub_type.name}
										</Button>&nbsp;
									</>
									))}
									<Button size="small"    onClick={(e) => handleClearChoice(e)} >
											Clear
										</Button>&nbsp;
								
									<br/><br/>
									{tempQuestionChoices.map((choice,index) => (
										<> {numberToLetter(index)})&nbsp;
										<TextField  inputProps={{ type: 'text'}} size="small"  margin="dense" id="" label="" name=""
												value={tempQuestionChoices[index]}
												onChange={(e) => {handleOnChangeQuestionChoices(e,index)}}
											/>
										<IconButton aria-label="remove" onClick={(e) => handleRemoveChoice(e,index)} > <ClearIcon sx={{color: "#ccc",fontSize: 16 }} /></IconButton>					<br/>
										</>
									))}
									<IconButton aria-label="add" onClick={(e) => handleAddChoice(e)} >
										<AddIcon sx={{color: "#ccc",fontSize: 16 }} />
									</IconButton>&nbsp;Add
									
									<IconButton aria-label="add" onClick={(e) => handleAddOtherChoice(e)} >
										<AddIcon sx={{color: "#ccc",fontSize: 16 }} />
									</IconButton>&nbsp;Add Other 
								</>}
								
								{tempQuestion.type=='multi_choice' &&
								<>
									<br/>
									{tempQuestionChoices.map((choice,index) => (
										<> {numberToLetter(index)})&nbsp;
										<TextField  inputProps={{ type: 'text'}} size="small"  margin="dense" id="" label="" name=""
												value={tempQuestionChoices[index]}
												onChange={(e) => {handleOnChangeQuestionChoices(e,index)}}
											/>
										<IconButton aria-label="remove" onClick={(e) => handleRemoveChoice(e,index)} > <ClearIcon sx={{color: "#ccc",fontSize: 16 }} /></IconButton>					<br/>
										</>
									))}
									<IconButton aria-label="add" onClick={(e) => handleAddChoice(e)} >
										<AddIcon sx={{color: "#ccc",fontSize: 16 }} />
									</IconButton>&nbsp;Add
									
									<IconButton aria-label="add" onClick={(e) => handleAddOtherChoice(e)} >
										<AddIcon sx={{color: "#ccc",fontSize: 16 }} />
									</IconButton>&nbsp;Add Other option
								</>}
								
								{tempQuestion.type=='list' &&
									<>&nbsp;&nbsp;
									<TextField  inputProps={{ type: 'text'}} size="small"  margin="dense"  id="" label="List Name" name=""
										value={tempQuestion['list_name']}
										onChange={(e) => {handleOnChangeQuestionValue(e,'list_name')}}
									/>
									<br/>
									<TableContainer component={Paper}>
									  <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
										<TableHead>
										  <TableRow>
											<TableCell align="left">Title</TableCell>
											<TableCell align="left">Helptext</TableCell>
											<TableCell align="left"></TableCell>
										  </TableRow>
										</TableHead>
										<TableBody>
									
										  {tempQuestionFieldList.map((field_list,index) => (
											<TableRow
											  key={index}
											  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
											>
											  <TableCell align="left">
												<TextField  inputProps={{ type: 'text'}} size="small"  margin="dense" id="" label="" name=""
													value={field_list.field}
													onChange={(e) => {handleOnChangeQuestionFieldList(e,index,'field')}}
												/>
											  </TableCell>
											  <TableCell align="left">
													<TextField  inputProps={{ type: 'text'}} size="small"  margin="dense" id="" label="" name=""
													value={field_list.help_text}
													onChange={(e) => {handleOnChangeQuestionFieldList(e,index,'help_text')}}
													/>
													
											  </TableCell>
											  <TableCell align="left">
													
													<IconButton aria-label="remove" onClick={(e) => handleRemoveFieldList(e,index)} > <ClearIcon sx={{color: "#ccc",fontSize: 16 }} /></IconButton>					<br/>
										
											  </TableCell>
									
											</TableRow>
										  ))}
										</TableBody>
									  </Table>
									</TableContainer>
									
									
									<IconButton aria-label="add" onClick={(e) => handleAddFieldList(e)} >
										<AddIcon sx={{color: "#ccc",fontSize: 16 }} />
									</IconButton>&nbsp;Add
										</>}
									
								
								
										<br/><br/>
										
										<Button id={row._id} size="small"  variant="contained"  onClick={(e) => handleCancelEdit(e,row)} >
											Cancel
										</Button>
										&nbsp;
										<Button id={row._id} size="small"  variant="contained"  onClick={(e) => handleSaveMode(e,row)} >
											Save
										</Button>
										</>
										} 
										{!checkLoadingId(row._id) &&
											<>
											<Button id={row._id} size="small"  variant="contained"  onClick={(e) => handleEditMode(e,row)} >
												Edit
											</Button>
											</>
										} 
											&nbsp;
											<Button id={row._id} size="small"  variant="contained"  onClick={(e) => handleDeleteQuestionModal(e,row)} >
												Delete
											</Button>
										</div>
						
					))}
										
										
										<br/>
										<br/>
										</>
							}
							
							
										<br/>
										{/* buttons  */}
										
										
										<br/>
										<Button id={row._id} size="small"  variant="contained"  onClick={(e) => handleCancelSectionMode(e,row)} >
											Cancel
										</Button>
										&nbsp;
										<Button  size="small"  variant="contained"  onClick={(e) => handleSaveSectionMode(e,row)} >
											Save
										</Button>
								</>}
							
								{/*  row not selected */}
								{!checkSectionId(row._id) &&
								<>
									<Button id={row._id} size="small"  variant="contained"  onClick={(e) => handleEditSectionMode(e,row)} >
										Edit
									</Button>
								</>
								}
								&nbsp;
								<Button id={row._id} size="small"  variant="contained"  onClick={(e) => handleDeleteQuestionSectionModal(e,row)} >
									Delete 
								</Button>
								{/*&nbsp;
								<Button id={row._id} size="small"  variant="contained"  onClick={(e) => handleDuplicateQuestionSection(e,row)} >
									Copy Section 
								</Button>
								<Button id={row._id} size="small"  variant="contained"  onClick={(e) => handleMoveQuestionSection(e,row,'up')} >
									Move Up 
								</Button>
								<Button id={row._id} size="small"  variant="contained"  onClick={(e) => handleMoveQuestionSection(e,row,'down')} >
									Move Down 
								</Button>*/}
							
							<br/>
							
							
							</>
						}{/* end of question section */}
						
					</Item>
				  </Grid>
			
		
		))}
		 </Grid>
				</Box>

		
		<br/>
		
			<Button onClick={(e) => handleAddingNewQuestion(e)}  variant="contained" startIcon={<AddIcon/>}>
				  add question
			</Button>
			&nbsp;&nbsp; Or &nbsp;&nbsp;
			<Button onClick={(e) => handleAddingNewQuestionSection(e)}  variant="contained" startIcon={<AddIcon/>}>
				  add section
			</Button>
			{devMode &&
			<>
			&nbsp;&nbsp;&nbsp;&nbsp;
			<Button onClick={(e) => handleAddingNewQuestionSectionModal(e)}  variant="contained" startIcon={<AddIcon/>}>
				  add section from json
			</Button>
			</>
			}
		<br/><br/><br/><br/><br/><br/><br/>
		</>
		:<></>}

		{/* View or preview */}
		{(pageMethod=='View') ?
		<>
		
		{/* View or take questionnaire */}
      <BootstrapDialog
        onClose={handleClientQuestionnaireClose}
        aria-labelledby="customized-dialog-title"
        open={openClientQuestionnaireModal}
		 fullWidth={true}
        maxWidth={'md'}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
		Questionnaire Complete
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClientQuestionnaireClose}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
		<Typography  gutterBottom>
		<>
		{/*{tempTestResponses.QuestionsAndResponses.map((qar,index) => (
		<>
		
		{index+1})	{qar.question.question}
		<br/>
		{formatJson(qar)}
		
		
		<br/>
		-----------------
		<br/>
		<br/>
		</>
		))}*/}
		</>
		</Typography>
		
        </DialogContent>
        <DialogActions>
			<Button onClick={handleClientQuestionnaireClose}>
				Close
			</Button>
        </DialogActions>
      </BootstrapDialog>
			{tempQuestionnaireData.published==false &&
			<>
				<div style={{ width: '100%' }}>
						<Box sx={{ display: 'flex', flexDirection: 'row-reverse',  p: 1,  m: 1, borderRadius: 1, }} >	
							<Button onClick={(e) => handleSubmitQuestionnaire(e,'View')}  variant="contained" startIcon={<EditIcon/>}>
							  Edit
							</Button>
						</Box>
					</div>
			</>}
			{/*<h4> Test Area </h4>
			<QuestionnaireComponent questionnaire={tempQuestionnaireData} questionsAndSections={tempQuestionnaireQuestionsAndSections} />
		<h4> End Test Area </h4>*/}
			
			
			<h3>{tempQuestionnaireData.title}</h3>
			{/*<p>{tempQuestionnaireData.description}</p>*/}
					
			<Box sx={{ flexGrow: 1 }}>
			  <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
				{tempQuestionnaireQuestionsAndSections.map((q,index) => (
				  <Grid   item xs={12} sm={12} md={12} key={index}>
					
					{/*QUESTION*/}
					{q._type=='Question' &&
					<>
					<Item sx={{ padding: '25px' }}   >
					<h3><b>{q.heading}</b></h3>
					<Box sx={{ color: 'text.primary' }}>{index+1}) {q.question}</Box>
					<Box sx={{ color: 'text.secondary' }}>&nbsp;&nbsp;&nbsp;&nbsp;{q.help_text}</Box>
					
						{/*text*/}
						{q.type=='text' &&
						<>
							<TextField  margin="normal" required fullWidth id="" label="" name=""
								value={tempResponses[q.id]}
								onChange={(e) => {handleOnChangeQuestionResponseValue(e,q)}}
							/>
						</>}
						{/*number*/}
						{q.type=='number' &&
						<>
							<TextField inputProps={{ type: 'number'}}  margin="normal" required fullWidth id="" label="" name=""
								value={tempResponses[q.id]}
								onChange={(e) => {handleOnChangeQuestionResponseValue(e,q)}}
							/>			
						</>}
						{/*time*/}
						{q.type=='time' &&
						<>
							<LocalizationProvider  dateAdapter={AdapterDayjs}>
							<DemoContainer sx={{ m: 1, width: '500px'}} components={['TimePicker']}>
								<TimePicker
								  name=""
								  label=""
								 // value={timeNow}
								  ampm={false}
								  onChange={(e) => {handleOnChangeQuestionResponseValue(e,q)}}
								/>
							  </DemoContainer>
							</LocalizationProvider>
						</>}
						{/*date*/}
						{q.type=='date' &&
						<>
							<LocalizationProvider  dateAdapter={AdapterDayjs}>
							<DemoContainer sx={{ m: 1, width: '500px'}} components={['DatePicker']}>
								<DatePicker 
								  name=""
								  label=""
								  format="DD/MM/YYYY"
								 // value={dateNow}
								 // ampm={false}
								  onChange={(e) => {handleOnChangeQuestionResponseValue(e,q)}}
								/>
							</DemoContainer>
							</LocalizationProvider>
						</>}
						{/*comment*/}
						{q.type=='comment' &&
							 <>			
							<TextField  id=""  label="" name=""  multiline rows={4}  sx={{ width: '100%' }}
								value={tempResponses[q.id]}
								onChange={(e) => {handleOnChangeQuestionResponseValue(e,q)}}
								inputProps={{ maxLength: q.settings.max_length }}
							/>
							{tempResponses[q.id][0] && <>
								{tempResponses[q.id][0].length}/{q.settings.max_length } Max char
							</>}
							{!tempResponses[q.id][0] && <>
								0/{q.settings.max_length } Max char
							</>} 						  
										
						</>}
						{/*single choice*/}						
						{q.type=='single_choice' &&
						<>
							<FormControl>
								<FormLabel id="demo-radio-buttons-group-label"></FormLabel>
								<RadioGroup
									aria-labelledby="demo-radio-buttons-group-label"
									//defaultValue="unknown"
									name="radio-buttons-group"
									onChange={(e) => {handleOnChangeQuestionResponseValue(e,q)}}
								>
								{q.choices.map((opt,index) => (
									<FormControlLabel key={index} value={opt} control={<Radio />} label={opt} />
								))}
								{q.other_choice_added &&
									<FormControlLabel value={q.other_choice_text} control={<Radio />} label={q.other_choice_text} />
								}
								</RadioGroup>
							</FormControl>
							<br/>
							{q.other_choice_added &&
								<TextField  inputProps={{ type: 'text'}} size="small"  margin="dense" id="" label="" name=""
									value={tempResponses[q.id]}
									onChange={(e) => {handleOnChangeQuestionResponseValue(e,q)}}
								/>
							}
						</>}
						{/*multi choice*/}
						{q.type=='multi_choice' &&
						<>
							<FormGroup
								onChange={(e) => {handleOnChangeQuestionResponseValue(e,q)}}
							>
								{q.choices.map((opt,index) => (
									<FormControlLabel key={index} value={opt} control={<Checkbox />} label={opt} />
								))}
							</FormGroup>
						</>}
						{/*list*/}
						{q.type=='list' &&
						<>
							<br/>
							{tempResponses[q.id].map((field_list,i) => (
							<>
									<TableContainer key={i} component={Paper}>
									  <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
										<TableHead>
										  <TableRow>
											<TableCell align="left">{q.list_name} {i+1}</TableCell>
											<TableCell align="right">
												 <Button variant="contained" onClick={(e) => handleRemoveListResponse(e,q,i)}  >
													Remove
												  </Button>
											</TableCell>
										
									
										  </TableRow>
										</TableHead>
										<TableBody>
									
										  {q.field_list.map((field_list,index) => (
											<TableRow
											  key={index}
											  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
											>
											  <TableCell align="left">
												<Box sx={{ color: 'text.primary' }}>{field_list.field}</Box>
												<Box sx={{ color: 'text.secondary' }}>{field_list.help_text}</Box>
											  </TableCell>
											  <TableCell align="left">
													<TextField  inputProps={{ type: 'text'}} size="small"  margin="dense" id="" label="" name=""
														value={tempResponses[q.id][i][field_list.field]}
														onChange={(e) => {handleOnChangeQuestionResponseValue(e,q,field_list.field,i)}}
													/>
											  </TableCell>
									
											</TableRow>
										  ))}
										</TableBody>
									  </Table>
									</TableContainer>
									<br/>
									</>
							))}
							<br/>
							<Button variant="contained" onClick={(e) => handleAddListResponse(e,q)}  >
								Add {q.list_name}
							</Button>
							  
						</>}
							
						</Item>
					</>}
					
					{/* Section */}
					{q._type=='Section' &&
					<>
						<Item sx={{ padding: '25px' }} >
						<Box sx={{ color: 'text.primary' }}>{index+1}) {q.title}</Box>
						<Box sx={{ color: 'text.secondary' }}>&nbsp;&nbsp;&nbsp;&nbsp;{q.help_text}</Box>
						
						{tempQuestionnaireSectionsQuestions[q.id].length==0 &&
						<>
							No questions
						</>}
						
						{tempQuestionnaireSectionsQuestions[q.id].length>0 &&
						<>
						{q.display_type=='table' &&
							<>
							<br/>
							{q.question_type=='single_choice_text' &&
								<TableContainer  component={Paper}>
								  <Table  sx={{ border: 1 }} size="small" aria-label="a dense table">
									<TableHead>
									  <TableRow  sx={{ border: 1 }}>
										<TableCell  sx={{ border: 1 }} align="center">{q.sub_title}</TableCell>
											{q.choices.map((opt,i) => (
												<TableCell key={i}  sx={{ border: 1 }} align="center">{opt}</TableCell>
											))}
							
									  </TableRow>
									</TableHead>
									<TableBody>
									  {tempQuestionnaireSectionsQuestions[q.id].map((sub_question,s) => (
										<TableRow  sx={{ border: 1 }} key={s}  >
										
										  <TableCell sx={{ border: 1 }} align="left">
											<Box sx={{ color: 'text.primary' }}>{sub_question.question}</Box>
												<Box sx={{ color: 'text.secondary' }}>{sub_question.help_text}</Box>
										  </TableCell>
										 
										{q.choices.map((opt,i) => (
													<TableCell  sx={{ border: 1 }} key={i}  align="center">
													  <TextField  margin="normal" required fullWidth id="" label="" name=""
													  value={tempResponses[sub_question.id][[opt]]}
															onChange={(e) => {handleOnChangeQuestionSectionResponseValue(e,q,sub_question,opt)}}
														/>
													</TableCell>
											  
											 ))}
										</TableRow>
										
										))}{/*end  sub questions */} 
											  
										
									</TableBody>
								  </Table>
								</TableContainer>
								
							}{/*text table */}
							
							{q.question_type=='single_choice' &&
							<>
								<TableContainer  component={Paper}>
								  <Table  sx={{ border: 1 }} size="small" aria-label="a dense table">
									<TableHead>
									  <TableRow  sx={{ border: 1 }}>
										<TableCell  sx={{ border: 1 }} align="center">{q.sub_title}</TableCell>
											{q.choices.map((opt,i) => (
											<TableCell key={i}  sx={{ border: 1 }} align="center">{opt}</TableCell>
										))}
							
									  </TableRow>
									</TableHead>
									<TableBody>
											  
									  {tempQuestionnaireSectionsQuestions[q.id].map((sub_question,s) => (
										<TableRow  sx={{ border: 1 }} key={s}  >
										
										  <TableCell sx={{ border: 1 }} align="left">
											<Box sx={{ color: 'text.primary' }}>{sub_question.question}</Box>
											<Box sx={{ color: 'text.secondary' }}>{sub_question.help_text}</Box>
										  </TableCell>
											  {q.choices.map((opt,i) => (
												  <TableCell  sx={{ border: 1 }} key={i}  align="center">
												  <input type="radio" value={opt} checked={tempResponses[sub_question.id]==opt} onChange={(e) => {handleOnChangeQuestionSectionResponseValue(e,q,sub_question)}} />
													  {/*	<FormControlLabel value={opt} control={<Radio />}    labelPlacement="bottom"  />
											  */} </TableCell>
											  
											 ))}
											 {/*  </RadioGroup>
									  </FormControl>*/}
										
										</TableRow>
										
										))}{/*end  sub questions */} 
											  
											
									  
									</TableBody>
								  </Table>
								</TableContainer>
								{tempQuestionnaireSectionsQuestions[q.id].map((sub_question,s) => (
								<>{sub_question.footnote}</>
								))}{/*end  sub questions */} 
								</>
							}{/*single choice table */}
						
						</>
						}
						
						{q.display_type=='list' &&
						<>
						{tempQuestionnaireSectionsQuestions[q.id].map((sub_question,s) => (
						<>
						<h4>{sub_question.heading}</h4>
						<Box sx={{ marginLeft:'25px' }} >{numberToLetter(s)}. {sub_question.question}
							<br/>
							{/*text*/}
							{sub_question.type=='text' &&
							<>
								<TextField  margin="normal" required fullWidth id="" label="" name=""
									value={tempResponses[sub_question.id]}
									onChange={(e) => {handleOnChangeQuestionResponseValue(e,sub_question)}}
								/>
							</>}
							{/*number*/}
							{sub_question.type=='number' &&
							<>
								<TextField inputProps={{ type: 'number'}}  margin="normal" required fullWidth id="" label="" name=""
									value={tempResponses[sub_question.id]}
									onChange={(e) => {handleOnChangeQuestionResponseValue(e,sub_question)}}
								/>			
							</>}
							{/*time*/}
							{sub_question.type=='time' &&
							<>
								<LocalizationProvider  dateAdapter={AdapterDayjs}>
								<DemoContainer sx={{ m: 1, width: '500px'}} components={['TimePicker']}>
									<TimePicker
									  name=""
									  label=""
									 // value={timeNow}
									  ampm={false}
									  onChange={(e) => {handleOnChangeQuestionResponseValue(e,sub_question)}}
									/>
								  </DemoContainer>
								</LocalizationProvider>
							</>}
							{/*date*/}
							{sub_question.type=='date' &&
							<>
								<LocalizationProvider  dateAdapter={AdapterDayjs}>
								<DemoContainer sx={{ m: 1, width: '500px'}} components={['DatePicker']}>
									<DatePicker 
									  name=""
									  label=""
									  format="DD/MM/YYYY"
									 // value={dateNow}
									 // ampm={false}
									  onChange={(e) => {handleOnChangeQuestionResponseValue(e,sub_question)}}
									/>
								</DemoContainer>
								</LocalizationProvider>
							</>}
							{/*comment*/}
							{sub_question.type=='comment' &&
								 <>			
								<TextField  id=""  label="" name=""  multiline rows={4}  sx={{ width: '100%' }}
									value={tempResponses[sub_question.id]}
									onChange={(e) => {handleOnChangeQuestionResponseValue(e,sub_question)}}
									inputProps={{ maxLength: sub_question.settings.max_length }}
								/>
								{tempResponses[sub_question.id][0] && <>
									{tempResponses[sub_question.id][0].length}/{sub_question.settings.max_length } Max char
								</>}
								{!tempResponses[sub_question.id][0] && <>
									0/{sub_question.settings.max_length } Max char
								</>} 						  
											
							</>}
							{/*single choice*/}						
							{sub_question.type=='single_choice' &&
							<>
								<FormControl>
									<FormLabel id="demo-radio-buttons-group-label"></FormLabel>
									<RadioGroup
										aria-labelledby="demo-radio-buttons-group-label"
										//defaultValue="unknown"
										name="radio-buttons-group"
										onChange={(e) => {handleOnChangeQuestionResponseValue(e,sub_question)}}
									>
									{sub_question.choices.map((opt,index) => (
										<FormControlLabel key={index} value={opt} control={<Radio />} label={opt} />
									))}
									{sub_question.other_choice_added &&
										<FormControlLabel value={sub_question.other_choice_text} control={<Radio />} label={sub_question.other_choice_text} />
									}
									</RadioGroup>
								</FormControl>
								<br/>
								{sub_question.other_choice_added &&
									<TextField  inputProps={{ type: 'text'}} size="small"  margin="dense" id="" label="" name=""
										value={tempResponses[sub_question.id]}
										onChange={(e) => {handleOnChangeQuestionResponseValue(e,sub_question)}}
									/>
								}
							</>}
							{/*multi choice*/}
							{sub_question.type=='multi_choice' &&
							<>
								<FormGroup
									onChange={(e) => {handleOnChangeQuestionResponseValue(e,sub_question)}}
								>
									{sub_question.choices.map((opt,index) => (
										<FormControlLabel key={index} value={opt} control={<Checkbox />} label={opt} />
									))}
								</FormGroup>
							</>}
						</Box>
						</>
						))}
						</>
						}
						
						</>}
					
						<br/>
						
						</Item>
					</>}
					
					
					{/*<Item>{index+1}) {q.question}
					<br/>
							 {q.type=='text' &&
			  <>
				<TextField  margin="normal" required fullWidth id="" label="" name=""
					value={tempResponses[q.id]}
					onChange={(e) => {handleOnChangeResponseValue(e,q)}}
				/>
			  </>}
			  
			  {q.type=='number' &&
			  <>
				<TextField inputProps={{ type: 'number'}}  margin="normal" required fullWidth id="" label="" name=""
					value={tempResponses[q.id]}
					onChange={(e) => {handleOnChangeResponseValue(e,q)}}
				/>			
			  </>}
			  
			  {q.type=='time' &&
			  <>
				 <LocalizationProvider  dateAdapter={AdapterDayjs}>
				  <DemoContainer sx={{ m: 1, width: '500px'}} components={['TimePicker']}>
					<TimePicker
					  name=""
					  label=""
					  value={timeNow}
					  ampm={false}
					  onChange={(e) => {handleOnChangeResponseValue(e,q)}}
					/>
				  </DemoContainer>
				</LocalizationProvider>
				
				</>}
				
				{q.type=='date' &&
			  <>
				 <LocalizationProvider  dateAdapter={AdapterDayjs}>
				  <DemoContainer sx={{ m: 1, width: '500px'}} components={['DatePicker']}>
					<DatePicker 
					  name=""
					  label=""
					  format="DD/MM/YYYY"
					  value={dateNow}
					  ampm={false}
					  onChange={(e) => {handleOnChangeResponseValue(e,q)}}
					/>
				  </DemoContainer>
				</LocalizationProvider>
				
				</>}
				
				 {q.type=='comment' &&
				 <>			
				<TextField  id=""  label="" name=""  multiline rows={4}  sx={{ width: '100%' }}
					value={tempResponses[q.id]}
					onChange={(e) => {handleOnChangeResponseValue(e,q)}}
					inputProps={{ maxlength: q.settings.max_length }}
				/>
				{tempResponses[q.id][0] && <>
					{tempResponses[q.id][0].length}/{q.settings.max_length } Max char
				</>}
				{!tempResponses[q.id][0] && <>
					0/{q.settings.max_length } Max char
				</>} 						  
							
				</>}
			  {q.type=='single_choice' &&
			  <>
			  <FormControl>
				  <FormLabel id="demo-radio-buttons-group-label"></FormLabel>
				  <RadioGroup
					aria-labelledby="demo-radio-buttons-group-label"
					//defaultValue="unknown"
					name="radio-buttons-group"
					onChange={(e) => {handleOnChangeResponseValue(e,q)}}
				  >
				  {q.choices.map((opt,index) => (
					<FormControlLabel value={opt} control={<Radio />} label={opt} />
				  ))}
				  </RadioGroup>
				</FormControl>
				</>}
				
			{q.type=='multi_choice' &&
			  <>
				<FormGroup
					onChange={(e) => {handleOnChangeResponseValue(e,q)}}
				>
				{q.choices.map((opt,index) => (
				  <FormControlLabel value={opt} control={<Checkbox />} label={opt} />
				   ))}
				</FormGroup>
			</>}
			
			{q.type=='list' &&
			  <>
			  <br/>
							{tempResponses[q.id].map((field_list,i) => (
							<>
								<TableContainer key={i} component={Paper}>
								  <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
									<TableHead>
									  <TableRow>
										<TableCell align="left">{q.list_name} {i+1}</TableCell>
										<TableCell align="right">
											 <Button variant="contained" onClick={(e) => handleRemoveListResponse(e,q,i)}  >
												Remove
											  </Button>
										</TableCell>
									
								
									  </TableRow>
									</TableHead>
									<TableBody>
								
									  {q.field_list.map((field_list,index) => (
										<TableRow
										  key={index}
										  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
										>
										  <TableCell align="left">
											<Box sx={{ color: 'text.primary' }}>{field_list.field}</Box>
											<Box sx={{ color: 'text.secondary' }}>{field_list.help_text}</Box>
										  </TableCell>
										  <TableCell align="left">
												<TextField  inputProps={{ type: 'text'}} size="small"  margin="dense" id="" label="" name=""
												value={tempResponses[q.id][i][field_list.field]}
												onChange={(e) => {handleOnChangeResponseValue(e,q,field_list.field,i)}}
												/>
												
										  </TableCell>
								
										</TableRow>
									  ))}
									</TableBody>
								  </Table>
								</TableContainer>
								<br/>
								</>
							))}
							<br/>
							<Button variant="contained" onClick={(e) => handleAddListResponse(e,q)}  >
							Add {q.list_name}
						  </Button>
							
							
			  
						</>}
						</Item>
				*/}
				  </Grid>
				))}
			  </Grid>
			</Box>
			<br/>
			 <Button variant="contained" onClick={(e) => handleViewQuesionnaireSubmit(e)}  >
				Submit
			  </Button>
			   &nbsp;
			   <Button variant="contained" onClick={(e) => handleTestSubmit(e)}  >
				Test Client Submit
			  </Button>

		</>
		:<></>}

		{/* Clone */}
		{(pageMethod=='Clone') ?
		<>
		
		<h4>Title</h4>
		<TextField  margin="normal" required fullWidth id="title" label="Title" name="title"
			value={tempQuestionnaireData['title']}
			onChange={(e) => {handleOnChangeValue(e,'title')}}
		/>
	
			{/*<h4>Description</h4>
		<TextField  id="description"  label="Description" name="Description"  multiline rows={3}  sx={{ width: '100%' }}
			value={tempQuestionnaireData['description']}
			onChange={(e) => {handleOnChangeValue(e,'description')}}
		/>*/}
		
		<div style={{ width: '100%' }}>
			<Box sx={{ display: 'flex', flexDirection: 'row-reverse',  p: 1,  m: 1, borderRadius: 1, }} >	
				<Button onClick={(e) => handleSubmitQuestionnaire(e,'Clone')}  variant="contained" startIcon={<SaveIcon/>}>
				  Clone
				</Button>
			</Box>
		</div>


		</>
		:<></>}
	

		
	<br/>
	</>
	:<></>}
	
	{/* Researcher */}
	{(currentUser.__t=='Researcher') ?
	<>
	
	</>
	:<></>}

	</AppLogout>
	</>
  ) 
  ;
};

export default Questionnaire;