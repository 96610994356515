import {useEffect,useState,useRef} from 'react';
import { useNavigate } from "react-router-dom";

import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TopPrivateNavigation from '../components/TopPrivateNavigation.jsx'
import AppLogout from '../components/AppLogout.jsx'
import ImportExportIcon from '@mui/icons-material/ImportExport';
import Box from '@mui/material/Box';

import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import PreviewIcon from '@mui/icons-material/Preview';
import TableRowsOutlinedIcon from '@mui/icons-material/TableRowsOutlined';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import CircleIcon from '@mui/icons-material/Circle';
import UploadIcon from '@mui/icons-material/Upload';
import TextField from '@mui/material/TextField';
import LinearProgress from '@mui/material/LinearProgress';

import { Fragment } from "react";


import Badge from '@mui/material/Badge';
import MailIcon from '@mui/icons-material/Mail';

//services
import AuthService from '../service/auth'
import ProjectService from '../service/Project'
import QuestionnaireService from '../service/Questionnaire'
import ClientQuestionnaireService from '../service/ClientQuestionnaire'
import ExportsService from '../service/Exports'
import UploadService from '../service/Upload'
import DataBackUpService from '../service/DataBackUp'

const formatJson=function(obj){
	return JSON.stringify(obj);
}

const formatDate=function(d){
	var d = new Date(d);
	Number.prototype.padLeft = function(base,chr){
    var  len = (String(base || 10).length - String(this).length)+1;
    return len > 0? new Array(len).join(chr || '0')+this : this;
}
    var dformat = [
               d.getDate().padLeft(),
			   (d.getMonth()+1).padLeft(),
               d.getFullYear()].join('/') +' ' +
              [d.getHours().padLeft(),
               d.getMinutes().padLeft()].join(':');
	return dformat;
}

function ControlBox({ questionnaire }) {

  return (
    <Fragment>
	{formatJson(questionnaire)}
    </Fragment>
  );
}

const Questionnaires = () => {
	
	const navigate = useNavigate();
	//protected page check if logged in
	const [currentUser,setCurrentUser]=useState({});
	const [projectData,setProjectData]=useState([]);
	const [projectFound,setProjectFound]=useState(false);
	
	//let params = useParams();
	let project_id =ProjectService.getCurrentProject();//params.id;
	console.log('project_id',project_id);
		
	useEffect(() => {
		if(!AuthService.isUserLoggedIn()){
			// not logged in navigate back to login
			navigate("/login");
		}else{
				setCurrentUser(AuthService.getCurrentUser());
				var account_type=AuthService.getCurrentUser().__t;
				//console.log('account_type',account_type);
				
				//console.log(AuthService.getCurrentUser());
				var currentUser=AuthService.getCurrentUser();
				//expired Account
				if(currentUser.expired){
					var string = "account_type="+AuthService.getCurrentUser().__t+"&type=expired";
					//console.log('string',string);
					var encodedString = btoa(string); // Base64 encode the String
					//console.log('encodedString',encodedString);
					navigate("/restricted/"+encodedString);
					
				}else{//unverified account
					if(!currentUser.email_verified){
						var string = "account_type="+AuthService.getCurrentUser().__t+"&type=emaiverification";
						//console.log('string',string);
						var encodedString = btoa(string); // Base64 encode the String
						//console.log('encodedString',encodedString);
						navigate("/restricted/"+encodedString);
						
					}else{////force change Password
						if(currentUser.force_password_change){
							//not implemented	
						
						}else{
							//load project if set 
							if((typeof project_id !=="undefined")&&(project_id!==null)){//project filter
								loadProjectById(project_id);
							}
							//load
							if(account_type=='Admin'){//Admin
						
							}else if(account_type=='Master'){//Master
								
								loadQuestionnaires();
								
							}else if(account_type=='Researcher'){//Researcher
								
							}
						}
					}
				}	
		}
	}, []);
	
	//load project 
	function loadProjectById(project_id){
		ProjectService.getById(project_id).then(function(res){
		setProjectData(res.data);
		console.log(res.data);
		setProjectFound(true);
		}).catch(function(error){
			console.log(error);
			setProjectFound(false);
		});
	}
	
	//load questionnaires
	const [questionnaireData,setQuestionnaireData]=useState([]);
	function loadQuestionnaires(){
		var questionnaireFilter ={'global':true}
		if((typeof project_id !=="undefined")&&(project_id!==null)){//project filter
			questionnaireFilter ={'global':false,'project':project_id}
		}
		//questionnaireFilter.active=true;
		console.log(questionnaireFilter);
		QuestionnaireService.getByFilter(questionnaireFilter).then(function(res){
			setQuestionnaireData(res.data);
			console.log(res.data);
			let _questionnaires= res.data;
			let _array={};
			//init client & test responses
			for(let i=0;i<_questionnaires.length;i++){
				console.log('_questionnaires',_questionnaires[i].id);
				_array[_questionnaires[i].id]={'total':0};
				loadQuestionnaireResponses(_questionnaires[i].id);
				loadQuestionnaireTestResponses(_questionnaires[i].id);
			}
			setQuestionnaireTestResponses(_array);
			setQuestionnaireResponses(_array);
		}).catch(function(error){
			console.log(error);
		});
	}
	
	function loadQuestionnaireResponses(id){
		console.log('id',id);
		ClientQuestionnaireService.getByFilter({'questionnaire':id,'test':false}).then(function(res){
			console.log(res.data);
			let _responses=res.data;
			if(_responses.length>0){
				let _questionnaireId=_responses[0].questionnaire;
				setQuestionnaireResponses(questionnaireResponses => ({
					...questionnaireResponses,
					[_questionnaireId]: {'total':_responses.length},
				}))
			}
		}).catch(function(error){
			console.log(error);
		});
	}
	
	function loadQuestionnaireTestResponses(id){
		console.log('id',id);
		ClientQuestionnaireService.getByFilter({'questionnaire':id,'test':true}).then(function(res){
			console.log(res.data);
			let _responses=res.data;
			if(_responses.length>0){
				let _questionnaireId=_responses[0].questionnaire;
				setQuestionnaireTestResponses(questionnaireTestResponses => ({
					...questionnaireTestResponses,
					[_questionnaireId]: {'total':_responses.length},
				}))
			}
		}).catch(function(error){
			console.log(error);
		});
	}
	
	/*switch view*/
	const [dataView, setDataView] = useState('table');
	const handleSwitchView = (e,view) => {
			setDataView(view)
	};
	
	/* sort fields */
	const [sortByField, setSortByField] = useState('date');
	const [sortType, setSortType] = useState('asc');//desc
	
	const handleSortQuestionnaires = (e,sortByField) => {
	   console.log('default','sortByField',sortByField,'sortType',sortType);
	   let sorted;
	   
	   //field
	   if(sortByField=='date'){
		   console.log('sort by date.....');
		  		 if(sortType=='asc'){
		    sorted = [...questionnaireData].sort((a, b) => {
			   var keyA = new Date(a.last_updated),
					keyB = new Date(b.last_updated);
				  if (keyA < keyB) return -1;
				  if (keyA> keyB) return 1;
				  return 0;
			});
		   
		   setSortType('desc');
		}else if(sortType=='desc'){
		      sorted = [...questionnaireData].sort((a, b) => {
			    var keyA = new Date(a.last_updated),
					keyB = new Date(b.last_updated);
				  if (keyA > keyB) return -1;
				  if (keyA< keyB) return 1;
				  return 0;
				});
				   setSortType('asc');
		}
	   }else if(sortByField=='title'){
		  console.log('sort by title.....');
		 if(sortType=='asc'){
		    sorted = [...questionnaireData].sort((a, b) => {
			   var keyA = a.title,
					keyB = b.title;
				  if (keyA < keyB) return -1;
				  if (keyA> keyB) return 1;
				  return 0;
			});
		   
		   setSortType('desc');
		}else if(sortType=='desc'){
		      sorted = [...questionnaireData].sort((a, b) => {
			   var keyA = a.title,
					keyB = b.title;
				  if (keyA > keyB) return -1;
				  if (keyA< keyB) return 1;
				  return 0;
				});
				   setSortType('asc');
		}
	   }else{
		  /// console.log('dont know what to sort by.....');
	   }

    setQuestionnaireData(sorted);
  };
  
	/*end sort fields */
  
  	/*Import questionnaire modal*/
	const [selectedFile, setSelectedFile] = useState(null);
	const [loadingImport, setLoadingImport] = useState(false);
	const [errorImport, setErrorImport] = useState(null);
	const [successImport, setSuccessImport] = useState(null);
	const [openImportQuestionnaireModal, setOpenImportQuestionnaireModal] = useState(false);

	const handleImportQuestionnaireOpenModal = (e) => {
		setSelectedFile(null);
		setErrorImport(null);
		setSuccessImport(null);
		setOpenImportQuestionnaireModal(true);
	};

	const handleImportQuestionnaireCloseModal = () => {
		setOpenImportQuestionnaireModal(false);
	};

	const handleFileChange = (event) => {
		const file = event.target.files[0];
		let tableName='Questionnaire';
		setSelectedFile(file);
		
		if (file) {
			setLoadingImport(true);
		  UploadService.uploadDataFile({'file':file}).then(function(res){
				console.log(res.data);
				//setErrorImport("Success: File uploaded.");
				var fileObject =res.data;
					//create back up record
					var obj ={'global':true,'project':project_id,file:fileObject._id,'table_name':tableName,'description':tableName+' items back up data file'};
					if((typeof project_id !=="undefined")&&(project_id!==null)){//project filter
						obj ={'global':false,'project':project_id,file:fileObject._id,'table_name':tableName,'description':tableName+' items back up data file '};
					}
					DataBackUpService.create(obj).then(function(res1){
					console.log(res1.data);
						//loadDataBackUps();
						//setSuccessImport("Success:");
						let _backUpData=res1.data;
						DataBackUpService.validate(_backUpData).then(function(res2){
							console.log(res2.data);
							setTimeout(function(){
								setSuccessImport("Success: you have successfully imported questionnaire ");
								setLoadingImport(false);
								loadQuestionnaires();
							}, 3000);
							
						}).catch(function(error2){
							console.log(error2);
							setErrorImport("Error: "+error2.response.data.message);
							setLoadingImport(false);
						});
						
					}).catch(function(error1){
							console.log(error1);
							setErrorImport("Error: "+error1.response.data.message);
							setLoadingImport(false);
					});
				
			}).catch(function(error){
					console.log(error);
					setErrorImport("Error: "+error.response.data.message);
					setLoadingImport(false);
			});
		}
	};
	  
  
  /*delete questionnaire modal*/
  const [openDeleteQuestionnaireModal, setOpenDeleteQuestionnaireModal] = useState(false);
  const [tempQuestionnaire, setTempQuestionnaire] = useState({});

  const handleDeleteQuestionnaireModal = (e,questionnaire) => {
	  setTempQuestionnaire(questionnaire);
	  console.log('questionnaire',questionnaire);
		setOpenDeleteQuestionnaireModal(true);
	  };

	  const handleDeleteClose = () => {
		setOpenDeleteQuestionnaireModal(false);
	  };
	  
	  const handleDeleteQuestionnaire=()=>{
		//setOpenDeleteQuestionnaireModal(false);
		console.log('tempQuestionnaire',tempQuestionnaire);
		//tempQuestionnaire.active=false;
		QuestionnaireService.deleteById(tempQuestionnaire.id).then(function(res){
		//setQuestionnaireData(res.data);
		console.log(res.data);
		setOpenDeleteQuestionnaireModal(false);
		loadQuestionnaires();
		}).catch(function(error){
			console.log(error);
		});
	  };
	  
	/*Clone questionnaire modal*/
	const [openCloneQuestionnaireModal, setOpenCloneQuestionnaireModal] = useState(false);

  const handleCloneQuestionnaireModal = (e,questionnaire) => {
	  setTempQuestionnaire(questionnaire);
	  console.log('questionnaire',questionnaire);
		setOpenCloneQuestionnaireModal(true);
	  };

	  const handleCloneClose = () => {
		setOpenCloneQuestionnaireModal(false);
	  };
	  
	  const handleCloneQuestionnaire=()=>{//deactivate not delete
		//setOpenDeleteQuestionnaireModal(false);
		console.log('tempQuestionnaire',tempQuestionnaire);
		//tempQuestionnaire.active=false;
		QuestionnaireService.clone(tempQuestionnaire).then(function(res){
		//setQuestionnaireData(res.data);
		console.log(res.data);
		setOpenCloneQuestionnaireModal(false);
		loadQuestionnaires();
		}).catch(function(error){
			console.log(error);
		});
	  };
	  
	  const handleOnChangeValue = (e,key) => {
		console.log(e.target.value,key);
		setTempQuestionnaire({...tempQuestionnaire,
			[key]: e.target.value,
		})
	};
	
	/*update questionnaire */
	const [updateQuestionnaireMessage, setUpdateQuestionnaireMessage] = useState({"message":""});
	const handleUpdateQuestionnaire=(e,questionnaire)=>{
		setTempQuestionnaire(questionnaire);
		console.log('tempQuestionnaire',tempQuestionnaire);
		if(questionnaire.active){
			questionnaire.active=false;
		}else{
			questionnaire.active=true;
		}
		QuestionnaireService.update(questionnaire).then(function(res){
			console.log(res.data);
			loadQuestionnaires();
		}).catch(function(error){
			console.log(error);
		});
	};
	  
	const [questionnaireResponses,setQuestionnaireResponses]=useState({});
	const [questionnaireTestResponses,setQuestionnaireTestResponses]=useState({});

	const [downloadUrl,setDownloadUrl]=useState("");
	const stateRef = useRef();
	stateRef.loaded = false;
 
	 const handleDownloadQuestionnaireResponses = (e,questionnaire,test) => {
		 console.log('questionnaire:',questionnaire);
		 
		  var _filter={'sub_type':'questionnaire_responses','questionnaire':questionnaire._id};
		  _filter.test=test;
		 ExportsService.build('client_questionnaires',_filter).then(function(res){
			console.log(res.data);
			
			if(res.data.found){
				var filename =res.data.filename;
				var url=ExportsService.getUrl(filename);
				console.log('url',url);
				setDownloadUrl(url);
				
				var counter=0;
				var refreshIntervalId = setInterval(function(){
				checkIfBuilt(filename);
				if(stateRef.loaded){
					clearInterval(refreshIntervalId);
					window.open(url,'_blank');}
					if(counter==20){
						clearInterval(refreshIntervalId);
					}
					counter++;
				}, 1000);
			}else{
				console.log('Not able to download...');
			}
			
						
		}).catch(function(error){
				console.log(error);
		});
		 
	 }
	 
	function checkIfBuilt(filename){
		  	ExportsService.exists(filename).then(function(res){
			if(res.data.exists){
				stateRef.loaded=true;
			}
		}).catch(function(error){
				console.log(error);
		});
	 }
	 //export as xlsx
	 const handleDownloadQuestionnaireXlsx = (e,questionnaire) => {
		 QuestionnaireService.exportXlsx(questionnaire._id).then(function(res){
			console.log(res.data);
			var filename =res.data.filename;
				var url=QuestionnaireService.getUrl(filename);
				console.log('url',url);
				setDownloadUrl(url);
				
				var counter=0;
				var refreshIntervalId = setInterval(function(){
				checkIfBuiltXlsx(filename);
				if(stateRef.loaded){
					clearInterval(refreshIntervalId);
					window.open(url,'_blank');}
					if(counter==20){
						clearInterval(refreshIntervalId);
					}
					counter++;
				}, 1000);
		}).catch(function(error){
				console.log(error);
		});
		 
	 }
	 
	 function checkIfBuiltXlsx(filename){
		  	QuestionnaireService.existsXlsx(filename).then(function(res){
			if(res.data.exists){
				stateRef.loaded=true;
			}
		}).catch(function(error){
				console.log(error);
		});
	 }
	
  return(
  <>
	<TopPrivateNavigation/>
	<AppLogout>
	
		{/* error no project found */}
	{(!projectFound) ?
		<>
		
		<br/>
		<Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" href="/">
          Home
        </Link>
		 <Link
          underline="hover"
          color="inherit"
          href={`/Questionnaires`}
        >
          Questionnaires
        </Link>
      </Breadcrumbs>
	  
	  <br/>
	  <Divider />
		<br/>
		
		
		</>
	:<></>}
	{/* if project is found */}
	{(projectFound) ?
	<>
		<br/>
		<Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" href="/">
          Home
        </Link>
        <Link
          underline="hover"
          color="inherit"
          href="/projects"
        >
          Projects
        </Link>
		<Link
          underline="hover"
          color="inherit"
          href={`/project/${projectData._id}`}
        >
          {projectData.name}
        </Link>
		<Link
          underline="hover"
          color="inherit"
          href={`/Questionnaires`}
        >
          Questionnaires
        </Link>
      </Breadcrumbs>
	  
	  <br/>
	  <Divider />
		<br/>
	
	</>
	:<></>}
	
	{/* Admin */}
	{(currentUser.__t=='Admin') ?
	<>
	
	</>
	:<></>}
	
	{/* Master */}
	{(currentUser.__t=='Master') ?
	<>
		<div style={{ width: '100%' }}>
			<Box
				sx={{
				  display: 'flex',
				  justifyContent: 'space-between',
				  p: 1,
				  m: 1,
			  //    bgcolor: 'background.paper',
				  borderRadius: 1,
				}}
			>
				<Button href={`/questionnaire/New`}  variant="contained" endIcon={<AddIcon />}  >
					New Questionnaire 
				</Button>
				<Button onClick={(e) => handleImportQuestionnaireOpenModal(e)} variant="outlined" endIcon={<UploadIcon />} >
					Import Questionnaire
				</Button>
			</Box>
		</div>
		
		{/*START import questionnaire dialog */}
		<Dialog
			open={openImportQuestionnaireModal}
			onClose={handleImportQuestionnaireCloseModal}
			//aria-labelledby="alert-dialog-title"
			//aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">
			  {"Import Questionnaire"}
			</DialogTitle>
			<DialogContent>
			  <DialogContentText id="alert-dialog-description">
				  <input
					type="file"
					onChange={handleFileChange}
					style={{ display: "none" }}
					id="file-input"
				  />
				  <label htmlFor="file-input">
					<Button variant="outlined" component="span">
					  Select File (*.json only)
					</Button>
				  </label>
				  <br/>
				  {selectedFile && (
					<>
						Selected File: {selectedFile.name}
					</>
				  )}
				  {loadingImport && (
					<LinearProgress />
				  )}
				  {errorImport && (
					<Typography variant="body2" color="error.dark" mt={2}>
					  {errorImport}
					</Typography>
				  )}
				  {successImport && (
					<Typography variant="body2" color="success.main" mt={2}>
					  {successImport}
					</Typography>
				  )}
			  </DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleImportQuestionnaireCloseModal}>Close</Button>
					{/*<Button onClick={handleCloneQuestionnaire} > Yes</Button>*/}
			</DialogActions>
		</Dialog>
		{/*End import questionnaire dialog */}

	{(questionnaireData.length==0) ?
	<>
		No questionnaires found
	</>
	:<>
		{/*start sort buttons */}
		<div style={{ width: '100%' }}>
		  <Box sx={{ display: 'flex', flexDirection: 'row-reverse', p: 1, m: 1, borderRadius: 1}}>
			{dataView=='grid'?
			<>
				<Button  size="small" onClick={(e) => handleSwitchView(e,"table")}  variant="outlined" endIcon={<TableRowsOutlinedIcon/>}>View</Button>&nbsp;
			</>:<>
				<Button  size="small" onClick={(e) => handleSwitchView(e,"grid")} variant="outlined" endIcon={<GridViewOutlinedIcon/>}>View</Button>&nbsp;
			</>}
			<Button  size="small" onClick={(e) => handleSortQuestionnaires(e,"date")}  variant="outlined" endIcon={<ImportExportIcon/>}>
			  Sort new/old
			</Button>&nbsp;
			<Button size="small" onClick={(e) => handleSortQuestionnaires(e,"title")}  variant="outlined" endIcon={<ImportExportIcon/>}>
			  Sort a/z
			</Button>
		  </Box>
		</div>
		{/*end sort buttons */}
		

	 
		{/*   delete questionnaire dialog */}
		<Dialog
			open={openDeleteQuestionnaireModal}
			onClose={handleDeleteClose}
			//aria-labelledby="alert-dialog-title"
			//aria-describedby="alert-dialog-description"
		  >
			<DialogTitle id="alert-dialog-title">
			  {"Are you sure you want to delete this questionnaire?"}
			</DialogTitle>
			<DialogContent>
			  <DialogContentText id="alert-dialog-description">
			  
			  </DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleDeleteClose}>No</Button>
				<Button onClick={handleDeleteQuestionnaire} > Yes</Button>
			</DialogActions>
		  </Dialog>
	  
		{/*START Clone questionnaire dialog */}
		<Dialog
			open={openCloneQuestionnaireModal}
			onClose={handleCloneClose}
			//aria-labelledby="alert-dialog-title"
			//aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">
			  {"Are you sure you want to clone this questionnaire?"}
			</DialogTitle>
			<DialogContent>
			  <DialogContentText id="alert-dialog-description">
			  <h4>Title</h4>
			<TextField  margin="normal" required fullWidth id="title" label="Title" name="title"
				value={tempQuestionnaire['title']}
				onChange={(e) => {handleOnChangeValue(e,'title')}}
			/>
			  </DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleCloneClose}>Cancel</Button>
				<Button onClick={handleCloneQuestionnaire} > Yes</Button>
			</DialogActions>
		</Dialog>
		{/*End Clone questionnaire dialog */}
	
	{(dataView=='table')?
	<>
		<TableContainer component={Paper}>
			<Table sx={{ minWidth: 650 }} aria-label="simple table">
				<TableHead>
					<TableRow>
						<TableCell>Title</TableCell>
							<TableCell>Created By</TableCell>
							<TableCell>Last Updated</TableCell>
							<TableCell align="center">Published</TableCell>
							<TableCell align="center">Client Responses</TableCell>
							<TableCell align="center">Test Responses</TableCell>
							<TableCell></TableCell>
						  </TableRow>
						</TableHead>
						<TableBody>
						  {questionnaireData.map((row,index) => (
							<TableRow
							  key={index}
							  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
							>
							  <TableCell component="th" scope="row">
								{row.title}
									{/*<ControlBox  questionnaire={row} />*/}
							  </TableCell>
							  <TableCell component="th" scope="row">
								{row.created_by.first_name} {row.created_by.last_name}
							  </TableCell>
							  <TableCell component="th" scope="row">
								{formatDate(row.last_updated)}
							  </TableCell>
							  <TableCell component="th" scope="row" align="center">
								 {(row.published==false) ?
									<>
									<CircleIcon  fontSize="small"  style={{  color: 'orange',fontSize:"small" }} /> 
									</>
									:<>
									<CircleIcon    style={{  color: 'green',fontSize:"small" }} /> 
								</>}
							  </TableCell>
							  <TableCell component="th" scope="row" align="center">
								{row.published==true &&
								<>
									<Badge badgeContent={questionnaireResponses[row.id].total} color="primary">
									  <MailIcon color="action" />
									</Badge>
									{questionnaireResponses[row.id].total>0 &&
									<>
									<Tooltip title="Download">
									  <IconButton onClick={(e) => handleDownloadQuestionnaireResponses(e,row,false)} aria-label="download">
										<FileDownloadIcon />
									  </IconButton>
									</Tooltip>
									</>
									}
								</>}
								
							  </TableCell>
							  
							   <TableCell component="th" scope="row" align="center">
								
									<Badge badgeContent={questionnaireTestResponses[row.id].total} color="primary">
									  <MailIcon color="action" />
									</Badge>
									{questionnaireTestResponses[row.id].total>0 &&
									<>
									<Tooltip title="Download">
									  <IconButton onClick={(e) => handleDownloadQuestionnaireResponses(e,row,true)} aria-label="download">
										<FileDownloadIcon />
									  </IconButton>
									</Tooltip>
									</>
									}
								
							  </TableCell>
							 
							   <TableCell component="th" scope="row" align="right">
							   {row.published==false &&
							   <>
							   <Tooltip title="Edit">
								   <IconButton href={`/questionnaire/edit/${row._id}`} aria-label="edit">
									<EditIcon />
								  </IconButton>
								</Tooltip>
							   <Tooltip title="Preview">
								  <IconButton href={`/questionnaire/view/${row._id}`} aria-label="view">
									<PreviewIcon />
								  </IconButton>
								</Tooltip>
								
								<Tooltip title="Clone">
								  <IconButton onClick={(e) => handleCloneQuestionnaireModal(e,row)} aria-label="copy">
									<ContentCopyIcon />
								  </IconButton>
								</Tooltip>
								
								<Tooltip title="Delete">
								   <IconButton onClick={(e) => handleDeleteQuestionnaireModal(e,row)} aria-label="delete">
									<DeleteIcon />
								  </IconButton>
								</Tooltip>
							   
							   </>}
							   
							   {row.published==true &&
							   <>
							   
							   <Tooltip title="Preview">
								  <IconButton href={`/questionnaire/view/${row._id}`} aria-label="view">
									<PreviewIcon />
								  </IconButton>
								</Tooltip>
								<Tooltip title="Clone">
								  <IconButton onClick={(e) => handleCloneQuestionnaireModal(e,row)} aria-label="copy">
									<ContentCopyIcon />
								  </IconButton>
								</Tooltip>
									{row.active==true &&
									<>
									<Tooltip title="turn off visibility">
									  <IconButton onClick={(e) => handleUpdateQuestionnaire(e,row)} aria-label="copy">
										<VisibilityIcon />
									  </IconButton>
									</Tooltip>
									</>}
									{row.active==false &&
									<>
									<Tooltip title="turn on visibility">
									  <IconButton onClick={(e) => handleUpdateQuestionnaire(e,row)} aria-label="copy">
										<VisibilityOffIcon />
									  </IconButton>
									</Tooltip>
									</>}
							   </>}
								<Tooltip title="Export">
									  <IconButton onClick={(e) => handleDownloadQuestionnaireXlsx(e,row)} aria-label="download">
										<FileDownloadIcon />
									  </IconButton>
								</Tooltip>
								
							  </TableCell>
							  
							</TableRow>
						  ))}
						</TableBody>
					  </Table>
					</TableContainer>
	  
	</>
	:<>
	  
	  
	   <Grid container spacing={3} >
			  {questionnaireData.map((row,index) => (
			 <Grid key={row._id} item item xs={12} sm={6} md={4} >
				 <Card variant="outlined" sx={{ minWidth: 25,minHeight:240 }} >
				
				<CardHeader style={{ textAlign: 'center' }} 
					title={row.title}
					action={<IconButton disabled aria-label="settings">
								{(row.published) ?
								<>
								<CircleIcon  fontSize="small"  style={{  color: 'green',fontSize:"small" }} /> 
								</>
								:<>
								<CircleIcon    style={{  color: 'orange',fontSize:"small" }} /> 
								 </>}
								 </IconButton>
							}
				  />
					<CardContent style={{ textAlign: 'center' }} >
						{/*<Typography sx={{ mb: 1.5 }} color="text.secondary">
							{row.description}
			  </Typography><controlBox />*/}
						<Typography variant="body2">
						<b>Created By:</b> {row.created_by.first_name} {row.created_by.last_name}
						<br />
						<b>Last updated:</b>{formatDate(row.last_updated)}
						<br />
						</Typography>
					</CardContent>
					<CardActions style={{justifyContent:'center'}}>
							{row.published==false &&
							<>
							
								<Tooltip title="Edit">
								   <IconButton href={`/questionnaire/edit/${row._id}`} aria-label="edit">
									<EditIcon />
								  </IconButton>
								</Tooltip>
								<Tooltip title="Preview">
								  <IconButton href={`/questionnaire/view/${row._id}`} aria-label="view">
									<PreviewIcon />
								  </IconButton>
								</Tooltip>
								<Tooltip title="Clone">
								  <IconButton onClick={(e) => handleCloneQuestionnaireModal(e,row)} aria-label="copy">
									<ContentCopyIcon />
								  </IconButton>
								</Tooltip>
								<Tooltip title="Delete">
								   <IconButton onClick={(e) => handleDeleteQuestionnaireModal(e,row)} aria-label="delete">
									<DeleteIcon />
								  </IconButton>
								</Tooltip>
							</>}
							
							{row.published==true &&
							<>
							<Tooltip title="Preview">
								  <IconButton href={`/questionnaire/view/${row._id}`} aria-label="view">
									<PreviewIcon />
								  </IconButton>
								</Tooltip>
								<Tooltip title="Clone">
								  <IconButton onClick={(e) => handleCloneQuestionnaireModal(e,row)} aria-label="copy">
									<ContentCopyIcon />
								  </IconButton>
								</Tooltip>
									{row.active==true &&
									<>
									<Tooltip title="turn off visibility">
									  <IconButton onClick={(e) => handleUpdateQuestionnaire(e,row)} aria-label="copy">
										<VisibilityIcon />
									  </IconButton>
									</Tooltip>
									</>}
									{row.active==false &&
									<>
									<Tooltip title="turn on visibility">
									  <IconButton onClick={(e) => handleUpdateQuestionnaire(e,row)} aria-label="copy">
										<VisibilityOffIcon />
									  </IconButton>
									</Tooltip>
									</>}
							</>}
							
				  </CardActions>
			</Card>	
			</Grid>
			
			))}
		</Grid>
	  
		</>}
	
	</>}

	</>
	:<></>}
	
	{/* Researcher */}
	{(currentUser.__t=='Researcher') ?
	<>
	
	</>
	:<></>}

	</AppLogout>
	</>
  ) 
  ;
};

export default Questionnaires;